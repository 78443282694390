import React, {useEffect, useRef} from 'react';
import './slider.css';
import {AiOutlineDoubleLeft, AiOutlineDoubleRight} from 'react-icons/ai';
import Img1 from '../../Images/carrousel/1.jpg';
import Img2 from '../../Images/carrousel/2.jpg';
import Img3 from '../../Images/carrousel/3.jpg';
import Img4 from '../../Images/carrousel/4.jpg';
import Img5 from '../../Images/carrousel/5.jpg';

const Slider = () => {
    const slideShow = useRef(null);
    const intervalSlide = useRef(null);

    const moveSlide = ()=>{
        if(slideShow !== null && slideShow.current !== null){
            
            const primerElemento = slideShow.current.children[0];
            slideShow.current.style.transform = `translateX(-100%)`;
            slideShow.current.style.transition = `0.5s ease all`;

            const transicion = () =>{
                slideShow.current.style.transition = 'none';
                slideShow.current.style.transform = 'translateX(0%)';
                slideShow.current.appendChild(primerElemento);
                slideShow.current.removeEventListener('transitionend', transicion);
            }

            slideShow.current.addEventListener('transitionend', transicion);
        }else return;
    };

    const backSlide = ()=>{
        if(slideShow.current.children.length > 0){
            const lastElement = slideShow.current.children[4];
            slideShow.current.insertBefore(lastElement, slideShow.current.firstChild);
            slideShow.current.style.transition = `none`;
            slideShow.current.style.transform = `translateX(-100%)`;
            setTimeout(()=>{
                slideShow.current.style.transform = `translateX(0%)`;
                slideShow.current.style.transition = `0.5s ease all`;
            }, 3);
        }
    };

    useEffect(()=>{
        intervalSlide.current = setInterval(()=>{
            moveSlide();
        }, 8000);
    });


  return (
    <div className='slider-container'>
        <div className='arrows-container'>
            <span onClick={backSlide}><AiOutlineDoubleLeft/></span>
            <span onClick={moveSlide}><AiOutlineDoubleRight/></span>
        </div>

        <div className='slider-show-container'ref={slideShow}>
            <div className='slider' style={{'backgroundImage': `url(${Img1})`}} >
                <div className='slider-img'>
                    <div className='slider-content'>
                        <h3>CONFIANZA Y SOLIDEZ</h3>
                        <hr></hr>
                        <h6>Experiencia y trayectoria al servicio de nuestros clientes</h6>
                    </div>
                </div>
            </div>

            <div className='slider' style={{'backgroundImage': `url(${Img2})`}}>
                <div className='slider-img'>
                    <div className='slider-content'>
                        <h3>MÁS CALIDAD</h3>
                        <hr></hr>
                        <h6>Representamos marcas líderes para brindar innovación y tecnología en nuestros productos</h6>
                    </div>
                </div>
            </div>

            <div className='slider' style={{'backgroundImage': `url(${Img3})`}}>
                <div className='slider-img'>
                    <div className='slider-content'>
                        <h3>ASESORAMIENTO</h3>
                        <hr></hr>
                        <h6>Nuestro equipo de ventas especializado te espera para asesorarte cordialmente</h6>
                    </div>
                </div>
            </div>

            <div className='slider' style={{'backgroundImage': `url(${Img4})`}}>
                <div className='slider-img'>
                    <div className='slider-content'>
                        <h3>MÁS PRODUCCIÓN</h3>
                        <hr></hr>
                        <h6>Trabajamos incansablemente para que nunca te falte mercadería y así logres terminar tus trabajos a tiempo.</h6>
                    </div>
                </div>
            </div>

            <div className='slider' style={{'backgroundImage': `url(${Img5})`}}>
                <div className='slider-img'>
                    <div className='slider-content'>
                        <h3>SERVICIOS</h3>
                        <hr></hr>
                        <h6>Cortes, diseños, servicio de entrega, stock permanente, maquinado de maderas.</h6>
                    </div>
                </div>
            </div>

        </div>

    </div>
  )
}

export default Slider;