import React, { useRef, useState } from 'react';
import './Marcas.css';
import Img1 from '../../Images/marcas/1.png';
import Img2 from '../../Images/marcas/2.png';
import Img3 from '../../Images/marcas/3.png';
import Img4 from '../../Images/marcas/4.png';
import Img5 from '../../Images/marcas/5.png';
import Img6 from '../../Images/marcas/6.svg';
import Img7 from '../../Images/marcas/7.png';
import Img8 from '../../Images/marcas/8.png';
import Img9 from '../../Images/marcas/9.png';
import Img10 from '../../Images/marcas/10.png';
import Img11 from '../../Images/marcas/11.png';
import Img12 from '../../Images/marcas/12.png';
import Img13 from '../../Images/marcas/13.png';
import Img14 from '../../Images/marcas/14.png';
import Img15 from '../../Images/marcas/15.png';
import Img16 from '../../Images/marcas/16.png';
import Img17 from '../../Images/marcas/17.png';
import Img18 from '../../Images/marcas/18.png';
import Img19 from '../../Images/marcas/19.png';
import Img20 from '../../Images/marcas/20.png';
import Img21 from '../../Images/marcas/21.png';
import Img22 from '../../Images/marcas/22.png';
import Img23 from '../../Images/marcas/23.png';
import Img24 from '../../Images/marcas/24.png';

const MarcasSlider = () => {
  const [despl, setDespl] = useState(0);
  const sliderMarcas = useRef(null);
  const sliderMarcasShow = useRef(null);

  const moveSlide = ()=>{
    if(sliderMarcas !== null && sliderMarcas.current !== null){
      sliderMarcasShow.current.style.transition = `300ms ease-out all`;
      if(sliderMarcas.current.offsetWidth > 745){
        if(despl === 500)setDespl(0)
        else setDespl(despl + 50)
      }else{
        if(despl === 1100) setDespl(0)
        else setDespl(despl + 100)
      }
      sliderMarcasShow.current.style.transform = `translateX(-${despl}%)`
    }
  }

    setTimeout(()=>{setInterval(moveSlide(), 3000)},3000);

  return (
    <div className='slider-marcas-container' ref={sliderMarcas}> 
        <div className='home-tittles'>
          <h3>Marcas que confían en nosotros</h3>
          <hr/>
        </div>
        <div className='slider-marcas'>
          <div className='slider-hidden'>
            <div className='sliderMarcasShow' ref={sliderMarcasShow}>
              <div className='sliderImg-marcas'><img src={Img1} alt='marca1'/></div>
              <div className='sliderImg-marcas'><img src={Img2} alt='marca2'/></div>
              <div className='sliderImg-marcas'><img src={Img3} alt='marca3'/></div>
              <div className='sliderImg-marcas'><img src={Img4} alt='marca4'/></div>
              <div className='sliderImg-marcas'><img src={Img5} alt='marca5'/></div>
              <div className='sliderImg-marcas'><img src={Img6} alt='marca6'/></div>
              <div className='sliderImg-marcas'><img src={Img7} alt='marca7'/></div>
              <div className='sliderImg-marcas'><img src={Img8} alt='marca8'/></div>
              <div className='sliderImg-marcas'><img src={Img9} alt='marca9'/></div>
              <div className='sliderImg-marcas'><img src={Img10} alt='marca10'/></div>
              <div className='sliderImg-marcas'><img src={Img11} alt='marca11'/></div>
              <div className='sliderImg-marcas'><img src={Img12} alt='marca12'/></div>
              <div className='sliderImg-marcas'><img src={Img13} alt='marca13'/></div>
              <div className='sliderImg-marcas'><img src={Img14} alt='marca14'/></div>
              <div className='sliderImg-marcas'><img src={Img15} alt='marca15'/></div>
              <div className='sliderImg-marcas'><img src={Img16} alt='marca16'/></div>
              <div className='sliderImg-marcas'><img src={Img17} alt='marca17'/></div>
              <div className='sliderImg-marcas'><img src={Img18} alt='marca18'/></div>
              <div className='sliderImg-marcas'><img src={Img19} alt='marca19'/></div>
              <div className='sliderImg-marcas'><img src={Img20} alt='marca20'/></div>
              <div className='sliderImg-marcas'><img src={Img21} alt='marca21'/></div>
              <div className='sliderImg-marcas'><img src={Img22} alt='marca22'/></div>
              <div className='sliderImg-marcas'><img src={Img23} alt='marca23'/></div>
              <div className='sliderImg-marcas'><img src={Img24} alt='marca24'/></div>
            </div>
          </div>
        </div>
        <div className='knauf-section'>
            <h4>Distribuidores oficiales de</h4>
            <div className='knauf-section-img'>
              <a href='https://www.knauf.com.ar' rel='noreferrer' target='_blank'><img alt='knauf' src={Img6}/></a>
              <a href='https://www.faplaconline.com.ar/home/' rel='noreferrer' target='_blank'><img alt='knauf' src={Img13}/></a>
            </div>
        </div>
    </div>
  )
}

export default MarcasSlider;