import React, { useState } from 'react';
import Banner from '../../../components/Banner/Index';
import './Pinturas.css';

const Pinturas = () => {
    const [comp, setComp] = useState('');
    
    const switchComponent = (selec)=>{
        switch(selec){
            case '': return <Default/>;
            case '-Danzke lasur': return <DanzkeLasur/>;
            case '-7 vidas: diluyente y protector inteligente': return <Vidas7/>;
            case '-Cambell': return <Cambell/>;
            case '-Danzke deck': return <DanzkeDeck/>;
            case '-Danzke todo riesgo': return <DanzkeTodoRiesgo/>;
            case '-Masilla': return <MasillaPetrilac/>;
            case '-Mateante': return <MateantePetrilac/>;
            case '-Protector UP para madera': return <ProtectorUP/>;
            case '-Restaurador': return <Restaurador/>;
            case '-Tinta': return <Tinta/>;
            case '-Xylasol lasur': return <Xylasol/>;
            case '-Barniz': return <Barniz/>;
            case '-Celocrom': return <Celocrom/>;
            case '-Celoplast': return <Celoplast/>;
            case '-Insecticida': return <Insecticida/>;
            case 'Limpiador': return <Limpiador/>;
            case '-Masilla polilak': return <Masilla/>;
            case '-Mateante polilak': return <Mateante/>;
            case '-Sellagres decorativos': return <Sellagres/>;
            case 'UV Revestimiento para madera': return <UVrevestimiento/>;
            case '-Deck': return <Decks/>;
            case '-Preservador insecticida': return <Preservador/>;
            default: return <Default/>
        }
    }


  return (
    <>
        <Banner title='Pinturas'/>
        <div className='products-container'>
            <div className='submenu-products'>
                <div className='div-submenu'>
                    <h5 onClick={()=> setComp('')}>Pinturas</h5>
                    <div className='buttons-submenu'>
                        <button id='different-button'>Petrilac</button>
                        <button onClick={()=> setComp('-Danzke lasur')} type='button'>-Danzke lasur</button>
                        <button onClick={()=> setComp('-7 vidas: diluyente y protector inteligente')} type='button'>-7 vidas: diluyente y protector inteligente</button>
                        <button onClick={()=> setComp('-Cambell')} type='button'>-Cambell</button>
                        <button onClick={()=> setComp('-Danzke deck')} type='button'>-Danzke deck</button>
                        <button onClick={()=> setComp('-Danzke todo riesgo')} type='button'>-Danzke todo riesgo</button>
                        <button onClick={()=> setComp('-Masilla')} type='button'>-Masilla</button>
                        <button onClick={()=> setComp('-Mateante')} type='button'>-Mateante</button>
                        <button onClick={()=> setComp('-Protector UP para madera')} type='button'>-Protector UP para madera</button>
                        <button onClick={()=> setComp('-Restaurador')} type='button'>-Restaurador</button>
                        <button onClick={()=> setComp('-Tinta')} type='button'>-Tinta</button>
                        <button id='different-button'>Polilak</button>
                        <button onClick={()=> setComp('-Xylasol lasur')} type='button'>-Xylasol lasur</button>
                        <button onClick={()=> setComp('-Barniz')} type='button'>-Barniz</button>
                        <button onClick={()=> setComp('-Celocrom')} type='button'>-Celocrom</button>
                        <button onClick={()=> setComp('-Celoplast')} type='button'>-Celoplast</button>
                        <button onClick={()=> setComp('-Insecticida')} type='button'>-Insecticida</button>
                        <button onClick={()=> setComp('Limpiador')} type='button'>-Limpiador</button>
                        <button onClick={()=> setComp('-Masilla polilak')} type='button'>-Masilla</button>
                        <button onClick={()=> setComp('-Mateante polilak')} type='button'>-Mateante</button>
                        <button onClick={()=> setComp('-Sellagres decorativos')} type='button'>-Sellagres decorativos</button>
                        <button onClick={()=> setComp('UV Revestimiento para madera')} type='button'>UV Revestimiento para madera</button>
                        <button id='different-button'>Penta</button>
                        <button onClick={()=> setComp('-Deck')} type='button'>-Deck</button>
                        <button onClick={()=> setComp('-Preservador insecticida')} type='button'>-Preservador insecticida</button>
                    </div>
                </div>
            </div>
            <div className='content-products-container'>
                {switchComponent(comp)}
            </div>
        </div>
    </>
  )
}

export default Pinturas;

const Default = ()=>{
    return (
        <>
            <img className='img-default' alt='const-en-seco' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2Fprincipal.jpg?alt=media&token=6ec84e9a-f841-440a-b1c6-fb5d3e739ad4' loading='lazy'/>
            <p>
                En LUMADI MADERAS ofrecemos lacas, barnices, lasures y recubrimientos transparentes para la protección y embellecimiento de maderas, una completa línea de convertidores y esmaltes para metales, impermeabilizantes para ladrillos y pinturas industriales.
            </p>
            <p>
                Ofrecemos diversas líneas de productos a través de marcas como PETRILAC, POLILAK y PENTA, consideradas como un alto exponente en calidad, innovación tecnológica, investigación y servicios al consumidor.
            </p>
            <p>
                Contamos con todos los productos de ABRASIVOS ARGENTINOS, como lijas, bandas de tela, rollos de papel y tela, cintas de enmascarar, discos de corte de concreto.
            </p>
            <div className='first-images-pinturas'>
                <img alt='img1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2Fpri-1.jpg?alt=media&token=4f4bd77c-731a-437f-9625-6b1273be82c2' loading='lazy'/>
                <img alt='img2' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2Fpri-2.jpg?alt=media&token=c0efc227-ab82-4a78-bd25-26aeb196f7a4' loading='lazy'/>
                <img alt='img3' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2Fpri-3.jpg?alt=media&token=358daac1-b6a5-40c9-bd83-c1403fd462b0' loading='lazy'/>
                <img alt='img4' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2Fpri-4.jpg?alt=media&token=84bd1b0d-1bf5-4981-a59c-1318ec424201' loading='lazy'/>
            </div>
            <p>Toda la línea en pinceles.</p>
            <p>Y todo en adhesivos FORTEX, desde cemento de contacto, cola vinilica, diluyentes y siliconas.</p>
            <div className='sd-images-pinturas'>
                <img alt='img5' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2Fpri-5.jpg?alt=media&token=d46c6e12-8abe-4118-9dde-7ecf9dba186d' loading='lazy'/>
                <img alt='img6' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2Fpri-6.jpg?alt=media&token=e5b6099b-beae-4ca4-85e2-993ffe9b9578' loading='lazy'/>
                <img alt='img7' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2Fpri-7.jpg?alt=media&token=bced10c1-a21b-4c18-b461-c13c142a659f' loading='lazy'/>
                <img alt='img8' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2Fpri-8.jpg?alt=media&token=08ba0bc0-d01f-4924-a342-3695c36c40bc' loading='lazy'/>
                <img alt='img9' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2Fpri-9.jpg?alt=media&token=fe077f59-4a8c-4bb7-a644-f50b765c84ae' loading='lazy'/>
                <img alt='img10' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2Fpri-10.jpg?alt=media&token=55c1f13f-8cb8-4557-b1ea-fb97b54d7aa9' loading='lazy'/>
            </div>
            <p>Para que usted cuente con todo lo necesario para pintar, en un solo lugar.</p>
        </>
    )
}



const DanzkeLasur = ()=>{
    return(
        <>
            <h4 className='title-placas'>DANZKE LASUR</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>El recubrimiento DANZKE® LASUR está elaborado a base de resinas de alto grado de penetración, filtros UV, pigmentos, aditivos y solventes de excelente calidad. Ideal para la protección de maderas en climas muy severos con cambios bruscos de temperatura, lluvia, sol, nieve y brisas marinas.</p>
                    <br/>
                    <p className='p-cards-comp'>Penetra profundamente, formando un microfilm elástico que se integra a la fibra, acompañando los movimientos sin cuartear ni descascararse. Doble acción hidrorrepelente, repele lluvia y nieve, permitiendo que la humedad interior escape en forma de vapor.</p>
                    <p className='p-cards-comp'>Esta condición elimina la posibilidad de formación de hongos y microorganismos que necesitan mucha humedad para proliferar.</p>
                    <ul>
                        <li>Interior / Exterior</li>
                        <li>Doble acción contra rayos UV.</li>
                        <li>Contiene aceite de teka.</li>
                        <li>Excelente hidrorrepelencia.</li>
                        <li>No se cuartea, descascara ni ampolla.</li>
                        <li>Mantenimiento: Muy simple, retirar la suciedad con cepillo, lijar suavemente y aplicar una nueva mano.</li>
                        <li>Tiempo de secado: De 6 a 8 horas entre manos, de acuerdo a las condiciones ambientales y de aplicación.</li>
                        <li>Terminación: Brillante y satinada.</li>
                        <li>Rendimiento: De 14 a 16m², de acuerdo a la absorción de la madera a tratar.</li>
                        <li>Colores: Muy decorativos -mezclarse entre sí-. El color cristal debe usarse únicamente en interiores.</li>
                    </ul>
                    <p className='p-cards-comp'><span>Presentación: </span>1l, 4l y 20l.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F1v1.png?alt=media&token=6b6b950f-e825-416e-8839-6d3b933d975a' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
            <img style={{width: '100%'}} alt='img-deck' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F1v2.png?alt=media&token=cc66ce56-184a-40d2-becb-92373cd3201e' loading='lazy'/>
        </>
    )
}



const Vidas7 = ()=>{
    return(
        <>
            <h4 className='title-placas'>7 VIDAS: DILUYENTE Y PROTECTOR INTELIGENTE</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <h4>7 VIDAS: DILUYENTE</h4>
                    <p className='p-cards-comp'>El DILUYENTE 7 VIDAS® está esencialmente formulado para optimizar el resultado de PETRILAC 7 VIDAS®, Protector Inteligente®.</p>
                    <p className='p-cards-comp'>La importancia de usar el diluyente correspondiente se traduce en el resguardo de la calidad, debido a que su desarrollo tecnológico ha sido realizado en función del producto. Facilita la pintabilidad, la nivelación de la película y ajusta la viscosidad de aplicación, sin modificar el secado.</p>
                    <br/>
                    <p className='p-cards-comp'>Contribuye a lograr acabados lisos y uniformes con inmejorable aspecto de la película, sin alterar el brillo. Es el complemento ideal de dilución para obtener la máxima durabilidad, tal como ha sido comprobado en ensayos realizados en conjunto con la laca PETRILAC 7 VIDAS®.</p>
                    <ul>
                        <li>Adecua la viscosidad de la LACA PETRILAC 7 VIDAS ®.</li>
                        <li>Resguarda la calidad de la laca.</li>
                        <li>Mejora la pintabilidad y facilita la nivelación.</li>
                        <li>Contribuye a lograr acabados lisos y uniformes.</li>
                        <li>No afecta el brillo.</li>
                        <li>No modifica el secado.</li>
                    </ul>
                    <p className='p-cards-comp'><span>Presentación: </span>1/2l y 1l.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F2v1.png?alt=media&token=77e363ff-e8de-4330-98dd-c33a21328395'/>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <h4>7 VIDAS: PROTECTOR INTELIGENTE</h4>
                    <p className='p-cards-comp'>LACA 7 VIDAS®, es un PROTECTOR INTELIGENTE® que está formulado con excelentes resinas de Bayer AG, potentes filtros UV, pigmentos inorgánicos, antioxidantes.</p>
                    <p className='p-cards-comp'>Es una nueva concepción en protección y embellecimiento de la madera que ataca todas las causas del deterioro producido por las condiciones climáticas. Brinda una óptima protección de acuerdo a su porosidad, dureza, ubicación geográfica y/o a la intensidad del sol, lluvia, nieve y/o brisa marina a la cual se encuentra expuesta.</p>
                    <br/>
                    <p className='p-cards-comp'>Es un PROTECTOR INTELIGENTE® porque posee aditivos totalmente novedosos "antioxidantes" que desactivan a los perjudiciales radicales libres en el mismo momento que comienzan a dañarla. Estos aditivos autorregeneran el efecto antioxidante, demorando el envejecimiento de la madera y del recubrimiento. Los antioxidantes complementan la defensa de la madera, manteniendo el brillo original de la película por mucho más tiempo.</p>
                    <ul>
                        <li>No cuartea ni descascara.</li>
                        <li>Mínimo mantenimiento.</li>
                        <li>Duración: hasta 7 años</li>
                        <li>DILUCION: Usar DILUYENTE 7 VIDAS®, especialmente desarrollado para conseguir el máximo resultado.</li>
                        <li>RENDIMIENTO: 10 -12 m² por litro y por mano, dependiendo del tipo de madera y dilución.</li>
                        <li>SECADO: Al tacto 6 a 8 horas. Entre manos mínimo 12 horas.</li>
                        <li>LIMPIEZA: Limpiar las herramientas de trabajo con DILUYENTE 7 VIDAS®.</li>
                        <li>COLORES: Hermosa gama de colores preparados muy decorativos, los que podrán mezclarse entre sí para ampliar las opciones de acuerdo a su elección</li>
                    </ul>
                    <p className='p-cards-comp'><span>Presentación: </span>1/2l y 1l.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F2v1.png?alt=media&token=77e363ff-e8de-4330-98dd-c33a21328395' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Cambell = ()=>{
    return(
        <>
            <h4 className='title-placas'>CAMBELL</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>El BARNIZ CAMPBELL está elaborado con resinas sintéticas, aditivos, filtros UV, solventes de excelente calidad y aceite de teka que renueva y afirma las fibras celulósicas. Su novedoso Factor de Protección Solar -FPS 50®-, que retarda 50 veces el efecto de las radiaciones con respecto a una madera sin tratar, prolonga por mucho tiempo su duración.</p>
                    <p className='p-cards-comp'>Forma una película transparente de alto brillo, que realza la veta, ideal para uso arquitectónico y náutico. Por su gran flexibilidad, no cuartea ni descascara.</p>
                    <ul>
                        <li>Filtros absorbedores de rayos UV.</li>
                        <li>Muy transparente y brillante.</li>
                        <li>Realza la veta y la belleza de la madera.</li>
                        <li>Impide la formación de hongos y algas.</li>
                        <li>Para maderas en exteriores e interiores con aceite de teka.</li>
                        <li>Teñido de madera: El aceite de teka confiere al barniz una tonalidad ámbar amarillenta. Consultar a nuestro Departamento Técnico.</li>
                        <li>Rendimiento: 10 a 12 m² por litro y por mano dependiendo de la absorción y textura de la madera y la calidad del lijado.</li>
                        <li>Tiempo de secado: Al tacto 6 a 8 horas. Entre manos 10 a 12 horas.</li>
                        <li>Limpieza: Los utensilios se lavan inmediatamente después de usados con aguarrás mineral.</li>
                        <li>Terminación: Brillante.</li>
                    </ul>
                    <p className='p-cards-comp'><span>Presentación: </span>1l y 4l.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F3v1.png?alt=media&token=27597141-4500-433f-b470-e0d372331b2d' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const DanzkeDeck = ()=>{
    return(
        <>
            <h4 className='title-placas'>DANZKE DECK</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>DANZKE® DECK es un protector satinado que protege y decora decks (cubiertas), escaleras, senderos de madera expuestos al tránsito y al exterior. Es apto para muebles de jardín, cercas, puertas, ventanas, aleros y cenefas. Penetra profundamente y acompaña los movimientos de la madera sin cuartear, descascarar o ampollar. Neutraliza la acción del sol.</p>
                    <p className='p-cards-comp'>Su notable hidrorrepelencia, rechaza el agua sin impedir la salida de humedad interna, la que se elimina en forma de vapor.</p>
                    <p className='p-cards-comp'>Evita la formación de algas y hongos, protegiendo el interior de la madera.</p>
                    <ul>
                        <li>Transparente, realza la veta</li>
                        <li>Excelente hidrorrepelencia.</li>
                        <li>Impide la formación de hongos y algas.</li>
                        <li>Para pisos de madera en exteriores.</li>
                        <li>Tiempo de secado: Entre 12 y 24 horas dependiendo de la característica de la madera y condiciones ambientales de humedad y temperatura.</li>
                        <li>Rendimiento: Maderas rugosas o muy absorbentes (pino, cedro, etc.): hasta 4 m² por litro. Maderas menos absorbentes: (lapacho, incienso, eucaliptus, anchico, etc.): hasta 8 m² por litro. El rendimiento depende de la absorción y rugosidad de la madera. Las maderas expuestas a la intemperie que no han sido protegidas por largo tiempo, aumentarán el consumo del producto.</li>
                        <li>Mantenimiento: Muy sencillo, realizar una limpieza previa del área pintada y desgastada por el uso y aplicar DANZKE® DECK. Una mejor nutrición de la madera se logra con un service periódico</li>
                        <li>Limpieza: Los utensilios se limpian con aguarrás mineral.</li>
                        <li>Terminación: Satinada.</li>
                    </ul>
                    <p className='p-cards-comp'><span>Presentación: </span>1l y 4l.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F4v1.png?alt=media&token=c53ff084-3057-42d4-85f0-42b46332f0f4' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
            <img style={{width: '100%'}} alt='img-deck' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F4v2.png?alt=media&token=b54ef3d2-7a70-4238-bc24-1d58b9a6ed81' loading='lazy'/>
        </>
    )
}



const DanzkeTodoRiesgo = ()=>{
    return(
        <>
            <h4 className='title-placas'>DANZKE TODO RIESGO</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                <p className='p-cards-comp'>TODO RIESGO ® DANZKE ® + PENTA ® es un lasur formulado para proteger y embellecer todo tipo de maderas en exteriores e interiores, REVESTIMIENTO PROTECTOR TOTAL PARA CLIMAS EXTREMOS, de acción preventiva (FUNGICIDA + INSECTICIDA + BLOQUEADOR DE UV + ANTIOXIDANTE) para aplicar antes de instalar la madera y de erradicación y prevención para después de la puesta en obra; especialmente para las que resultan atacadas, como los pinos, eucaliptos, álamos, cedros, etc., por los hongos de la pudrición- blanca y parda-. (Basidiomicetes: Lenzites sp, Coniophora sp, Polyporus sp, Trametes sp) y por los insectos coleópteros: Hylotrupes bajulus (taladro del pino), Anobium punctatum (taladrillo), Lyctus brunneus (polilla de la madera). Himenópteros: Camponotus sp (hormiga carpintera), Sirex sp (avispa barrenadora), Xylocopa sp (abejorro carpintero); Isópteros: Nasutitermes sp (termitas).</p>
                <br/>
                <p className='p-cards-comp'>Es un curador que actúa en forma permanente, impermeabiliza, nutre las fibras con efectivos antioxidantes, resguarda contra los factores climáticos más severos con 4 clases de potentísimos filtros UV y deja una micropelícula de gran repelencia al agua, elástica, que no se cuartea, neutralizando la formación de hongos, moho y verdín. Ofrece una paleta de colores transparentes que resaltan la veta, decoran con un solo producto y a la vez, por su exclusiva fórmula, alarga la vida útil - externa e interna- ; además de simplificar la mano de obra, ahorrando tiempo y dinero. No apto para mesas ni mesadas.</p>
                    <ul>
                        <li>Fácil de mantener sin lijar</li>
                        <li>No cuartea ni descascara</li>
                        <li>Insecticida + Fungicida</li>
                        <li>Bloqueador de UV + FPS®90</li>
                        <li>Tiempo entre manos: Al tacto 4 horas, entre manos de 6 a 8 horas, de acuerdo a las condiciones ambientales.</li>
                        <li>Rendimiento: 14 a 16 m2 por litro y por mano, dependiendo de la absorción y rugosidad de la madera a tratar.</li>
                        <li>Colores: Consultar www.petrilac.com.ar</li>
                        <li>Terminación: Brillante y Satinada.</li>
                        <li>Limpieza de materiales: Lavar las herramientas de trabajo con aguarrás mineral después de utilizadas.</li>
                        <li>Mantenimiento y repintado: Retirar toda la suciedad con cepillo y aplicar una nueva mano cuando el deterioro recién ocurra.</li>
                        <li>Compatibilidad: No es compatible con otros preservadores ni con productos solubles en agua.</li>
                    </ul>
                    <p className='p-cards-comp'>Aviso de consulta técnica: para su mejor aplicación consultar al Departamento Técnico de QUIMICA del NORTE S.A.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Presentación: </span>1l, 4l y 20l.</p>
                    <p className='p-cards-comp'><span>Producto inflamable: </span>Categoría 3 (GHS).</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F5v1.png?alt=media&token=0f335807-19fa-4b6c-a2d1-2fbd552797f2' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const MasillaPetrilac = ()=>{
    return(
        <>
            <h4 className='title-placas'>PETRILAC MASILLA</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                <p className='p-cards-comp'>La MASILLA PETRILAC® es ideal para reparar agujeros, imperfecciones y juntas- inclusive en pisos de madera-.</p>
                <br/>
                <p className='p-cards-comp'>Formulada a base de fibras naturales de maderas, muy buenas emulsiones y aditivos de calidad que le otorgan la homogeneidad adecuada para obtener un excelente relleno, fácil aplicación y lijado. No se agrieta y se obtiene muy fácilmente el tono apropiado, para disimular el arreglo. Se utiliza en todo tipo de maderas en interiores y exteriores y en distintas superficies tales como muebles, aberturas, pisos, techos, instrumentos musicales, mesas, etc.; para conseguir una terminación lisa y uniforme.</p>
                    <ul>
                        <li>Base de emulsión vinil-acrílica.</li>
                        <li>Pigmentos inorgánicos.</li>
                        <li>Fórmula con fibras de madera.</li>
                        <li>Repara agujeros, imperfecciones</li>
                        <li>Alto poder de relleno, incluso para pisos.</li>
                        <li>Se une a la madera.</li>
                        <li>No cuartea ni agrieta.</li>
                        <li>SECADO: 2 a 4 horas, según el grosor aplicado.</li>
                        <li>RENDIMIENTO: Depende de la profundidad de la superficie reparada.</li>
                    </ul>
                    <img alt='colors-masilla' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F6v1.png?alt=media&token=3906bc08-8be9-461d-92f1-fc362a33ade0' loading='lazy' style={{width: '100%'}}/>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F6v2.png?alt=media&token=f9bf499e-2ea7-414f-bd01-b63717e28121' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const MateantePetrilac = ()=>{
    return(
        <>
            <h4 className='title-placas'>PETRILAC MATEANTE</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                <p className='p-cards-comp'>El MATEANTE UNIVERSAL PETRILAC® es una suspensión de ceras y sílices en solventes.</p>
                <br/>
                <p className='p-cards-comp'>Totalmente transparente y agregado a barnices y lacas no oculta la veta de la madera. Posee buena resistencia a los rayos UV y demás agentes climáticos, por lo que no afecta la duración de los revestimientos con respecto a los acabados brillantes.</p>
                <p className='p-cards-comp'>No afecta el secado de lacas y barnices, solamente atenúa el brillo.</p>
                    <ul>
                        <li>Atenúa el brillo de lacas, barnices y esmaltes.</li>
                        <li>Logra un satinado sin grumos ni asperezas.</li>
                        <li>Resiste a la intemperie.</li>
                        <li>No oculta las vetas de la madera.</li>
                        <li>Uso en exteriores e interiores. Atenúa el brillo de lacas, barnices y esmaltes.</li>
                        <li>Logra un satinado sin grumos ni asperezas.</li>
                    </ul>
                    <p className='p-cards-comp'><span>Presentación:</span> 100cc.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F7v1.png?alt=media&token=d468e5ed-2b86-4757-9721-6a417a0cbbf3' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const ProtectorUP = ()=>{
    return(
        <>
            <h4 className='title-placas'>PROTECTOR UP PARA MADERA</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <ul>
                        <li>Protege y decora.</li>
                        <li>No forma película.</li>
                        <li>Hidrorrepelente.</li>
                        <li>No cuartea ni descascara.</li>
                        <li>Hermosa gama de colores.</li>
                        <li>Uso exteriores e interiores.</li>
                        <li>Tiempos de secado: Seca al tacto: 4 horas. Entre manos: 6 a 8 horas, de acuerdo a las condiciones ambientales.</li>
                        <li>Mantenimiento y Repintado: Es conveniente retirar toda suciedad con cepillo y dar una nueva mano, cuando el deterioro recién ocurra.</li>
                        <li>Colores: Muy decorativos y mezclables entre sí: natural, roble oscuro, cedro, caoba, nogal.</li>
                    </ul>
                    <p className='p-cards-comp'><span>Presentación:</span> 1l, 4l y 20l.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F8v1.png?alt=media&token=03660c56-a6b3-4db1-85ee-23ed49c315f5' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Restaurador = ()=>{
    return(
        <>
            <h4 className='title-placas'>RESTAURADOR</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>RESTAURADOR LUSTRELAC® está formulado con aceites siliconados que protegen la madera, nutren su interior y a la vez reparan las marcas producidas por el uso diario.</p>
                    <p className='p-cards-com'>Resalta el color natural en una sola operación y con la repetición de las aplicaciones las marcas tienden a disimularse por completo.</p>
                    <br/>
                    <p>Da vida a la madera y le devuelve la nutrición necesaria para que luzca renovada. Es muy fácil de aplicar y se presenta en 2 tonos para embellecer los muebles deteriorados.</p>
                    <ul>
                        <li>Cubre manchas, arañazos.</li>
                        <li>Disimula rayones e imperfecciones de la madera.</li>
                        <li>Protege y lustra.</li>
                        <li>Contiene aceites nutrientes.</li>
                        <li>Resalta la calidez de la madera.</li>
                        <li>Da vida y renueva la madera.</li>
                    </ul>
                    <p className='p-cards-comp'><span>Presentación:</span> 250cm3</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F9v1.png?alt=media&token=541572e6-daf1-488a-ae80-0431e9d7adbf' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Tinta = ()=>{
    return(
        <>
            <h4 className='title-placas'>TINTA</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>La TINTA COLOR PETRILAC® con aceite de teka puede ser utilizada con barnices, plastificantes, lacas, protectores, stains, lasures (aplicación en vertical u horizontal – decks-) .Contiene aceite de teka que revive la madera, penetra, nutre y humecta su interior. Su doble filtro solar otorga más duración a barnices, lasures y lacas. No oculta la veta, respetando su belleza original con tonos muy naturales.</p>
                    <ul>
                        <li>Contiene aceite de teca que favorece la nutrición y humectación.</li>
                        <li>Revive la madera, protegiendo su interior y exterior.</li>
                        <li>Otorga doble Filtro UV a los productos de terminación.</li>
                        <li>Compatible con barnices, lasures y lacas.</li>
                    </ul>
                    <p className='p-cards-comp'><span>Teñido de pisos</span></p>
                    <p className='p-cards-comp'>Teñir primero la madera, dejar secar 24 hs. y aplicar el plastificante seleccionado (en condiciones normales de humedad y temperatura), para evitar el cambio de color en zonas de mayor tránsito. Lacas poliuretánicas, acuosas o nitro celulósicas, proceder igual a la aplicación de teñido de pisos. Sugerimos consultar a nuestro departamento técnico.</p>
                    <p className='p-cards-comp'>La tonalidad final varía según la dilución empleada, el color de la madera y el espesor aplicado.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Rendimiento</span></p>
                    <p className='p-cards-comp'>Está directamente relacionado con la intensidad de tono que se desee lograr, la absorción y rugosidad de la madera.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Presentación:</span> 60cc y 240cc</p>
                    <br/>
                    <img style={{width: '100%'}} alt='colores-tinta' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F10v2.png?alt=media&token=84612939-0b55-4eeb-aaca-2a327fcbd5eb' loading='lazy'/>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F10v1.png?alt=media&token=cbc3d9b0-93c4-44ad-91e4-a4223237fe49' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}




const Xylasol = ()=>{
    return(
        <>
            <h4 className='title-placas'>XYLASOL LASUR</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>XYLASOL® LASUR protector satinado está elaborado con resinas de última generación de alto grado de penetración, pigmentos inorgánicos, filtros UV y solventes de la mejor calidad. Protege del sol, lluvia, nieve, brisa marina y de los cambios bruscos de temperatura. Penetra profundamente sin formar película superficial y en consecuencia, no cuartea ni descascara. Repele el agua y no penetra en el interior, a la vez que la humedad es eliminada en forma de vapor; la madera "respira" y evita la formación de microorganismos. No oculta las vetas ni el aspecto natural de la madera, realzando su belleza con una terminación satinada. Es el más sencillo de aplicar y mantener.</p>
                    <ul>
                        <li>Exterior</li>
                        <li>Protege a la madera de los climas más extremos.</li>
                        <li>Inhibe la formación de hongos y microorganismos.</li>
                        <li>Hidrorrepelente, la humedad interior se elimina en forma de vapor.</li>
                        <li>Terminación satinada.</li>
                        <li>No cuartea ni descascara.</li>
                        <li>Fácil aplicación:</li>
                        <li>Mantenimiento sencillo</li>
                    </ul>
                    <p className='p-cards-comp'><span>Mantenimiento</span></p>
                    <p className='p-cards-comp'>Muy simple; sólo limpiar por medio de cepillo o de un suave lijado y aplicar una mano más. Recomendamos hacer un mantenimiento cada 2 años, según la exposición al sol a la que esté expuesta la madera. No requiere remover ni rasquetear antes de una nueva aplicación, con lo que simplifica notablemente el trabajo.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Rendimiento</span></p>
                    <p className='p-cards-comp'>14 a 15 m² por litro y por mano, según la absorción de la madera.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Tiempo de Secado</span></p>
                    <p className='p-cards-comp'>Al tacto, 4 horas. De 6 a 8 horas entre manos. En maderas muy resinosas -caso incienso-, el secado puede ser más lento. Para evitarlo, frotar fuerte y reiteradamente con un paño embebido en thinner.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Limpieza</span></p>
                    <p className='p-cards-comp'>Para los elementos de trabajo, limpiarlos con aguarrás mineral inmediatamente después de utilizados.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Tonalidades:</span></p>
                    <p className='p-cards-comp'>Satinada: Natural - Cristal - Cedro - Roble Claro - Roble Oscuro - Caoba - Nogal</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F11v1.png?alt=media&token=5e0a185d-6f52-4620-bb84-052e017d897e' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Barniz = ()=>{
    return(
        <>
            <h4 className='title-placas'>BARNIZ</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>El barniz marino INTEMPERIE®, combina en un sólo producto las ventajas de la excelente penetración de un impregnante con una terminación de alto brillo, muy decorativo para exteriores e interiores.</p>
                    <br/>
                    <p>Su doble protección se obtiene "hacia dentro": debido a que penetra profundamente, fija las fibras de la madera y las mantiene unidas, afirmando su estructura; y "hacia fuera": formando una película muy resistente, pero a la vez flexible que evita el ingreso y la destrucción producida por el agua, oxígeno, la luz UV, hongos, viento y la erosión. Contiene aditivos inhibidores de la oxidación ocasionada por la nociva irradiación UV, que hacen que el superior brillo permanezca inalterable por más tiempo. Acompaña los movimientos de la madera, soportando cualquier clima: marino, montañoso, pampeano, del litoral e incluso tropicales.</p>
                    <ul>
                        <li>Alto brillo radiante, que embellece la veta.</li>
                        <li>Doble acción protectora: penetración más resistencia.</li>
                        <li>Con filtros solares.</li>
                        <li>Soporta cualquier clima.</li>
                        <li>No cuartea ni descascara.</li>
                        <li>Superior pintabilidad y nivelación.</li>
                    </ul>
                    <p className='p-cards-comp'><span>Dilución</span></p>
                    <p className='p-cards-comp'>Este producto no requiere dilución para su aplicación.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Mantenimiento</span></p>
                    <p className='p-cards-comp'>Limpiar el piso diariamente con un paño humedecido con agua.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Rendimiento</span></p>
                    <p className='p-cards-comp'>De 10 a 12 m² por litro y por mano de aplicación, dependiendo de la absorción de la madera.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Tiempo de Secado</span></p>
                    <p className='p-cards-comp'>Al tacto entre 4 y 6 horas.</p>
                    <p className='p-cards-comp'>Entre manos 7 y 8 horas dependiendo de temperatura y humedad ambiente.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Limpieza</span></p>
                    <p className='p-cards-comp'>Los utensilios se limpian con aguarrás.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Presentación:</span> 1l y 4l.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F12v1.png?alt=media&token=0eb605d3-cf3a-4350-9492-6471e0058065' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Celocrom = ()=>{
    return(
        <>
            <h4 className='title-placas'>CELOCROM</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>El PLASTIFICADOR CELOCROM® es un producto de dos componentes de sobresalientes características destinado a recubrir superficies de madera que serán sometidas a duras exigencias: pisos de parquet, tarugados, muebles de uso intensivo (mesas, estanterías, sillas), etc</p>
                    <ul>
                        <li>Brillante y satinado.</li>
                        <li>Forma una película brillante de excelente dureza y resistente a las manchas de grasa.</li>
                        <li>Transparente.</li>
                        <li>alcohol y la mayoría de los productos de uso doméstico de limpieza.</li>
                        <li>Se limpia fácilmente con un paño con agua.</li>
                        <li>Resistencia a la abrasión.</li>
                    </ul>
                    <p className='p-cards-comp'><span>Dilución</span></p>
                    <p className='p-cards-comp'>Este producto no requiere dilución para su aplicación.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Mantenimiento</span></p>
                    <p className='p-cards-comp'>Limpiar el piso diariamente con un paño humedecido con agua.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Rendimiento</span></p>
                    <p className='p-cards-comp'>Rinde 10 m² por litro y por mano dependiendo de la porosidad y absorción de la madera y la calidad del lijado.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Secado</span></p>
                    <p className='p-cards-comp'>Entre manos esperar mínimo una hora a 25 °C. El secado dependerá de la humedad y temperatura ambiente. No aplique PLASTIFICANTE CELOCROM® a temperaturas inferiores a 15 °C y humedad cercana al 100%.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Limpieza</span></p>
                    <p className='p-cards-comp'>Las herramientas de trabajo limpiarlas con alcohol o thinner, inmediatamente despues de usarlas. Ventile el ambiente donde se aplique PLASTIFICADOR CELOCROM® para evitar acumulación de vapores. Evitar inhalar los vapores. En caso de derrames absorber con materiales apropiados. En caso de derrame del endurecedor, lavar con abundante agua. Evitar el contacto con la piel. Usar guantes apropiados y protección ocular.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Accesorios</span></p>
                    <p className='p-cards-comp'>Por cada litro, viene una unidad de Endurecedor</p>
                    <br/>
                    <p className='p-cards-comp'><span>Presentación:</span> 1l y 4l.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F13v1.png?alt=media&token=c638880a-fe93-4f55-a782-7fdabd88c685' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Celoplast = ()=>{
    return(
        <>
            <h4 className='title-placas'>CELOPLAST</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>CELOPLAST®, plastificante para pisos ureico de 2 componentes, está elaborado con resinas, aditivos y solventes de la mejor calidad. Es un producto de calidad comprobada a través del tiempo, con amplia aceptación de los profesionales. Es indicado para toda clase de maderas en interiores y semisombras para pisos, muebles, aberturas, revestimientos, los que quedan protegidos con una película lavable, natural, resistente, durable y decorativa.</p>
                    <ul>
                        <li>Para pisos de madera.</li>
                        <li>Excelente formación de película.</li>
                        <li>Transparente.</li>
                        <li>No oculta la veta ni cambia el color de la madera.</li>
                        <li> Económico. La mejor relación costo - beneficio.</li>
                        <li>Muy durable.</li>
                        <li>De fácil mantenimiento.</li>
                        <li>Acabados brillante y satinado, muy decorativos.</li>
                    </ul>
                    <p className='p-cards-comp'><span>Tiempo de secado</span></p>
                    <p className='p-cards-comp'>6 horas, puede requerir 8 horas entre manos.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Mantenimiento</span></p>
                    <p className='p-cards-comp'>Limpiar el piso diariamente con un paño humedecido con agua. Cuando el desgaste lo requiera se lijará hasta obtener un mateado parejo y se aplicará una mano más.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Rendimiento</span></p>
                    <p className='p-cards-comp'>10 a 12 m² por litro y por mano, dependiendo de la porosidad y absorción de la madera y la calidad del lijado.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Limpieza</span></p>
                    <p className='p-cards-comp'>Las herramientas de trabajo higienizarlas con alcohol o thinner, inmediatamente de usadas.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Accesorios</span></p>
                    <p className='p-cards-comp'>Por cada litro, viene una unidad de Endurecedor</p>
                    <br/>
                    <p className='p-cards-comp'><span>Presentación:</span> 1l y 4l.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F14v1.png?alt=media&token=3ce21fd5-a791-47d7-b036-b8b874834383' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const Insecticida = ()=>{
    return(
        <>
            <h4 className='title-placas'>INSECTICIDA</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>POLILAK® INSECTICIDA está elaborado con cyflutrina de Bayer AG y solventes, está indicado para erradicar y prevenir, por efecto residual, el ataque de insectos xilófagos, destructores de la madera, como taladros, polillas y termitas. Se aplica en interiores y exteriores</p>
                    <br/>
                    <p className='p-cards-comp'>Preparados para decorar y cuidar superficies de maderas en exteriores e interiores, dejando lucir las vetas y tonos naturales, de fácil aplicación y mantenimiento.</p>
                    <ul>
                        <li>Destruye larvas y adultos de insectos xilófagos.</li>
                        <li>No forma película.</li>
                        <li>No cambia el color ni la textura de la madera.</li>
                        <li>Se aplica a pincel o por inmersión.</li>
                        <li>Sirve como base de cualquier tipo de revestimiento.</li>
                        <li>Gran protección durante años.</li>
                    </ul>
                    <br/>
                    <p className='p-cards-comp'><span>Presentación:</span> 1l y 4l.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F15v1.png?alt=media&token=65e59f46-4c0a-4365-b280-9a90510696ce' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Limpiador = ()=>{
    return(
        <>
            <h4 className='title-placas'>LIMPIADOR</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>El LIMPIADOR RENOVADOR POLILAK® para maderas está elaborado a base de ácido oxálico. Restaura las maderas deterioradas por la acción de la intemperie, decoloradas, ennegrecidas y/o manchadas por salpicaduras de cal o cemento. Limpia, aclara, devuelve el color original, otorgando aspecto natural. Su consistencia de gel, facilita la aplicación en vertical, con menor mano de obra, evitando chorreaduras</p>
                    <ul>
                        <li>Consistencia de gel, no chorrea en la aplicación vertical.</li>
                        <li>Fórmula biodegradable.</li>
                        <li>No es inflamable.</li>
                        <li>Baja penetración, no ataca la estructura interior de la madera.</li>
                        <li>Fácil aplicación y lavado.</li>
                        <li>Apto para muebles, aberturas, machimbres, pisos de madera, etc</li>
                    </ul>
                    <br/>
                    <p className='p-cards-comp'><span>Rendimiento</span></p>
                    <p className='p-cards-comp'>Aproximadamente 8 a 10 m² dependiendo del estado y porosidad de la madera.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Precauciones:</span></p>
                    <p className='p-cards-comp'>Aplicar en lugares ventilados. Usar guantes de PVC y protectores visuales. No inhalar. Evitar el contacto con la piel, ojos o mucosas, en caso de producirse lavar con agua y jabón. En caso de ingestión inducir al vómito, beber abundante agua y consultar inmediatamente al sector de toxicología del Hospital de Niños u Hospital Posadas. Mantener el envase tapado mientras no se usa y lejos del alcance de los niños.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Presentación:</span> 1l.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F16v1.png?alt=media&token=bf7104d3-3027-45dc-a122-cac9ec80739f' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Masilla = ()=>{
    return(
        <>
            <h4 className='title-placas'>POLILAK MASILLA</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>La Masilla para maderas POLILAK® está elaborada a base de emulsión vinílica, cargas inertes, pigmentos inorgánicos, derivado de la celulosa, aditivos y agua. Está diseñada para reparar agujeros, hendiduras, juntas e imperfecciones en todo tipo de madera, en interiores o exteriores. Por su gran poder de relleno es ideal para juntas entre tablas, incluyendo las de pisos. No se agrieta, es fácil de lijar y su variedad de colores -mezclables entre sí - permiten alcanzar el tono justo para disimular la reparación efectuada.</p>
                    <ul>
                        <li>Fácil aplicación a espátula.</li>
                        <li>No cuartea ni agrieta, aun en capas gruesas.</li>
                        <li>Muy buena lijabilidad.</li>
                        <li>Admite clavos y/o tornillos.</li>
                        <li>Colores muy naturales.</li>
                        <li>Absorción similar a la madera.</li>
                    </ul>
                    <br/>
                    <p className='p-cards-comp'><span>Rendimiento</span></p>
                    <p className='p-cards-comp'>Está directamente relacionado con la profundidad de la superficie reparada.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Tiempo de secado:</span></p>
                    <p className='p-cards-comp'>De 2 a 4 horas de acuerdo al espesor aplicado.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Presentación:</span> 125g y 225g.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F17v1.png?alt=media&token=d987167d-71a2-4577-821a-dbfe8130a6f1' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const Mateante = ()=>{
    return(
        <>
            <h4 className='title-placas'>POLILAK MATEANTE</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>MATEANTE POLILAK® es una dispersión de ceras y sílice en solventes. Es universal, disminuye el brillo de lacas, barnices y esmaltes, tanto en interiores como en exteriores. Es transparente, deja lucir la veta de la madera, brinda tersura a la terminación.</p>
                    <ul>
                        <li>Base solvente.</li>
                        <li>Resistente a la radiación solar.</li>
                        <li>Baja el brillo, logrando acabados semimates o satinados.</li>
                        <li>Transparente, no oculta la veta de la madera.</li>
                        <li>Terminación muy tersa, sin grumos ni asperezas.</li>
                        <li>No afecta la duración de los acabados expuestos a la intemperie.</li>
                    </ul>
                    <br/>
                    <p className='p-cards-comp'><span>Rendimiento</span></p>
                    <p className='p-cards-comp'>12 a 16 m² por litro y por mano dependiendo de la absorción de la superficie tratada.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Tiempo de secado:</span></p>
                    <p className='p-cards-comp'>Al tacto: 12 horas.</p>
                    <p className='p-cards-comp'>Secado final: 24 horas.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Limpieza:</span></p>
                    <p className='p-cards-comp'>La limpieza de herramientas se realiza inmediatamente luego de usadas con aguarrás mineral.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Presentación:</span> 100cm3 y 500cm3.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F18v1.png?alt=media&token=26f45800-62bf-43a3-89ad-0a2dc66d9d76' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const Sellagres = ()=>{
    return(
        <>
            <h4 className='title-placas'>SELLAGRES DECORATIVOS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>SELLAGRES® Decorativo es un protector al solvente satinado, que decora y embellece ladrillos, tejas, cerÂ·micos no esmaltados y materiales de frente porosos. Elaborado a base de resinas alquídicas modificadas, pigmentos inorgánicos, solventes alifáticos, aditivos y filtros UV, en un perfecto balance. Por sus tensoactivos penetra profundamente en el sustrato, protegiéndolo desde su interior. Es hidrorrepelente y a la vez, la humedad interior sale al exterior en forma de vapor, regulando el contenido de agua e impidiendo la formación de hongos, moho y verdín. Mantiene por largo tiempo el aspecto natural de la superficie, con muy fácil aplicación y sencillo mantenimiento.</p>
                    <ul>
                        <li>Protector hidrorrepelente para ladrillos, tejas y cerámicos.</li>
                        <li>Micropelícula de aspecto natural.</li>
                        <li>Hidrorrepelente.</li>
                        <li>Contiene filtro solar, no decolora.</li>
                        <li>Muy decorativo.</li>
                        <li>No descascara ni cuartea.</li>
                        <li>Regula la humedad interna, inhibe la formación de hongos, moho y salitre.</li>
                    </ul>
                    <br/>
                    <p className='p-cards-comp'><span>Rendimiento</span></p>
                    <p className='p-cards-comp'>12 a 16 m² por litro y por mano dependiendo de la absorción de la superficie tratada.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Tiempo de secado:</span></p>
                    <p className='p-cards-comp'>Al tacto: 12 horas.</p>
                    <p className='p-cards-comp'>Secado final: 24 horas.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Limpieza:</span></p>
                    <p className='p-cards-comp'>La limpieza de herramientas se realiza inmediatamente luego de usadas con aguarrás mineral.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Presentación:</span> 1l y 4l</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F19v1.png?alt=media&token=618288ce-ec74-448a-a51c-10ab9f705d1d' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Decks = ()=>{
    return(
        <>
            <h4 className='title-placas'>DECK</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>Indicado para superficies horizontales de madera como decks, aleros, techos, puertas, ventanas, tabiques, etc. Permite mezclar tonos entre si o con la versión natural para obtener nuevas tonalidades. Atención: el tono final alcanzado dependerá de la coloración natural de la madera.</p>
                    <ul>
                        <li>Alta resistencia al desgaste.</li>
                        <li>Para superficies horizontales de madera.</li>
                        <li>Protege del sol y del agua.</li>
                        <li>Realza las vetas con colores firmes.</li>
                        <li>Es transitable.</li>
                        <li>No cuartea.</li>
                        <li>Uso interior y exterior.</li>
                    </ul>
                    <p className='p-cards-comp'><span>Rendimiento</span></p>
                    <p className='p-cards-comp'>Óptimo rendimiento.</p>
                    <p className='p-cards-comp'>8 a 14 m² / litro / mano.</p>
                    <p className='p-cards-comp'>El rendimiento puede variar conforme a la absorción de cada especie de madera y del tipo de acabado final deseado.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Tiempo de secado:</span></p>
                    <p className='p-cards-comp'>Secado Total: 24 hs.</p>
                    <p className='p-cards-comp'>Secado al Tacto: 8 hs.</p>
                    <p className='p-cards-comp'>En superficies interiores o madera dura el tiempo de secado puede extenderse.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Presentación:</span> 1l y 4l</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F20v1.png?alt=media&token=7d475705-6bb5-44a0-835c-7b580a4195a6' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const UVrevestimiento = ()=>{
    return(
        <>
            <h4 className='title-placas'>UV REVESTIMIENTO PARA MADERA</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>POLILAK UV está elaborado con resinas poliéster modificadas de alta resistencia a la intemperie, aditivos absorbedores de los rayos ultravioletas y mezcla de solventes. De fácil aplicación y mantenimiento, forma una película muy decorativa, que protege a la madera por más tiempo, acompañando los movimientos de contracción y dilatación, sin cuartear ni descascararse. Resiste los cambios bruscos de temperatura, lluvia, nieve y brisa marina. Es ideal para aplicar en embarcaciones, aberturas, cenefas, etc. Posee excelente pintabilidad, nivelamiento y retención de brillo.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Rendimiento</span></p>
                    <p className='p-cards-comp'>10 a 12 m² por litro y por mano, de acuerdo a la absorción de la madera y forma de aplicación.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Tiempo de secado:</span></p>
                    <p className='p-cards-comp'>De 8 a 10 horas, dependiendo de humedad y temperatura ambiente y del espesor de película aplicada.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Limpieza:</span></p>
                    <p className='p-cards-comp'>Las herramientas deberán lavarse con aguarrás mineral inmediatamente después de usadas.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Presentación:</span> 1l y 4l</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F21v1.png?alt=media&token=c59258cb-bfa8-4039-8f98-7ff89ed6d81e' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Preservador = ()=>{
    return(
        <>
            <h4 className='title-placas'>PRESERVADOR INSECTICIDA</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>PENTA EXTERIOR LISTO PARA USAR es un preservador de acción preventiva para evitar el ataque de hongos e insectos y de acción curativa cuando las maderas han sido atacadas. PENTA EXTERIOR LISTO PARA USAR reemplaza la barniceta. Se puede aplicar antes o después de la colocación de la madera. Siendo aconsejable siempre el tratamiento previo. El preservador para maderas PENTA EXTERIOR LISTO PARA USAR debe emplearse siempre sin diluir. Evita el ataque de:</p>
                    <br/>
                    <p>Coleópteros: Hylotrupes bajulus (taladro del pino),Anobium punctatum (polilla), Lyctus brunneus (polilla).</p>
                    <br/>
                    <p className='p-cards-comp'>Himenópteros: Camponotus sp (hormiga carpintera),Sirex sp (avispa carpintera), Xylocopa sp (abeja carpintera).</p>
                    <br/>
                    <p className='p-cards-comp'>Isópteros: Nasutitermes sp (termita) y hongos.</p>
                    <ul>
                        <li>Funguicida.</li>
                        <li>Insecticida.</li>
                        <li>Preservador para madera.</li>
                        <li>Erradicación.</li>
                        <li>Prevención.</li>
                    </ul>
                    <p className='p-cards-comp'><span>Precauciones</span></p>
                    <p className='p-cards-comp'>Evite el contacto con la piel y los ojos. Evite respirar los vapores del producto y ventile los ambientes donde se haya utilizado el preservador. Mantenga el producto lejos de las llamas o lugares de alta temperatura. Usar antiparras, guantes, calzado y ropa adecuados durante la aplicación de este producto.</p>
                    <br/>
                    <p className='p-cards-comp'>Riesgos Ambientales:</p>
                    <p className='p-cards-comp'>Tóxico para abejas: No aplicar para preservar colmenares</p>
                    <p className='p-cards-comp'>Ligeramente tóxico para aves silvestres.</p>
                    <p className='p-cards-comp'>Extremadamente tóxico para peces: no contaminar fuentes o cursos de agua ni derramar sus residuos en lagos, ríos, alcantarillas fuentes de agua o áreas no especificadas. Mantenga alejado a los animales domésticos de los lugares de aplicación.</p>
                    <br/>
                    <p className='p-cards-comp'>Almacenamiento:</p>
                    <p className='p-cards-comp'>Cerrar bien los envases no agotados y no reutilizar los vacíos. Mantener el producto bajo techo, en lugar fresco, seco y ventilado. Temperatura máxima 40ºC y mínima 5ºC.</p>
                    <p className='p-cards-comp'>Tratamiento de derrames y envases vacíos: En caso de producirse un derrame, cubrir con material absorbente (arena, aserrín, etc), recoger y colocar en contenedores apropiados. No drenar hacia cursos de agua o alcantarillas que conduzcan a estos.</p>
                    <br/>
                    <p className='p-cards-comp'>Método de destrucción de envases:</p>
                    <p className='p-cards-comp'>Los envases se inutilizarán perforándolos y comprimiéndolos para evitar su reutilización</p>
                    <br/>
                    <p className='p-cards-comp'><span>Presentación:</span> 1l, 4l, 10l y 18l.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPinturas%2F22v1.png?alt=media&token=53e24e38-f5fb-4d4b-bab8-90e45b6ba7d4' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}