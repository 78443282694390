import React from 'react';
import './ItemService.css';

const ItemService = ({title, text, img}) => {
  return (
    <div className='card-service-container'>

        <div className='card-service' style={{'backgroundImage': `url(${img})`}}>
            <div className='card-content'>
                <h5>{title}</h5>
                <p>{text}</p>
            </div>  
        </div>
        
    </div>
  )
}

export default ItemService;