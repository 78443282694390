import React, { useState } from 'react';
import Banner from '../../../components/Banner/Index';
import './Aislacion.css';

const Aislacion = () => {
    const [comp, setComp] = useState('');

    const switchComponent = (selec)=>{
        switch(selec){
            case '': return <Default/>;
            case 'Hidrofuga - Membrana wichi Roofing': return <HidrofugaWichi/>;
            case 'Hidrofuga - Membrana asfalticas': return <HidrofugaAsfaltica/>;
            case 'Termica y acustica - Techos': return <TechosTermica/>;
            case 'Termica y acustica - Tabiques': return <TabiquesTermica/>;
            case 'Termica y acustica - Estructuras metalicas': return <EstructurasTermica/>;
            default: return <Default/>
        }
    }

  return (
    <>
        <Banner title='Aislacion'/>
        <div className='products-container'>
            <div className='submenu-products'>
                <div className='div-submenu'>
                    <h5 onClick={()=> setComp('')}>Aislacion</h5>
                    <div className='buttons-submenu'>
                        <button onClick={()=> setComp('Hidrofuga - Membrana wichi Roofing')} type='button'>Hidrófuga - Membrana wichi Roofing</button>
                        <button onClick={()=> setComp('Hidrofuga - Membrana asfalticas')} type='button'>Hidrófuga - Membrana asfálticas</button>
                        <button onClick={()=> setComp('Termica y acustica - Techos')} type='button'>Térmica y acústica - Techos</button>
                        <button onClick={()=> setComp('Termica y acustica - Tabiques')} type='button'>Térmica y acústica - Tabiques</button>
                        <button onClick={()=> setComp('Termica y acustica - Estructuras metalicas')} type='button'>Térmica y acústica - Estructuras metálicas</button>
                    </div>
                </div>
            </div>
            <div className='content-products-container'>
                {switchComponent(comp)}
            </div>
        </div>
    </>
  )
}

export default Aislacion;


const Default = ()=>{
    return (
        <>
            <img className='img-default' alt='const-en-seco' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FAislacion%2Fprincipal.jpg?alt=media&token=9ac0ef8f-8183-47ea-ab3f-8984ccc8ff5f' loading='lazy'/>
            <p>
                Los sistemas de aislación permiten limitar las pérdidas de calor y evitar así la formación de moho en las paredes internas. Limitando el intercambio de calor entre el espacio interior y exterior se puede aislar el edificio con materiales que tengan muy baja conductividad y de espesor adecuado. Ahorrando una gran cantidad de energía para calentar y mejorar el confort y la calidad del aire microclimático.            
            </p>
        </>
    )
}


const HidrofugaWichi = ()=>{
    return(
        <>
            <h4 className='title-placas'>HIDRÓFUGA - MEMBRANA WICHI ROOFING</h4>
            <p>WICHI Roofing es una membrana fuerte y flexible, hidrófuga y controladora de vapor para techos inclinados y paredes en construcción industrializada, conformada a partir de 3 capas de polipropileno.</p>
            <p>Gracias a la utilización de una membrana técnica inteligente que posee una alta impermeabilidad (hasta 6000mm de Columna de agua) y respirabilidad, es altamente resistente al ingreso de agua y humedad a la vez que permite que el vapor generado en el interior de los ambientes sea ventilado hacia el exterior.</p>
            <br/>
            <h4 style={{color: '#636363'}}>Ventajas:</h4>
            <ul>
                <li>Resistente al paso del agua hasta 600cm de columna de agua.</li>
                <li>Excelente barrera de viento, disminuyendo el consumo de energía.</li>
                <li>Permeable al vapor, controla el vapor que ingresa a las estructura del techo evitando la condensación.</li>
                <li>WICHI no propaga la llama.</li>
                <li>Alta resistencia al rasgado, fue probado resistiendo vientos de hasta 130km/h.</li>
                <li>Antideslizante.</li>
                <li>Posee WICHI Tac, una banda adhesiva que facilita el montaje y sella el ingreso de aire reduciendo el consumo energético.</li>
                <li>Resiste hasta 4 meses a la intemperie, una vez cubierto su vida es ilimitada.</li>
                <li>No es atacado por insectos ni roedores.</li>
                <li>Evita la entrada de polvo.</li>
                <li>Posee CAT, certificado de aptitud técnica expedido por la Subsecretaría de la vivienda dependiente este del Ministerio de Planificación Federal, por lo que no son aptas para su uso en obras del estado.</li>
            </ul>
            <br/>
            <p className='p-cards-comp'><span>Presentación: </span>rollos de 1.20 x 13m = 15m² - rollos de 1.20 x 25m = 30m².</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FAislacion%2F1v1.png?alt=media&token=e6d34b3d-fbe5-47b9-bee3-d07c7eff3b1e' loading='lazy'/>
        </>
    )
}


const HidrofugaAsfaltica = ()=>{
    return(
        <>
            <h4 className='title-placas'>HIDRÓFUGA - MEMBRANAS ASFÁLTICAS</h4>
            <p>Una membrana asfáltica es un material impermeabilizante conformado por varias capas que le otorgan propiedades físicas, químicas y mecánicas. Puede ser hecha in situ cuando las sucesivas capas se aplican sobre el sustrato a impermeabilizar, o preelaboradas cuando el fabricante la provee armada y lista para su colocación.</p>
            <br/>
            <p>Se clasifican en tres clases:</p>
            <br/>
            <ul>
                <li><p className='p-cards-comp'><span>Membranas no expuestas a la intemperie.</span> Son aquellas que necesitan una protección adicional para permanecer a la intemperie. Ej.: membranas con terminación superior de polietileno o geotextil pintado con asfalto.</p></li>
                <li><p className='p-cards-comp'><span>Membranas aptas para estar a la intemperie, no transitables.</span> Ej.: membranas con terminación superior de aluminio.</p></li>
                <li><p className='p-cards-comp'><span>Membranas aptas para estar a la intemperie y transitables.</span> Ej: membranas con terminación geotextil pintado con revestimiento impermeable acrílico.</p></li>
            </ul>
            <br/>
            <p>El espesor es fundamental porque determina que pueda tener la masa asfáltica mínima y necesaria para lograr una membrana de muy buenas resistencias físicas y mecánicas. En este sentido, las membranas de espesores totales superiores a los 3,5 mm y de peso total mínimo 4 kg/m2 son las que nos aseguran esa calidad.</p>
            <br/>
            <p>Ademas contamos con toda la línea de pinturas asfálticas y aluminizadas.</p>
            <h4 style={{color: '#636363', fontSize: '32px', fontWeight: '500'}}>Ventajas:</h4>
            <h4 style={{color: '#636363', fontSize: '20px'}}>MEMBRANA ALUMINIO NO CRACK COLOR</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>Nueva Membrana MEGALUM con revestimiento de aluminio NO CRACK COLOR (Aluminio Flexible). Permite decorar el techo con revestimiento COLOR y reducir la temperatura interior de los ambientes ahorrando energía, y colaborando con el medio ambiente.</p>
                    <p className='p-cards-comp'>Posee armadura central de polietileno de alta densidad y doble capa asfáltica. El revestimiento de Aluminio NO CRACK COLOR, extiende aún más la vida útil del producto y brinda mayor seguridad al sistema de impermeabilización. Posee protección UV actuando como barrera reflectora de rayos ultravioletas, reduciendo la absorción térmica, protegiendo la membrana del envejecimiento y ofreciendo una impermeabilización efectiva y duradera.</p>
                    <p className='p-cards-comp'>Es especificada para resolver impermeabilizaciones sobre superficies donde la membrana quede expuesta a la intemperie, en techos planos o curvos inaccesibles, terrazas de escasa accesibilidad, azoteas de edificios, sobre techos de fuerte pendiente, sobre chapas metálicas o de fibrocemento.</p>
                    <p className='p-cards-comp'><span>Presentación: </span>rollos de 10m².</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FAislacion%2F2v1.png?alt=media&token=f5ad8ebe-1de2-49d3-902e-278e84ab8de2' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <h4 style={{color: '#636363', fontSize: '20px'}}>MEMBRANA ALUMINIO NO CRACK COLOR MGX</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>La nueva Membrana MGX con aluminio NO CRACK (Aluminio Flexible) incrementa la resistencia mecánica ante las distintas solicitaciones que se producen sobre la membrana. Elaborada con Asfalto Plástico Megaflex, posee armadura central de polietileno de alta densidad y revestimiento de Aluminio NO CRACK, que extiende la vida útil del producto y brinda mayor seguridad al sistema de impermeabilización. Actúa además como barrera reflectora de rayos ultravioletas, reduciendo la absorción térmica, protegiendo la membrana del envejecimiento y ofreciendo una impermeabilización efectiva y duradera.</p>
                    <p className='p-cards-comp'>Es especificada para resolver impermeablilizaciones sobre superficies donde la membrana quede expuesta a la intemperie.</p>
                    <p className='p-cards-comp'><span>Presentación: </span>rollos de 10m².</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FAislacion%2F2v2.png?alt=media&token=d3829e33-0c02-4b99-b1fe-605bc2d2b079' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <h4 style={{color: '#636363', fontSize: '20px'}}>MEMBRANA TRANSITABLE GEOTRANS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>Membrana elaborada con Asfalto Plástico Megaflex, con revestimiento de Geotextil (Poliéster) de hilo continuo, formando un tejido entramado al azar en todas direcciones, llamado tejido no tejido; y con resinas que aumentan sus propiedades de adhesión a la capa asfáltica.</p>
                    <p className='p-cards-comp'>Posee armadura central de polietileno de alta densidad y doble capa asfáltica. Ideal para impermeabilizaciones que requieran alta resistencia mecánica y/o transitabilidad con una excelente terminación estética obteniendo además una impermeabilización eficiente y duradera. En impermeabilizaciones expuestas, una vez aplicada la membrana, se debe pintar la superficie con Pintura Geotrans o Revestimiento Geotrans.</p>
                    <p className='p-cards-comp'>Para resolver impermeabilizaciones sobre superficies donde la membrana quede expuesta a la intemperie, inclusive cuando esta sea sometida a un tránsito intensivo. También en impermeabilizaciones bajo piso, otorgando mayor seguridad ante los posibles desgarros y perforaciones que pueden producirse por la naturaleza misma de la obra.</p>
                    <p className='p-cards-comp'>Algunos ejemplos de impermeabilización, que incluyen a este producto, son:</p>
                    <p className='p-cards-comp'>Cubiertas planas de hormigón, terrazas accesibles, azoteas de edificios, muros de contención, en canteros, bañeras, piscinas, canaletas, balcones, sótanos, cursos de agua, túneles subterráneos, en impermeabilizaciones bajo baldosa o bajo carpeta, etc.</p>
                    <p className='p-cards-comp'><span>Presentación: </span>rollos de 10m².</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FAislacion%2F2v3.png?alt=media&token=778a182e-91f8-4aba-97b7-bea8d7aeec5c' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <h4 style={{color: '#636363', fontSize: '32px', fontWeight: '500'}}>ORMIFLEX:</h4>
            <h4 style={{color: '#636363', fontSize: '20px'}}>MEMBRANA TRANSITABLE GEOTRANS</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>Membranas con asfalto plástico Nº1 polimerizado, refuerzo central e inferior de polietileno de alta densidad, doble capa asfáltica y cubierta superior de foil de aluminio Aluar, que le confiere elevada resistencia a los rayos UV.</p>
                    <p className='p-cards-comp'>Usos: Impermeabilizaciones donde la membrana quede expuesta a la intemperie y que no requiera tránsito. Ejemplos: Azoteas de edificios, techos planos o curvos inaccesibles, chapa metálica o fibrocemento y sistemas bicapa de techos ajardinados. También pueden utilizarse como barrera de vapor.</p>
                    <p className='p-cards-comp'>Aplicación y consumo: Imprimar antes con Ormiflex A o Pintura Asfáltica Profesional, sellar grietas y fisuras existentes con Ormiflex 5 y calentar la parte inferior para adherirla al sustrato, solapandola de 8 a 10 cm entre rollos. Proteger las uniones con pintura asfáltica aluminizada Ormiflex 8. Proteger las uniones con pintura asfáltica aluminizada Ormiflex 8.</p>
                    <p className='p-cards-comp'><span>Presentación: </span>rollos de 10m².</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FAislacion%2F2v4.png?alt=media&token=ae4d0d24-de4c-4efb-ad0c-7f9bc9f35861' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <h4 style={{color: '#636363', fontSize: '20px'}}>MEMBRANA ASFÁLTICA CON ALUMINIO FLEXIBLE TRICAPA</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>Membranas con asfalto plástico Nº1 polimerizado, refuerzo central e inferior de polietileno de alta densidad, doble capa asfáltica y cubierta superior de Aluminio Flexible Tricapa.</p>
                    <p className='p-cards-comp'>Usos: Impermeabilizaciones donde la membrana quede expuesta a la intemperie y que no requiera tránsito. Ejemplos: Azoteas de edificios, techos planos o curvos inaccesibles, chapa metálica o fibrocemento.</p>
                    <p className='p-cards-comp'>Aplicación y consumos: Imprimar antes con Ormiflex A o Pintura Asfáltica Profesional, sellar grietas y fisuras existentes con Ormiflex 5 y calentar la parte inferior para adherirla al sustrato solapándola de 8 a 10 cm entre rollos. Proteger la uniones con Pintura Asfáltica de Aluminio Ormiflex 8.</p>
                    <p className='p-cards-comp'><span>Presentación: </span>rollos de 10m².</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FAislacion%2F2v5.png?alt=media&token=d66c18d7-8123-4a65-825b-5d39e073a975' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <h4 style={{color: '#636363', fontSize: '20px'}}>MEMBRANA ASFÁLTICA CON GEOTEXTIL</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-60'>
                    <p className='p-cards-comp'>Membranas con asfalto plástico Nº1 polimerizado, refuerzo central e inferior de polietileno de alta densidad, doble capa asfáltica y cubierta superior de Geotextil ultra resistente de 150grs., que le confiere elevada resistencia mecánica a la tracción, el punzonado y granizo.</p>
                    <p className='p-cards-comp'>Usos: Impermeabilizaciones donde la membrana quede expuesta a la intemperie y que requieran de un tránsito moderado. Impermeabilizaciones donde la membrana sea colocada bajo piso o como un elemento del sistema. Ejemplos: azoteas de edificios, piscinas, balcones, túneles subterráneos, terrazas accesibles, bajo decks de madera, techos de madera, canteros, bañeras y sistemas Bicapa de techos ajardinados.</p>
                    <p className='p-cards-comp'>Aplicación y consumo: Imprimar antes con Ormiflex A o Pintura Asfáltica Profesional, sellar grietas y fisuras existentes con Ormiflex 5 y calentar la parte inferior para adherirla al sustrato, solapándola de 8 a 10 cm entre rollos. Proteger el Geotextil, pintándolo con Membrana Liquida Profesional.</p>
                    <p className='p-cards-comp'><span>Presentación: </span>rollos de 10m².</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FAislacion%2F2v6.png?alt=media&token=34a3cfc9-c158-4339-b6d7-97017c2ea7eb' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const TechosTermica = ()=>{
    return(
        <>
            <h4 className='title-placas'>TÉRMICA Y ACÚSTICA - TECHOS</h4>
            <p>En una construcción, la cubierta es la parte de la envolvente de un edificio a través de la cual se dan hasta un 40% de las pérdidas energéticas. En invierno se encuentra sometida a fuertes perdidas de energía y en verano expuesta a una gran cantidad de radiaciones solares. Por este motivo, es uno de los puntos primordiales al momento de pensar en aislación.</p>
            <br/>
            
            <h4 style={{color: '#636363'}}>BAJO TEJA HIDRORREPELENTE</h4>
            <p className='p-cards-comp'>Fieltro de lana de vidrio sin revestimiento, cortado a medida para ser instalado en cubiertas de tejas cerámicas. Aislamiento térmico y acústico, para ser instalado entre clavaderas, sobre el machimbre y por debajo de las tejas cerámicas. El proceso hidrorepelente, le otorga un importante atributo a los aislantes Isover, manteniendo inalterables sus propiedades térmicas, acústicas y de protección al fuego ante cualquier filtración de agua, presencia de condensación o accidentalmente por fisuras.</p>
            <p className='p-cards-comp'>Repele el 99% de agua, absorbe: 0.07 kg/m2 agua.</p>
            <br/>
            <p className='p-cards-comp'><span>Presentación: </span>rollos 50mm de 3mm x 14.52 x 4mm = 18m². Rollos de 80mm de 12 x 1.40m = 14.4m². Rollos de 100mm de 11 x 1.20m = 13.20m².</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FAislacion%2F3v1.png?alt=media&token=6efac9d1-94f6-4709-b46d-77a156857289' loading='lazy'/>
            
            <hr/>
            <h4 style={{color: '#636363', fontSize: '20px'}}>FIELTRO LIVIANO</h4>
            <p className='p-cards-comp'>Fieltro de lana de vidrio Isover sin revestimiento. Aislamiento térmico y acústico, para ser instalado sobre Techos inclinados o cielorrasos suspendidos y entretechos en posición horizontal o inclinado sin carga.</p>
            <p className='p-cards-comp'>La utilización de las lanas de vidrio hacen sustentables las construcciones. Mejora y disminuye sensiblemente los consumos de calefacción y refrigeración dado que se minimizan las pérdidas de energía y las emisiones de CO2. Ahorro de energía 66% en facturas de gas y electricidad. En la fabricación de las lanas de vidrio se utiliza un 70% de vidrio reciclado.</p>
            <br/>
            <p className='p-cards-comp'><span>Presentación: </span>rollos de 38/50mm de 18 x 1.20m = 21.60m². Rollos de 80mm de 12 x 1.40m = 14.4m². Rollos de 100mm de 11 x 1.20m = 13.20m².</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FAislacion%2F3v2.png?alt=media&token=9298e9f3-3c8c-4db9-8c84-c54775dc71f5' loading='lazy'/>
            
            <hr/>
            <h4 style={{color: '#636363', fontSize: '20px'}}>ROLAC PLATA CUBIERTA HIDRORREPELENTE</h4>
            <p className='p-cards-comp'>Aislante de lana de vidrio Isover hidrorepelente revestido en una de sus caras con un complejo de foil de aluminio que actúa como barrera de vapor, para ser instalado en cubiertas metálicas sobre machimbre.</p>
            <br/>
            <p className='p-cards-comp'>Es el único producto que reúne en un solo material aislamiento térmico, absorción acústica, barrera de vapor acorde con las condiciones, soluciones constructivas utilizadas comúnmente y seguro frente al fuego (INCOMBUSTIBLE).</p>
            <p className='p-cards-comp'>El proceso hidrorepelente, le otorga un importante atributo a los aislantes Isover, manteniendo inalterables sus propiedades térmicas, acústicas y de protección al fuego ante cualquier filtración de agua, presencia de condensación o accidentalmente por fisuras</p>
            <p className='p-cards-comp'>Repele el 99% de agua. Absorbe: 0.07 kg/m2 agua.</p>
            <br/>
            <p className='p-cards-comp'><span>Ventajas:</span></p>
            <ul>
                <li>Alto nivel de aislación térmica, acústica.</li>
                <li>Control de la condensación (superficial e intersticial).</li>
                <li>100% Incombustible.</li>
                <li>Solapa longitudinal que asegura la continuidad de la barrera de vapor.</li>
                <li>Liviano.</li>
                <li>Suave al tacto.</li>
                <li>Fácil de cortar</li>
                <li>Flexible</li>
            </ul>
            <br/>
            <p className='p-cards-comp'><span>Presentación: </span>rollos de 50mm de 18 x 1.20m = 21.60m². Rollos de 80mm de 12 x 1.40m = 14.4m².</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FAislacion%2F3v3.png?alt=media&token=fa73b20d-d833-41d4-9bb2-c91454ac502a' loading='lazy'/>
        </>
    )
}


const TabiquesTermica = ()=>{
    return(
        <>
            <h4 className='title-placas'>TÉRMICA Y ACÚSTICA - TABIQUES</h4>
            <p>La utilización de lana de vidrio Isover G3, contribuye a solucionar problemas Acústicos. Según sea su ubicación mejora el aislamiento, es decir disminuye los ruidos provenientes del exterior o del interior, como también acondiciona interiormente los locales de las reflexiones de ruidos molestos. Según el producto y como se lo aplique será su aporte en el aislamiento o en la absorción acústica. La lana de vidrio es un absorbente acústico por excelencia.</p>
            <br/>
            
            <h4 style={{color: '#636363'}}>ACUSTIVER P / P500</h4>
            <p className='p-cards-comp'>Paneles de lana de vidrio Isover sin revestimiento, diseñados para el aislamiento acústico y térmico, en sistemas de construcción en seco y sobre cielorrasos de cualquier tipo. Aislamiento acústico y Tratamiento fonoabsorbente de locales.</p>
            <br/>
            <p className='p-cards-comp'><span>Ventajas:</span></p>
            <ul>
                <li>Cortado para ser instalado entre montantes.</li>
                <li>Alta absoción Acústica.</li>
                <li>No corrosiva.</li>
                <li>Fácil de cortar e instalar.</li>
                <li>Excelente aislante acústico y fonoabsorbente.</li>
                <li>Suguridad frente al fuego.</li>
                <li>Mantiene sus propiedades a través del tiempo.</li>
            </ul>
            <br/>
            <p className='p-cards-comp'><span>Presentación: </span>rollos de 50mm de 1.20 x 0.96m = 1.15m²</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FAislacion%2F4v1.png?alt=media&token=0e846754-f16b-4369-aaaf-0b06b724e587' loading='lazy'/>
            
            <hr/>
            <h4 style={{color: '#636363', fontSize: '20px'}}>ACUSTIVER R</h4>
            <p className='p-cards-comp'>Fieltro de lana de vidrio revestico con velo de vidrio reforzado. Se caracteriza por su alto nivel de aislación Térmica, acústica, y ser totalmente incombustible. Es liviano, suave al tacto, Fácil de cortar y flexible, adptándose a las iregularidades propias de la construcción. Es el único producto que reúne en un solo material aislamiento térmico, absorción acústica acorde a las soluciones constructivas utilizadas comúnmente y seguro frente al fuego.</p>
            <br/>
            <p className='p-cards-comp'><span>Ventajas:</span></p>
            <ul>
                <li>Rapidez de instalación dado que es un producto cortado a los anchos típicos entre montantes.</li>
                <li>Alta absorción acústica dado su gran elasticidad.</li>
                <li>El velo de vidrio le ofrece una mayor resistencia a la tracción longitudinal, mejor terminación y suavidad.</li>
                <li>Excelente aislante acústico y fonoabsorbente.</li>
                <li>Totalmente incombustible.</li>
            </ul>
            <br/>
            <p className='p-cards-comp'><span>Presentación: </span>Rollos de 50mm de 0.40/0.48/0.60 x 18.2m. Rollos de 70mm de 0.40/0.48/0.60 x 13m. Rollos de 100mm de 0.40/0.48/0.60 x 7.5m. Rollos de 150mm de 0.40/0.48/0.60 x 5.2m.</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FAislacion%2F4v2.png?alt=media&token=3a83a601-3435-446f-b4f0-ba721a9b16ff' loading='lazy'/>
        </>
    )
}


const EstructurasTermica = ()=>{
    return(
        <>
            <h4 className='title-placas'>TÉRMICA Y ACÚSTICA - ESTRUCTURAS METÁLICAS</h4>
            <p>Productos especialmente diseñados para la ailacion térmica y acústica de estructuras métalicas.</p>
            <br/>
            
            <h4 style={{color: '#636363'}}>FIELTRO TENSADO ALUMINIO</h4>
            <p className='p-cards-comp'>Aislante especialmente diseñado para el aislamiento de cubiertas y muros en edificios livianos a base de cerramientos metálicos y/o fibrocemento. El Tensado Aluminio se instala entre la estructura metálica y las chapas, no requiriendo ningún trabajo adicional.</p>
            <br/>
            <p className='p-cards-comp'>Es un aislante Liviano de lana de vidrio hidrorepelente Isover G3, revestido en una de sus caras con un complejo de foil de aluminio, hilos de vidrio (que actuan como refuerzo) y papel Kraft que en conjunto, actúan como barrera de vapor, presenta una solapa de 10 cm que permite cubrir las juntas para dar continuidad a la barrera de vapor, evitando la condensación.</p>
            <br/>
            <p className='p-cards-comp'>El Fieltro Tensado Aluminio HR, se caracteriza por su alto nivel de aislación Témica, acústica, control de la condensación y totalmente incombustible. El revestimiento de aluminio posee una solapa longitudinal que asegura la continuidad de la barrera de vapor, agregándole luego una cinta autoadhesiva de similares caracteristicas. Es liviano, suave al tacto, Fácil de cortar y flexible, adptándose a las iregularidades propias de la construcción.</p>
            <br/>
            <p className='p-cards-comp'>Es el único producto que reúne en un solo material, aislamiento térmico, absorción acústica, barrera de vapor acorde con las condiciones, y soluciones constructivas utilizadas comunmente y seguro frente al fuego.</p>
            <br/>
            <p className='p-cards-comp'><span>Ventajas:</span></p>
            <ul>
                <li>Bajo coeficiente de conductividad.</li>
                <li>Coeficiente constante.</li>
                <li>Contribuye al ahorro energético.</li>
                <li>Seguridad frente al fuego.</li>
                <li>Barrera de vapor de alta performance.</li>
                <li>Excelente aislante acústico y fonoabsorbente.</li>
                <li>Fácil de cortar e instalar.</li>
                <li>No es corrosiva.</li>
                <li>Su elasticidad permite rellenar y acomodarse bien a los espacios pequeños.</li>
                <li>Producto sustentable.</li>
                <li>Por su compresibilidad permite reducir costos de transporte y almacenamiento.</li>
            </ul>
            <br/>
            <p className='p-cards-comp'><span>Presentación: </span>rollos de 50mm de 16 x 1.25m = 19.20m². Rollos de 80mm de 12 x 1.20m = 14.4m².</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FAislacion%2F5v1.png?alt=media&token=c42745bd-fda9-44f3-9d73-7d3bef22cf64' loading='lazy'/>
            
            <hr/>
            <h4 style={{color: '#636363', fontSize: '20px'}}>FIELTRO TENSADO POLIPROPILENO BLANCO</h4>
            <p className='p-cards-comp'>Aislamiento térmico y acústico de cubiertas y muros en edificios livianos a base de cerramientos metálicos y/o fibrocemento.</p>
            <br/>
            <p className='p-cards-comp'>El fieltro se instala entre la estructura metálica y las chapas, no requiriendo ningún trabajo adiciona.</p>
            <br/>
            <p className='p-cards-comp'>Aislante a base de lana de vidrio hidrorepelente Isover G3, revestido en una de sus caras con un complejo de Polipropileno Blanco, hilos de vidrio (que actuan como refuerzo) y papel kraft, que en conjunto actuan como barrera de vapor. Posee una solapa de 10 cm para dar continuidad a la barrera de vapor evitando el paso del vapor.</p>
            <br/>
            <p className='p-cards-comp'><span>Ventajas:</span></p>
            <ul>
                <li>Bajo coeficiente de conductividad.</li>
                <li>Coeficiente constante.</li>
                <li>Contribuye al ahorro energético.</li>
                <li>Seguridad frente al fuego.</li>
                <li>Barrera de vapor de alta performance.</li>
                <li>Excelente aislante acústico y fonoabsorbente.</li>
                <li>Fácil de cortar e instalar.</li>
                <li>No es corrosiva.</li>
                <li>Su elasticidad permite rellenar y acomodarse bien a los espacios pequeños.</li>
                <li>Producto sustentable.</li>
                <li>Por su compresibilidad permite reducir costos de transporte y almacenamiento.</li>
            </ul>
            <br/>
            <p className='p-cards-comp'><span>Presentación: </span>rollos de 50mm de 16 x 1.25m = 20m².</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FAislacion%2F5v2.png?alt=media&token=2acfccb1-a41c-45b8-a496-f6b4141da932' loading='lazy'/>
        </>
    )
}