import React, { useContext, useState } from 'react';
import Banner from '../../components/Banner/Index';
import {FaUserAlt, FaPhoneAlt} from 'react-icons/fa';
import {GrMail} from 'react-icons/gr';
import './Optimizador.css';
import FormMaterial from '../../components/FormMaterial/FormMaterial';
import { useFormik} from 'formik';
import * as Yup from 'yup';
import NewMaterial from '../../components/NewMaterial/Index';
import { AppContext } from '../../Context/AppContext';
import { collection, addDoc } from "firebase/firestore";
import db from '../../firebase/firebase';
import { AiOutlineProfile} from 'react-icons/ai';
import {MdOndemandVideo} from 'react-icons/md';
import img from '../../Images/optimizador-info.png';


const Optimizador = () => {
  const {materiales} = useContext(AppContext);
  let funcDate = new Date();
  let date = () =>{
    if(funcDate.getDate() > 9 && funcDate.getMonth()+1 > 9){
      return `${funcDate.getDate()}-${funcDate.getMonth()+1}-${funcDate.getFullYear()}`;
    }else if (funcDate.getDate() < 10 && funcDate.getMonth()+1 > 9){
      return `0${funcDate.getDate()}-${funcDate.getMonth()+1}-${funcDate.getFullYear()}`;
    }else if (funcDate.getDate() < 10 && funcDate.getMonth()+1 < 10){
      return `0${funcDate.getDate()}-0${funcDate.getMonth()+1}-${funcDate.getFullYear()}`;
    }
  }
  const {cleanMateriales} = useContext(AppContext);
  const [orderSended, setOrderSended] = useState(false)
  const [search, setSearch] = useState('');

  const formik = useFormik({
    initialValues: {
        name: '',
        phone: '',
        email:''
    },
    validationSchema: Yup.object({
        name: Yup.string().min(5, 'upps! Nombre muy corto').required('El nombre es obligatorio'), 
        phone: Yup.number().min(6, 'upps! Teléfono incorrecto').required('El teléfono es obligatorio'), 
        email: Yup.string().email('El email no es válido').required('El email es obligatorio'),
    }),
    onSubmit:  async(data) =>{
      if(materiales.length === 0){
        alert('Uups! No tienes cortes agregados para enviar.')
      }else{
        setOrderSended(true);
        let dataEnv = {client: data, cortes: materiales, date: date()};
        const doc = await addDoc(collection(db, 'optimizador'), dataEnv);
        cleanMateriales();
        alert('Orden enviada!');
        window.location.href = `/ordenes/${doc.id}`;
      }
    }
  });

  return (
    <>
      <Banner title='Optimizador'/>
      <div className='optimizador-section-container'>
        <div className='contenedor'>
          <div className='precios-buttons'>
            <a target='_blank' rel='noreferrer' className='tutorial' href='https://drive.google.com/file/d/1bjAlxdVu-ZRzMB7J3DPq3ZYc0DCXNkZb/view?usp=sharing'>
              <MdOndemandVideo/>
              Ir al video tutorial
            </a>
            <div className='img-opt-info'>
                <img src={img} alt='img-opt-info'/>
            </div>
            <a target='_blank' rel='noreferrer' className='drive' href='https://drive.google.com/file/d/1qwoRmD3DiYMlx5wmA8WMgn2SGpwaJenm/view?usp=sharing'>
              <AiOutlineProfile/>
              Ir al Catalogo de Maderas
            </a>
          </div>

          <div className='searcher-order-container'>
            <input onChange={(e)=>{setSearch(e.target.value)}} placeholder='¿Ya tienes una orden? Ingresa ID'/>
            <button onClick={()=>{if(search !== '') window.location.href= `/ordenes/${search}`}} type='button'>Buscar</button>
          </div>


          <h4 id='title15'>Completa los detalles de tu pedido</h4>

            <FormMaterial/>

            {materiales.map(material => <NewMaterial key={material.id} id={material.id} cantoDerecho={material.cantoDerecho} cantoIzquierdo={material.cantoIzquierdo} cantoInferior={material.cantoInferior} cantoSuperior={material.cantoSuperior} material={material.material} color={material.color} espesor={material.espesor} cantidad={material.cantidad} ancho={material.ancho} alto={material.alto}/>)}

            <form onSubmit={formik.handleSubmit}>
              <div className='optimizador-divisor top-25'>
                <hr/>
                <p>Datos del cliente</p>
              </div>  

              <div className='inputs-container'>

                <div className='input-withErrors'>
                  <div className='input-divisor'>
                    <FaUserAlt/>
                    <input onChange={formik.handleChange} name='name' required type='text' placeholder='Nombre y apellido'/>
                  </div>
                  <p>{formik.errors.name}</p>
                </div>

                <div className='input-withErrors'>
                  <div className='input-divisor'>
                    <FaPhoneAlt/>
                    <input onChange={formik.handleChange} name='phone' required type='tel' placeholder='Telefono'/>
                  </div>
                  <p>{formik.errors.phone}</p>
                </div>

                <div className='input-withErrors'> 
                  <div className='input-divisor'>
                    <GrMail/>
                    <input onChange={formik.handleChange} name='email' required type='mail' placeholder='Email'/>
                  </div>
                  <p>{formik.errors.email}</p>
                </div>

              </div>
              <p style={{marginBottom: '10px', paddingLeft: '10px', fontSize: '14px', color: 'rgba(0, 0, 0, 0.8'}} className='error show'>* Al continuar, aceptas las politicas de privacidad.</p>

              {orderSended ? (<div className='container-loader'><div className='loader'></div></div>) : (
                <div className='buttons-optimizador'>
                  <button className='button-send' type='submit'>Enviar orden a producción</button>
              </div>
              )}

            </form>
          
        </div>
      </div>
    </>
  )
}

export default Optimizador;