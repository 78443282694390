import React, { useState } from "react";
import "./Header.css";
import { FaPhoneAlt, FaInstagram, FaFacebookF } from "react-icons/fa";
import { BiMenu } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import logo from "../../Images/logo.png";
import ProductosMenu from "../ProductosMenu/Index";

const Header = () => {
  const [show, setShow] = useState(false);

  const showMenu = () => {
    setShow(!show);
  };

  return (
    <>
      <div className="Header-info">
        <div className="first-phone">
          <i className="Header-icon">
            <FaPhoneAlt />
          </i>
          <span className="Header-Place">Etruria</span>
          <a className="Header-phone" href="tel:3534920770">
            0353 4920770/864/453
          </a>
        </div>

        <div className="second-phone">
          <span> - </span>
          <span className="Header-Place">La Carlota</span>
          <a className="Header-phone" href="tel:3534920770">
            03584 4922568/477
          </a>
        </div>

        <span className="divisor"> - </span>

        <a className="Header-mail" href="mailto:ventas@lumadimaderas.com.ar">
          ventas@lumadimaderas.com.ar
        </a>

        <div className="Header-anchor">
          <a
            href="https://www.instagram.com/lumadi.maderas/"
            className="Header-icon-ig"
          >
            <i className="ig-icon">
              <FaInstagram />
            </i>
          </a>

          <a
            href="https://www.facebook.com/LumadiMaderas/"
            className="Header-icon-fb"
          >
            <i className="fb-icon">
              <FaFacebookF />
            </i>
          </a>

          <a
            className="Header-tienda"
            href="https://lumadimaderas.mercadoshops.com.ar"
            target="_bank"
          >
            Tienda
          </a>
        </div>
      </div>

      <div className="Header-menu-container">
        <div className="Header-menu">
          <div className="logo-container">
            <NavLink to="/">
              <img alt="logo" className={show ? "margin" : null} src={logo} />
            </NavLink>
          </div>

          <div className="menu-container">
            <ul className={show ? "show" : null}>
              <li>
                <NavLink to="/">Inicio</NavLink>
              </li>
              <li>
                <NavLink to="/empresa">Empresa</NavLink>
              </li>
              <li>
                <ProductosMenu />
              </li>
              <li>
                <NavLink to="/novedades/">Novedades</NavLink>
              </li>
              <li>
                <a
                  href="https://lumadimaderas.mercadoshops.com.ar/p/contacto"
                  target="_bank"
                >
                  Contacto
                </a>
              </li>
              <li>
                <NavLink to="/optimizador" className="opt-anchor">
                  <span className="Optimizador-link">Optimizador</span>
                </NavLink>
              </li>
            </ul>
          </div>

          <span className="button-menu">
            <button onClick={showMenu} type="button">
              <BiMenu />
            </button>
          </span>
          <div className="menu-container-mobile">
            <ul className={show ? "show" : null}>
              <li>
                <NavLink onClick={() => setShow(false)} to="/">
                  Inicio
                </NavLink>
              </li>
              <li>
                <NavLink onClick={() => setShow(false)} to="/empresa">
                  Empresa
                </NavLink>
              </li>
              <li>
                <ProductosMenu />
              </li>
              <li>
                <NavLink onClick={() => setShow(false)} to="/novedades/">
                  Novedades
                </NavLink>
              </li>
              <li>
                <a
                  href="https://lumadimaderas.mercadoshops.com.ar/p/contacto"
                  target="_bank"
                >
                  Contacto
                </a>
              </li>
              <li>
                <NavLink
                  onClick={() => setShow(false)}
                  to="/optimizador"
                  className="opt-anchor"
                >
                  <span className="Optimizador-link">Optimizador</span>
                </NavLink>
              </li>
              <li>
                <a
                  className="Header-tienda-mobile"
                  href="https://lumadimaderas.mercadoshops.com.ar"
                  target="_bank"
                >
                  Tienda
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
