import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import NewMaterial from '../../components/NewMaterial/Index';
import db from '../../firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';

const Order = () => {
    const [orden, setOrden] = useState([]);
    const {id} = useParams()

useEffect(()=>{
    const obtenerDatos = async(idNew)=>{
      const docRef = doc(db, 'optimizador', idNew);
      const docSnap = await getDoc(docRef);
      const orderNew =docSnap.data()
      setOrden([orderNew]);
    }
    obtenerDatos(id);
  }, [id])

  return (
    <>
      <div className="Order-container">
        <div className='home-tittles'>
            <h3>Detalles de tu orden</h3>
            <hr/>
        </div>

        <div className='optimizador-image-container'>
            <div className='contenedor-max'>
                {orden.length > 0  && orden[0] !== undefined ? (
                    <>
                    <h4 style={{textAlign: 'center', fontSize: '20px', color: '#4ca000'}}>Tu orden de id: <span style={{color: 'rgba(0, 0, 0, 0.600)'}}>{id}</span> está {orden[0].status ? "en producción, pronto estará lista." : "en la lista de espera para producción, pronto irá a producción y estará lista."}</h4>
                    <hr/>
                    <h4>Datos del usuario:</h4>
                    <div className='tableContainer'>
                        <table className='tableProducts'>
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Cliente</th>
                                    <th>Mail</th>
                                    <th>Teléfono</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{orden[0].date.split('-').join('/')}</td>
                                    <td>{orden[0].client.name}</td>
                                    <td>{orden[0].client.email}</td>
                                    <td style={{textAlign: 'center', justifyContent: 'center'}}>{orden[0].client.phone}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br/>
                    <h4>Cortes pedidos:</h4>
                    {orden[0].cortes.map(uno => <NewMaterial  key={uno.id}
                                                    material={uno.material} 
                                                    color={uno.color} 
                                                    espesor={uno.espesor}
                                                    cantidad={uno.cantidad}
                                                    ancho={uno.ancho}
                                                    alto={uno.alto}
                                                    cantoDerecho={uno.cantoDerecho}
                                                    cantoIzquierdo={uno.cantoIzquierdo}
                                                    cantoInferior={uno.cantoInferior}
                                                    cantoSuperior={uno.cantoSuperior}/>)}
                                                    </>) :  <h4 style={{textAlign: 'center', fontSize: '20px', color: '#4ca000'}}>Tu orden de id: <span style={{color: 'rgba(0, 0, 0, 0.600)'}}>{id}</span> no existe o ya fue finalizada.</h4>}
            
                    
                <br/>
                <h4 style={{color: '#D70418', textAlign: 'center'}}>Para más información, te puedes comunicar con nuestros representantes al siguiente número: +543535089899</h4>
            
            </div>
        </div>


      </div>
    </>
  )
}

export default Order;
