import React from 'react';
import './Empresa.css';
import {BsChevronDoubleDown} from 'react-icons/bs';
import imgBanner from '../../Images/empresa.png';


const Empresa = () => {
  return (
    <div className='Empresa-container'>
        <div className='banner-empresa' style={{'backgroundImage': `url(${imgBanner})`}}>
            <div className='empresa-title'>
                <h3>Nuestra empresa</h3>
                <span className='empresa-divisor'></span>
                <h4>La historia de la familia Lumadi</h4>
            </div>
        </div>
        <div className='arrow'><BsChevronDoubleDown/></div>
        <div className='contenido-empresa'>
            <p>
                En 1993, en la localidad de Etruria, los señores Luis, Mario y Diego Baralle fundaron LUMADI MADERAS S.A.
                <br/>
                Inicialmente se concibió como una empresa destinada a abastecer al sector agropecuario, brindándole todo lo necesario para hacer alambrados, corrales, aguadas, bretes y comederos para hacienda.
                <br/>
                La demanda de madera, comenzó a crecer, como asi también la zona de influencia, de esa manera se incorporan a la venta machimbres, tirantes, revestimientos, molduras, pisos, etc.
                <br/>
                En el año 2002. La empresa pasa a manos de Mario Baralle, que junto a su esposa e hijos, comenzaron a desarrollar la zona sur de la provincia, abriendo una sucursal en la ciudad de La Carlota, esto permitió incrementara un mas los productos incorporando, placas como melaminas, fibro fácil, terciados fenolicos, OSB, herrajes y todo lo relacionado con la construcción en seco, fueron pioneros en el sistema constructivo steel frame, representando marcas de mucho prestigio, como Knauf, Rehau, Black & Decker, Arauco Argentina, entre otras.
                <br/>
                Con las mismas convicciones de nuestros comienzos, dedicados día a día a satisfacer las necesidades de nuestra exquisita y en costante crecimiento, cartera de clientes y con el aporte de nuestro excelente equipo de trabajo, seguimos creciendo y apostando al futuro.
            </p>

            <div className='goals-empresa'>

                <div className='vision'>
                    <h5>Visión</h5>
                    <p>
                    Ser una empresa maderera, que satisfaga las necesidades de nuestros clientes, en todos los rubros, construcción, arquitectura, industria del mueble, brindando soluciones tecnológicas y materiales de vanguardia, que no duden en volver a elegirnos. Que nuestros colaboradores y proveedores, se sientan parte y elijan quedarse.
                    </p>
                </div>

                <div className='mision'>
                    <h5>Misión</h5>
                    <p>
                    Es el servicio, como fuimos concebidos. Brindando soluciones de logística, llegando donde nadie llega. Generar puestos de trabajo y mantener un fuerte compromiso con el medio ambiente.                    </p>
                </div>
                
                <div className='valores'>
                    <h5>Valores</h5>
                    <ul>
                        <li>Mantener la satisfacción de nuestros clientes con productos y servicios de calidad.</li>
                        <li>Desarrollar nuevos productos.</li>
                        <li>Difundir el sistema constructivo steel frame y balood frame.</li>
                        <li>Ampliar las zonas de influencia comercial.</li>
                        <li>Asegurar la fuente de trabajo de nuestros colaboradores y comprometernos con generar más puestos.</li>
                    </ul>
                </div>

            </div>

        </div>

    </div>
  )
}

export default Empresa;