import React from 'react';
import './Footer.css';
import {FaPhoneAlt, FaInstagram, FaFacebookF} from 'react-icons/fa';
import {GrMail} from 'react-icons/gr';
import {IoLocation} from 'react-icons/io5';
import {AiOutlineCopyrightCircle} from 'react-icons/ai';
import logo from '../../Images/logo.png';
import {NavLink} from 'react-router-dom';

const Footer = () => {

  const date = new Date().getFullYear();

  return (
    <div className='footer-container'>
        <div className='contactus'>
            <h5>Contactanos</h5>
            <ul>
              <li>
                <FaPhoneAlt/>
                <span className='Header-Place'>Etruria</span>
                <a className='Footer-phone' href='tel:3534920770'>0353 4920770/864/453</a>
              </li>
              <li>
                <FaPhoneAlt/>
                <span className='Header-Place'>La Carlota</span>
                <a className='Footer-phone' href='tel:3584422568'>03584 422568/477</a>
              </li>
              <li>
                <GrMail/>
                <a className='Footer-mail' href='mailto:ventas@lumadimaderas.com.ar'>ventas@lumadimaderas.com.ar</a>
              </li>
              <li>
                <a style={{textDecoration: 'none', color: '#000'}} target='_blank' rel='noreferrer' href='https://goo.gl/maps/XTXTLQqxxj3EH4zi9'>
                  <IoLocation/>
                  <span className='address'>Av. Agustin Quaranta 1180, </span>
                  <span className='Header-Place'>Etruria</span>
                </a>
              </li>
              <li>
                <a style={{textDecoration: 'none', color: '#000'}} target='_blank' rel='noreferrer' href='https://goo.gl/maps/hJW4dhW9HwJfWeoY7'>
                  <IoLocation/>
                  <span className='address'>San Martin 970, </span>
                  <span className='Header-Place'>La Carlota</span>
                </a>
              </li>
            </ul>
        </div>

        <div className='copy-container'>
          <div className='copyrigth'>
            <AiOutlineCopyrightCircle/>
            <img src={logo} loading='lazy' alt='logo'/>
            <span>{date}</span>
          </div>

          <NavLink to='/politicas-de-privacidad'><h5>Politicas de cookies y privacidad</h5></NavLink>

          <div className='Footer-redes'>
            <a  className='footer-ig' href='https://www.instagram.com/lumadi.maderas/'><FaInstagram/></a>
            <a href='https://www.facebook.com/LumadiMaderas/'><FaFacebookF/></a>
          </div>
        </div>
    </div>
  )
};

export default Footer;