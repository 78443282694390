import React, {useState} from 'react';
import {FaCloudUploadAlt} from 'react-icons/fa';
import {useFormik} from 'formik';
import db, { uploadFile } from '../../firebase/firebase';
import { doc, setDoc, collection, addDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const FormNovedad = ({id, novedad}) => {
    let navigate = useNavigate();

    const [img, setImg] = useState('');
    const [secondImg, setSecondImg] = useState('');
    const dateNow = new Date();
    const date = `${dateNow.getDate()}/${dateNow.getMonth()+1}/${dateNow.getFullYear()}`;

    const clipImage = async(e)=>{
        const filed = await uploadFile(e.target.files[0]);
        setImg(filed);
        alert("Se ha guardado la imagen principal");
    }

    const clipSecondImage = async(e)=>{
        const filed = await uploadFile(e.target.files[0]);
        setSecondImg(filed);
        alert("Se ha guardado la segunda imagen");
    }

    const revenue = async(data) =>{
        let dataArray = Object.entries(data);
        let filtered = dataArray.filter(([key, value])=> value !== '');
        let newEdit = Object.fromEntries(filtered);
        const NewRef = doc(db, 'novedades', id);
        window.scrollTo({
            top:0,
            behavior: 'smooth'
        });
        if(img !== ''){
            if(secondImg !== ''){
                await setDoc(NewRef, {...newEdit,  img: img, secondImg: secondImg}, { merge: true });
                alert('Se ha actualizado la novedad');
            }else{
                await setDoc(NewRef, {...newEdit,  img: img}, { merge: true });
                alert('Se ha actualizado la novedad');
            }
        }else{
            await setDoc(NewRef, {newEdit}, { merge: true });
            alert('Se ha actualizado la novedad');
        }
    }

    const addNew = async(data) =>{
        if(img !== ''){
            await addDoc(collection(db, 'novedades'), {...data, date: date, img: img, secondImg: secondImg});
            alert('Se ha creado la novedad, la podrás ver en la página "administracion"');
            navigate('/administracion');
        }else{
            await addDoc(collection(db, 'novedades'), {...data, date: date});
            alert('Se ha creado la novedad, la podrás ver en la página "administracion"');
            navigate('/administracion');
        }
    }

    const formik = useFormik({
        initialValues:{
            title: novedad.title || '',
            shortDescription: novedad.shortDescription || '',
            text: novedad.text || '',
            secondText: novedad.secondText || ''
        },
        onSubmit: async (obj) =>{
            if(id !== 'false'){revenue(obj)}
            else{addNew(obj)}
        }
    })

  return (
    <>
        <form className='formulary' onSubmit={formik.handleSubmit}>

            <input  name='title' 
                    onChange={formik.handleChange} 
                    className='input-title' 
                    required
                    type='text' 
                    placeholder='Titulo' 
                    defaultValue={novedad.title}/>

            <textarea   name='shortDescription' 
                        onChange={formik.handleChange} 
                        className='input-description' 
                        placeholder='Mini-description' 
                        defaultValue={novedad.shortDescription}/>

            <label>
                <div style={{backgroundImage: `url(${img || novedad.img})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    <FaCloudUploadAlt/>
                </div>
                <input  name='img' 
                        onChange={clipImage}
                        className='input-img' 
                        accept='image/*'
                        type='file' 
                        placeholder='Imagen'/>
            </label>

            <textarea   name='text' 
                        required
                        onChange={formik.handleChange} 
                        className='input-text' 
                        placeholder='Texto' 
                        defaultValue={novedad.text}/>

            <label>
                <div style={{backgroundImage: `url(${secondImg || novedad.secondImg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    <FaCloudUploadAlt/>
                </div>
                <input  name='secondImg' 
                        onChange={clipSecondImage}
                        className='input-sdImg' 
                        accept='image/*'
                        type='file' 
                        placeholder='Segunda imagen'/>
            </label>

            <textarea   name='secondText' 
                        onChange={formik.handleChange} 
                        className='input-sdText' 
                        placeholder='Segundo texto' 
                        defaultValue={novedad.secondText}/>

            {id !== 'false' ?(
                <div className='formulary-button'><button type='submit'>Guardar cambios de novedad</button></div>
            ) : (
                <div className='formulary-button'><button type='submit'>Crear nueva novedad</button></div>
            )
            }

            </form>
    </>
  )
}

export default FormNovedad;