import React, {useEffect, useState} from 'react';
import './NovedadCreate.css';
import Banner from '../../components/Banner/Index';
import MenuAdministracion from '../../components/MenuAdministracion/Index';
import { useParams } from 'react-router-dom';
import db from '../../firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';
import FormNovedad from '../../components/FormNovedad/Index';

const NovedadCreate = () => {
    const [novedad, setNovedad] = useState([]);
    const {id} = useParams();

  useEffect(()=>{
    if(id !== 'false'){
      const obtenerDatos = async(idNew)=>{
        const docRef = doc(db, 'novedades', idNew);
        const docSnap = await getDoc(docRef);
        setNovedad(docSnap.data());
      }
      obtenerDatos(id);
    }
  }, [id])

  return (
    <>
        <Banner title='Novedades'/>
        <MenuAdministracion/>

        <FormNovedad id={id} novedad={novedad}/>

    </>
  )
}

export default NovedadCreate;