import React from 'react';
import Banner from '../../../components/Banner/Index';
import './Herramientas.css';

const Herramientas = () => {
  return (
    <>
        <Banner title='Herramientas'/>
        <div className='products-container'>
            <div className='submenu-products'>
                <div className='div-submenu'> 

                </div>
            </div>
            <div className='content-products-container'>
                <img className='img-default' alt='const-en-seco' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FHerramientas%2Fprincipal%20.jpg?alt=media&token=d867b957-f1dc-4e86-8a3d-9f8ce38f33be' loading='lazy'/>
                <a className='visit-store' href='https://tienda.lumadimaderas.com.ar'>VISITÁ NUESTRA TIENDA ONLINE VER TODAS LAS HERRAMIENTAS</a>
                <p>
                  Contamos con una amplia gama en herramientas de las mejores marcas: DEWALT, STANLEY, BLACK AND DECKER, KREG, IRWIN, LENOX. Que responden a un excepcional rendimiento, innovación y fiabilidad en los productos. Cumplen con los más altos niveles / estándares de durabilidad y protección para el usuario.
                </p>
                <p>
                  Las herramientas DEWALT están diseñadas y garantizadas para exceder las expectativas de los profesionales de la construcción, incluso en las circunstancias más extremas. Responden a un excepcional rendimiento, innovación y fiabilidad de sus productos. Ofrecen soluciones para aplicaciones completas en un entorno de construcción moderna, que incluye herramientas eléctricas con y sin cable, hojas de sierra y extensa gama de accesorios, acoplamientos para la extracción de polvo, anclajes y fijaciones, almacenamiento para la obra, láseres e instrumentos de medición, generadores, compresores y más..
                </p>
                <p><span className='marca-descrip'>BLACK+DECKER</span> es el productor de herramientas eléctricas y accesorios más grande del mundo. Fabrica productos de calidad tanto para el hogar como para profesionales.</p>
                <p><span className='marca-descrip'>STANLEY</span> es un fabricante global de herramientas manuales, mecánicas y accesorios. Se ha comprometido a proporcionar productos inteligentes, resistentes, pioneros y adecuados a cada tarea, a los expertos de todo el mundo.</p>
                <p><span className='marca-descrip'>KREG</span> son herramientas diseñadas y pensadas para los carpinteros. Para facilitar su uso a todo tipo de personas. Con un diseño sencillo y de alta precisión permite hacer creación en maderas con firmeza, dureza y durabilidad.</p>
                <p><span className='marca-descrip'>IRWIN</span> fabrica y distribuye accesorios y herramientas manuales para técnicos de todo el mundo que buscan calidad, durabilidad y rendimiento superior. Crea herramientas orientadas a los profesionales de la construcción civil.</p>
                <p>En <span className='marca-descrip'>LENOX</span> se busca comprender y satisfacer las necesidades de los principales consumidores: los expertos de la construcción. Piensan de nuevo las herramientas familiares. Para que hagan más rápido y fácil hacer bien el trabajo</p>
            </div>
        </div>
    </>
  )
}

export default Herramientas;