import React, { useContext } from "react";
import { AuthContext } from "../../Auth/useAuth";
import { NavLink } from "react-router-dom";
import ItemService from "../../components/ItemService";
import MarcasSlider from "../../components/MarcaSlider/Index";
import Slider from "../../components/Slider";
import imgOptimizador from "../../Images/Optimizador.png";
import imgTienda from "../../Images/tienda.png";
import "./Home.css";
import imgService1 from "../../Images/services/construccion-en-seco.jpg";
import imgService2 from "../../Images/services/yeso.jpg";
import imgService3 from "../../Images/services/placas.jpg";
import imgService4 from "../../Images/services/techos.png";
import imgService5 from "../../Images/services/rurales.png";
import imgService6 from "../../Images/services/tromen.jpg";
import imgService7 from "../../Images/services/perfiles.jpg";
import imgService8 from "../../Images/services/herramientas.jpg";
import imgService9 from "../../Images/services/mucho-mas.png";
import PreServicios from "../../components/PreServicios/Index";

const Home = () => {
  const { login, logout } = useContext(AuthContext);
  let dateLogin = localStorage.getItem("date");
  if (dateLogin !== null && Date.now() - parseInt(dateLogin) < 172800000) {
    login();
    localStorage.setItem("date", Date.now());
  } else {
    logout();
    localStorage.removeItem("date");
  }

  return (
    <>
      <Slider />

      <div className="experience-container">
        <div className="home-tittles">
          <h3>La experiencia nos respalda</h3>
          <hr />
        </div>
        <div className="flex-wrap-row">
          <div className="experience-text div-50">
            <h4>Casi 30 años de experiencia...</h4>
            <p>
              Somos una empresa familiar que surge para abastecer al carpintero
              y al hombre de campo. Con el tiempo fuimos creciendo e
              incorporamos un gran cantidad de rubros. Como Steel frames, pisos,
              melaminas, herrajes, herramientas, techos, entre otros
              <br />
              Gracias al compromiso y esmero en cumplir, ganamos la confianza de
              nuestros clientes y proveedores, logrando la representación de
              marcas lideres.
              <br />
              Haciendo que nuestro mostrador no tenga límites geográficos, pero
              siempre manteniendo la calidez, solides y confianza de nuestros
              comienzos, haciendo que tu experiencia de compra sea satisfactoria
              y 100% recomendable.
            </p>
          </div>

          <div className="video-yt div-50">
            <div className="border-video">
              <iframe
                src="https://www.youtube.com/embed/wLBLMfo5FhM"
                title="LUMADI Maderas S.A. - Video Comercial"
                autoPlay
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <MarcasSlider />

      <div className="optimizador-container">
        <div className="home-tittles">
          <h3>Optimizamos los trabajos</h3>
          <hr />
        </div>

        <div className="optimizador-image-container">
          <div className="div-50">
            <div className="div-center">
              <img
                className="optimizador-image"
                loading="lazy"
                src={imgOptimizador}
                alt="optimizadorImage"
              />
            </div>
          </div>
          <div className="experience-text div-50">
            <h4>Tenemos un optimizador online</h4>
            <p>
              Podes enviarnos tus cortes a través de nuestro optimizador online,
              rellenando el formulario con unos pocos clicks, hacemos los
              recortes, pegamos los cantos y nos comunicamos cuando estén listos
              para coordinar la entrega. Hacemos tus compras y trabajos mucho
              más fácil.
            </p>
            <NavLink to="/optimizador">
              <span className="go-to-opt">Probar optimizador</span>
            </NavLink>
          </div>
        </div>
      </div>

      <div className="tienda-container">
        <div className="home-tittles">
          <h3>Comprá desde tu casa</h3>
          <hr />
        </div>
        <div className="tienda-image-container">
          <div className="experience-text div-50">
            <h4>Podes usar nuestro e-commerce</h4>
            <p>
              Tenemos una tienda online con los precios más competitivos.
              <br />A través de una web muy accesible en la que con un par de
              clicks podés comprar las herramientas y materiales que te gusten y
              necesites, teniendo las mejores opciones de envíos y métodos de
              pago para hacer tus compras de la manera más cómoda posible.
            </p>
            <a href="https://tienda.lumadimaderas.com.ar">
              <span className="go-to-shop">Ir a la tienda online</span>
            </a>
          </div>
          <div className="div-50 image-tienda">
            <div className="div-center">
              <img loading="lazy" src={imgTienda} alt="tiendaImage" />
            </div>
          </div>
        </div>
      </div>

      <PreServicios />

      <div className="service-container">
        <div className="home-tittles">
          <h3>Lo que hacemos por vos</h3>
          <hr />
        </div>
        <div className="Items-service-container">
          <ItemService
            img={imgService1}
            text="Somos proveedores del sistema que está marcando un cambio en los tiempos y costos de obra"
            title="Construcción en seco"
          />
          <ItemService
            img={imgService2}
            text="Somos proveedores del sistema que está marcando un cambio en los tiempos y costos de obra"
            title="Yeso Proyectado"
          />
          <ItemService
            img={imgService3}
            text="FAPLAC confía en nosotros para que distribuyamos exclusivamente toda su linea de los mejores aglomerados y tableros de MDF"
            title="Placas"
          />
          <ItemService
            img={imgService4}
            text="Brindamos todos los materiales para que construyas tus techos de madera, teniendo todos los beneficios que estos ofrecen"
            title="Techos"
          />
          <ItemService
            img={imgService5}
            text="Acompañams a los productores ganaderos y agropecuarios satisfaciendo sus necesidades con un amplio stock, postes, varillas, tanques, bretes, comederos, etc."
            title="Articulos rurales"
          />
          <ItemService
            img={imgService6}
            text="Distribuimos exclusivamente toda la linea TROMEN, que con su sistema de doble combustión, optimiza la calefacción"
            title="Calefacción, hornos y parrillas"
          />
          <ItemService
            img={imgService7}
            text="Amplia variedad de colores y medidas."
            title="Chapas y perfiles"
          />
          <ItemService
            img={imgService8}
            text="Distribuidores de DeWALT, STANLEY, BLACK AND DECKER, IRWIN, LENOX y KREG, MONTANA, PONY, VIRUTEX"
            title="Herramientas"
          />
          <ItemService
            img={imgService9}
            text="Ofrecemos pegado de cantos, cortes personalizados, variedad increíble de maderas con stock permanente, etc."
            title="Muchas cosas más"
          />
        </div>
      </div>
    </>
  );
};

export default Home;
