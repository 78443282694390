import React, { useState } from 'react';
import Banner from '../../../components/Banner/Index';
import './Pisos.css';

const Pisos = () => {
    const [comp, setComp] = useState('');
    

    const switchComponent = (selec)=>{
        switch(selec){
            case '': return <Default/>;
            case 'Pisos flotantes melaminicos': return <FlotantesMelaminicos/>;
            case 'Pisos flotantes de PVC': return <FlotantesPVC/>;
            case 'Pisos vinilicos': return <Vinilicos/>;
            case 'Pisos de madera macizos': return <Macizos/>;
            case 'Pisos de madera prefinished': return <Prefinished/>;
            case 'Decks de madera': return <DecksMadera/>;
            case 'Decks de PVC': return <DecksPVC/>;
            case 'Escaleras': return <Escaleras/>;
            default: return <Default/>
        }
    }


  return (
    <>
        <Banner title='Pisos y decks'/>
        <div className='products-container'>
            <div className='submenu-products'>
                <div className='div-submenu'>
                    <h5 onClick={()=> setComp('')}>Pisos y decks</h5>
                    <div className='buttons-submenu'>
                        <button onClick={()=> setComp('Pisos flotantes melaminicos')} type='button'>Pisos flotantes melamínicos</button>
                        <button onClick={()=> setComp('Pisos flotantes de PVC')} type='button'>Pisos flotantes de PVC</button>
                        <button onClick={()=> setComp('Pisos vinilicos')} type='button'>Pisos vinílicos</button>
                        <button onClick={()=> setComp('Pisos de madera macizos')} type='button'>Pisos de madera macizos</button>
                        <button onClick={()=> setComp('Pisos de madera prefinished')} type='button'>Pisos de madera prefinished</button>
                        <button onClick={()=> setComp('Decks de madera')} type='button'>Decks de madera</button>
                        <button onClick={()=> setComp('Decks de PVC')} type='button'>Decks de PVC</button>
                        <button onClick={()=> setComp('Escaleras')} type='button'>Escaleras</button>
                    </div>
                </div>
            </div>
            <div className='content-products-container'>
                {switchComponent(comp)}
            </div>
        </div>
    </>
  )
}

export default Pisos;


const Default = ()=>{
    return (
        <>
            <img className='img-default' alt='const-en-seco' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2Fprincipal.jpg?alt=media&token=0d81aa35-a6e9-4398-9be1-3cdbde3d47aa' loading='lazy'/>
            <p>
            Los pisos de madera proporcionan un entorno acogedor y cálido al hogar. Hay pisos de madera sólida, flotantes y laminados.            
            </p>
            <p>
                El piso de madera sólida consiste en piezas de madera que se ensamblan y, en caso de rayarse, se pueden pulir y barnizar, más de una vez, sin perder funcionalidad y estética.
                <br/>
                Los pisos flotantes son paneles hechos de fibras de madera prensada. Tiene una película laminada, que imita el dibujo y el aspecto de la madera.
            </p>
            <p>
                De fácil mantenimiento y gran resistencia, el deck es un piso por lo general de madera para exterior, que se caracteriza por acentuar la calidez y elegancia en espacios como solariums, pasillos o patios, balcones y terrazas. La aparición de estas plataformas comienza con la revalorización de los espacios abiertos. Al tener este material poca absorción del calor, es apto para caminar sin protección de calzado en zonas expuestas al sol.
            </p>
            <p>
                Según las características del espacio, un deck puede ser montado de diversas maneras: sobre tirantería, alfajías, elevados, fijos o móviles, y su estructura puede apoyarse sobre cualquier tipo de suelos.
            </p>
        </>
    )
}



const FlotantesMelaminicos = ()=>{
    return(
        <>
            <h4 className='title-placas'>PISOS FLOTANTES MELAMÍNICOS</h4>
            <p>Suelos melamínicos laminados de instalación flotante, realizados sobre HDF de 8 mm, especialmente diseñados y producidos para el uso domiciliario intensivo o comercial moderado. De instalación con anclaje rápido libre de adhesivos o fijaciones. Líneas modernas y clásicas para cubrir todas las tendencias de diseño y ambientación.</p>
            <p>Esta nueva generación de maderas (o cubiertas imitación madera), se instala libremente sobre una espuma autonivelante y una capa de polietileno.</p>
            <br/>
            <p>Sin importar el sistema de unión utilizado entre los paneles, este piso "flotante" no se pega ni clava al subsuelo como se hace en pisos tradicionales. Si se clava o pega, “no funciona”. Es importante dejar no menos de 10 mm. de espacio en todo el perímetro para permitir que el material se expanda libremente. Este es un detalle fundamental para el éxito de la instalación, pues si llega a tocar la pared, aunque sea en sólo un punto, el piso se levantará inevitablemente.</p>
            <br/>
            <p>Este piso se instala con las uniones de tablas a mitad de la siguiente. En otras palabras, se va trabando de modo que se vean líneas continuas interrumpidas tabla por media. Otra opción es comenzar la segunda línea con el sobrante de la primera (siempre que no sea menor de 30cm), esto beneficia en cuanto a lograr un mayor aprovechamiento del material.</p>
            <br/>
            <ul>
                <li>Los pisos laminados son sumamente fáciles de limpiar.</li>
                <li>NO ENCERE NI PULA el piso.</li>
                <li>Para la limpieza diaria, use una aspiradora o un trapeador húmedo.</li>
                <li>Antes de usar agua o los limpiadores mencionados anteriormente, exprima muy bien el trapeador o la esponja. Una cantidad excesiva de humedad no es necesaria.</li>
                <li>Igual que con cualquier material de superficie dura, el piso laminado posiblemente sea resbaloso cuando está mojado. Si derrama un líquido sobre el piso, límpielo rápidamente usando una esponja o un paño suave.</li>
                <li>NO LIMPIE ESTE PISO CON LIMPIADORES ABRASIVOS, ESPONJAS ABRASIVAS, LANA DE ACERO NI POLVOS DESGRASADORES.</li>
                <li>Para no rayar, utilice protectores para piso Armstrong o almohadillas protectoras debajo de las patas de sillas y de muebles.</li>
                <li>Utilice esteras o tapetes en las entradas para recoger la arenilla acarreada dentro de la casa y para absorber el exceso de humedad.</li>
                <li>Debido a que las ruedas de metal pueden dañar el piso, no las recomendamos. Si necesita usar ruedas, por favor use ruedas suaves que tengan el grosor adecuado para la carga.</li>
            </ul>
            <br/>
            <p className='p-cards-comp'><span>Presentación: </span>Cajas x 10 tablas = 2,453m2 = 18kg = 1.90m x 2.28m.</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2F1v1.png?alt=media&token=6f8a731a-abe8-44cf-a7bf-edacce781737' loading='lazy'/>

            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2F1v2.png?alt=media&token=ca4b972e-dd72-4f28-9847-d379fa2a0e87' loading='lazy'/>

            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2F1v3.png?alt=media&token=e00ad864-045d-4051-b0d4-c030fe816114' loading='lazy'/>
            <p>Tambien contamos con terminaciones metalicas para escaleras, desniveles, remates o para absorber dilataciones. Todas en color aluminio.</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2F1v4.png?alt=media&token=8ddcb29d-6bb3-4cbd-9b3f-0ea55574d4e9' loading='lazy'/>
        </>
    )
}


const FlotantesPVC = ()=>{
    return(
        <>
            <h4 className='title-placas'>PISOS FLOTANTES DE PVC</h4>
            <p>El piso vinílico en listones es uno de los pisos más innovadores y versátiles. Es resistente al agua e ideal para zonas de alto tránsito y de fácil de limpieza.</p>
            <br/>
            <p>Poseé gran durabilidad y suavidad al andar, mantiene una temperatura agradable en todas las estaciones.</p>
            <p>Piso compuesto por una base de PVC con refuerzo de fibra de vidrio sobre la que se aplica una lámina decorativa y un overlay de resistencia.</p>
            <br/>
            <p className='p-cards-comp'><span>Forma de colocación:</span> flotante INICLIC, sin pegamentos.</p>
            <p className='p-cards-comp'><span>Producto no repulible</span></p>
            <p className='p-cards-comp'><span>Tránsito:</span> Alto tránsito residencial, alto tránsito comercial.</p>
            <p className='p-cards-comp'><span>100% resistente al agua.</span></p>
            <p className='p-cards-comp'>Se puede colocar sobre cualquier piso existente</p>
            <p className='p-cards-comp'><span>Imitación:</span> piedra, cerámico, madera</p>

            <hr/>
            <p className='p-cards-comp'><span>Espesor:</span> 4mm.</p>
            <p className='p-cards-comp'><span>Medidas:</span> 903x150mm</p>
            <p className='p-cards-comp'><span>Capa de uso:</span> 0.3mm</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2F2v1.png?alt=media&token=24082270-e10a-46ac-9a79-f7690b5e039b' loading='lazy'/>

            <hr/>
            <p className='p-cards-comp'><span>Espesor:</span> 2mm.</p>
            <p className='p-cards-comp'><span>Medidas:</span> 914.4x152mm</p>
            <p className='p-cards-comp'><span>Capa de uso:</span> 0.2mm</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2F2v2.png?alt=media&token=a6256454-92ba-4b71-a78a-30f03af8e0e4' loading='lazy'/>

            <hr/>
            <p className='p-cards-comp'><span>Espesor:</span> 3mm.</p>
            <p className='p-cards-comp'><span>Medidas:</span> 914.4x152mm</p>
            <p className='p-cards-comp'><span>Capa de uso:</span> 0.3mm</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2F2v3.png?alt=media&token=a073ba06-f5b0-49c9-9813-2df175535a3f' loading='lazy'/>

            <hr/>
            <p className='p-cards-comp'><span>Espesor:</span> 5mm.</p>
            <p className='p-cards-comp'><span>Medidas:</span> 1218x178mm</p>
            <p className='p-cards-comp'><span>Capa de uso:</span> 0.55mm</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2F2v4.png?alt=media&token=9f770a64-ce3c-49d5-9a04-59fb15d43b1f' loading='lazy'/>
        </>
    )
}


const Vinilicos = ()=>{
    return(
        <>
            <h4 className='title-placas'>PISOS VINÍLICOS</h4>
            <p>Es un revestimiento silencioso, anti alergénico y aislante térmico. De fácil mantenimiento, permite conservar los ambientes limpios a bajo costo. Está compuesto por 4 capas de PVC, dándole de esta forma una excelente estabilidad dimensional, confort, durabilidad y estética para ajustarse a sus necesidades.</p>
            <br/>
            <p>Se presenta en rollos de 2 metros de ancho y 1,2 mm de espesor.</p>
            <br/>
            <h4 style={{color: '#636363'}}>Ventajas:</h4>
            <ul>
                <li>Superficie antideslizante.</li>
                <li>Tecnología y diseños alemanes.</li>
                <li>Versatilidad de usos.</li>
                <li>Antes de usar agua o los limpiadores mencionados anteriormente, exprima muy bien el trapeador o la esponja. Una cantidad excesiva de humedad no es necesaria.</li>
                <li>Fácil instalación.</li>
                <li>Fácil limpieza.</li>
            </ul>
            <br/>
            <h4 style={{color: '#636363'}}>Aplicaciones:</h4>
            <ul>
                <li>Carrocerías.</li>
                <li>Casas rodantes.</li>
                <li>Construcciones modulares.</li>
                <li>Salas de juego.</li>
                <li>Comederos.</li>
                <li>Cocinas.</li>
                <li>Dormitorios</li>
            </ul>

            <p className='p-cards-comp'><span>Presentación del producto:</span> rollos de 1.3mm de espesor.</p>
            <p className='p-cards-comp'><span>Medidas del rollo:</span> 2mm x 40mm.</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2F3v1.png?alt=media&token=145cd832-6361-4675-8670-77b4520ce87e' loading='lazy'/>
        </>
    )
}


const Macizos = ()=>{
    return(
        <>
            <h4 className='title-placas'>PISOS DE MADERA MACIZOS</h4>
            <p>Pisos de madera maciza sin barnizar.</p>
            <br/>
            <p className='p-cards-comp'><span>Instalación: </span>Clavado y pegado con cola vinílica sobre fajas de terciado fenólico ligadas a la carpeta. Luego de colocados se debe esperar entre 4 y 6 meses para su pulido y laqueado.</p>
            <br/>
            <div className='cards-placas-container space'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2F4v1.jpg?alt=media&token=7d085a4e-8020-478a-ab03-c6feb26b4c8f' loading='lazy'/>
                        </div>
                    </div>
                    <h5>EUCA PREMIUM</h5>
                    <p className='p-cards-comp'><span>Medidas:</span> 18x100x300/600/900mm.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2F4v2.jpg?alt=media&token=123f3ad0-661c-408f-b654-2a1df91eed65' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ROSTRATA</h5>
                    <p className='p-cards-comp'><span>Medidas:</span> 20x80x400/600/800/1000mm. 14x70x400/600/800/1000mm.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2F4v3.jpg?alt=media&token=a7845698-3451-4973-851a-e1e85cf4e6ad' loading='lazy'/>
                        </div>
                    </div>
                    <h5>RABO VAPORIZADO</h5>
                    <p className='p-cards-comp'><span>Medidas:</span> 20x80x400/600/800/1000mm. 14x70x400/600/800/1000mm,</p>
                </div>
            </div>
        </>
    )
}


const Prefinished = ()=>{
    return(
        <>
            <h4 className='title-placas'>PISOS DE MADERA PREFINISHED</h4>
            <p>La madera recibe 9 capas de barniz, con secado de rayos ultravioletas (U.V). secante, garantizando secado más rápido y uniforme, finalmente el piso es recubierto por una capa de óxido de aluminio que aumenta su resistencia a la abrasión y la protege de arañazos, roces y desgastes.</p>
            <p>Los Pisos Prefinished son pisos que se barnizan en fábrica y que no necesitan pulimento o acabado posterior.</p>
            <p>Son fáciles de colocar, ya que durante la instalación usted puede permanecer en la obra y utilizarlos inmediatamente después.</p>
            <p>Los Pisos Prefinished al ser de madera maciza, después de años de uso, pueden ser nuevamente lijados y barnizados, restaurando su belleza original.</p>
            <br/>
            <div className='cards-placas-container space'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2F5v1.png?alt=media&token=fa78f7c5-d499-4487-9b39-2a7525935be6' loading='lazy'/>
                        </div>
                    </div>
                    <h5>EUCA PREFINISHED</h5>
                    <p className='p-cards-comp'><span>Medidas:</span> 18x100x300/600/900mm.</p>
                </div>
            </div>
        </>
    )
}


const DecksMadera = ()=>{
    return(
        <>
            <h4 className='title-placas'>DECKS DE MADERA</h4>
            <p>Solución natural para espacios exteriores, aportando calidez, practicidad y confort. Ideal para uso en galerías y exteriores.</p>
            <p>Brinda una larga vida útil a bajo mantenimiento. Excelente terminación que permite una colocación rápida y eficiente. Muy buena aceptación de lustres y teñidos que permiten mantener fácilmente el producto.</p>
            <br/>
            <div className='cards-placas-container space'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2F6v1.jpg?alt=media&token=66680cba-db8d-4b97-9589-cf2dc0d45e10' loading='lazy'/>
                        </div>
                    </div>
                    <h5>EUCA PREMIUN</h5>
                    <p className='p-cards-comp'><span>Medidas:</span> 1" x 3" x 1.50/1.80/2.10/2.70/3.05/3.65mts. 1" x 5" x 1.50/1.80/2.10/2.70/3.05/3.65mts.</p>
                </div>
            </div>
        </>
    )
}


const DecksPVC = ()=>{
    return(
        <>
            <h4 className='title-placas'>DECKS DE PVC</h4>
            <p>Están fabricados con un 90% de materiales reciclados no tóxicos (madera recuperada, aserrín y plástico). Tienen larga durabilidad. No se pudren ni se astillan. Bajo mantenimiento. Resistente al sol, al calor y a la humedad. No se necesitan pulir ni barnizar, sólo limpieza con agua y jabón.</p>
            <br/>
            <p className='p-cards-comp'><span>Colores:</span> Lapacho y piedra.</p>
            <p className='p-cards-comp'><span>Medidas:</span> 240mm x 150mm x 25mm.</p>
            <p className='p-cards-comp'><span>Superficie:</span> Texturado</p>
            <br/>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2F7v1.png?alt=media&token=299d1f85-494a-48cc-ab8a-5b8582d3b7d1' loading='lazy'/>
        </>
    )
}


const Escaleras = ()=>{
    return(
        <>
            <h4 className='title-placas'>ESCALERAS</h4>
            <br/>
            <div className='cards-placas-container space'>
            <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2F8v1.png?alt=media&token=326c1306-cc3c-4fdb-bd36-d7200107c09b' loading='lazy'/>
                        </div>
                    </div>
                </div>
                <div className='card-placas-45'>
                    <p className='p-cards-comp'>Contamos también con herrajes para escaleras.</p>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FPisos%2F8v2.jpg?alt=media&token=53b09591-b2ae-41f0-86cd-2e43ea47d3cd' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}