import React, { useState, useEffect } from 'react';
import Banner from '../../../components/Banner/Index';
import './Precios.css';
import {SiGooglesheets, SiGoogledrive} from 'react-icons/si';
import {BiSearchAlt} from 'react-icons/bi';
import {AiOutlineDoubleLeft, AiOutlineDoubleRight} from 'react-icons/ai';
import { getLinks, getProducts } from '../../../API/products';
import {GrDocumentPdf} from 'react-icons/gr';
import {v4} from 'uuid';


const Precios = () => {
    const [page, setPage] = useState(1);
    const [products, setProducts] = useState([]);
    const [data, setData] = useState([]);
    const [showPag, setShowPag] = useState(true)
    const [links, setLinks] = useState({pdf: '', excel: ''});

    const search = (e)=>{
        let str = (e.target.value).toLowerCase();
        setData((products.filter(elem => elem.description.toLowerCase().includes(str))).splice(0, 100));
        if(e.target.value !== ''){
            setShowPag(false)
        }else setShowPag(true)
    }

    const plusPage =()=>{
        if(products.length/100 > page){
            setPage(page+1);
            setData(products.slice(page*100, ((page+1)*100)-1))
            window.scrollTo({
                top:0,
                behavior: 'smooth'
            });
        }
    }

    const lessPage =()=>{
        if(page > 1){
            setPage(page-1);
            setData(products.slice((page-2)*100, ((page-1)*100)-1))
            window.scrollTo({
                top:0,
                behavior: 'smooth'
            });
        }
    }
    

    useEffect(()=>{ 
        getProducts('https://docs.google.com/spreadsheets/d/e/2PACX-1vQsXVseOehT1JXH8LPlxbHtpNGkYBoD19WXJHEfWJGZQcCYXVzETMU7NKQVfNmfIjemTnSKFwz1Q1ZN/pub?gid=0&single=true&output=csv').then(resp =>{
            setProducts(resp);
            setData(resp.slice(0,99))
        })
        getLinks('https://docs.google.com/spreadsheets/d/e/2PACX-1vSswuqVFqLj79vPwyLS2vBq3xv89REEJPbLrI7Et0JhA9IY0novKjan_DmA8LHDItjMvniCZWN6yJqF/pub?output=csv')
            .then(resp =>{setLinks(resp);})
    }, [])


  return (
    <>
        <Banner title='Lista de precios'/>
        <div className='Precios-container'>
            <div className='precios-contenedor-delimited'>
                <div className='precios-buttons'>
                    <a target='_blank' rel='noreferrer' className='sheets' href={links.excel && links.excel !== '' ? links.excel : "https://drive.google.com/drive/folders/1xJxD1b1HEouu1YGrxTdYKWWBAkdXT4eB"}>
                            <SiGooglesheets/>
                            Ir a Excel de precios
                    </a>
                    <a target='_blank' rel='noreferrer' className='pdf' href={links.pdf && links.pdf !== '' ? links.pdf : "https://drive.google.com/drive/folders/1xJxD1b1HEouu1YGrxTdYKWWBAkdXT4eB"}>
                            <GrDocumentPdf/>
                            Ir a PDF de precios
                    </a>
                    <a target='_blank' rel='noreferrer' className='drive' href='https://drive.google.com/drive/folders/1xJxD1b1HEouu1YGrxTdYKWWBAkdXT4eB'>
                            <SiGoogledrive/>
                            Ir a Drive de catalogos
                    </a>
                </div>
                <div className='search'>
                        <BiSearchAlt/>
                        <input onChange={search} placeholder='Buscar producto' type='text'/>
                </div>

                <div className='comment'>
                    <a href='https://lumadimaderas.mercadoshops.com.ar/p/contacto'><p>*Consulta por tus bonificaciones</p></a>
                </div>

                <div className='tableContainer'>
                    <table className='tableProducts'>
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Producto</th>
                                <th>Unid. medida</th>
                                <th>Precio con IVA</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.length > 0 ? (
                                data.map(product => <tr key={v4()}><td>{product.code}</td><td>{product.description}</td><td>{product.Unidad}</td><td>{product.price}</td></tr>)
                            ) : (<tr><td></td><td>No hay productos aún, espera un momento...</td><td></td></tr>)}

                        </tbody>
                    </table>
                </div>

                <div className={`paginator-container ${showPag ? 'show' : null}`}>
                    <div className='paginator'>
                        <button onClick={lessPage} type='button'><AiOutlineDoubleLeft/></button>
                        <p>{page} de ...  {Math.trunc(products.length/100)}</p>
                        <button onClick={plusPage} type='button'><AiOutlineDoubleRight/></button>
                    </div>
                </div>

            </div>
        </div>
    </>
  )
}

export default Precios;