import React, { useState } from 'react';
import './WppBurble.css';
import {BsWhatsapp} from 'react-icons/bs';
import logo512 from '../../Images/logo512.png'

const WppBurble = () => {
  const [text, setText] = useState('');
  const [ModalIsOpen, setModalIsOpen] = useState(false);
  const [chatIsOpen, setChatIsOpen] = useState(false);

  const openModal = ()=>{
    if (chatIsOpen){
      setChatIsOpen(false);
      setModalIsOpen(false);
    }else{
      setModalIsOpen(!ModalIsOpen);
    }
  }

  const showChat = () =>{
    setChatIsOpen(true);
    setModalIsOpen(false);
  }

  const setNewText = (e) =>{
    setText(e.target.value);
  }


  return (
    <>
        <div className={ `wpp-container ${ModalIsOpen ? 'show' : null}`}>
          <div className='wpp-header'>
            <div className='wpp-header-divisor'>
              <BsWhatsapp/>
              <p>¡Estamos aquí para ayudarte! No dudes en consultar. Haga click a continuación para iniciar el chat.</p>
            </div>
          </div>
          <button onClick={showChat} type='button' className='wpp-assist-container'>
            <img alt='profile' src={logo512}></img>
            <div>
              <h6>Lumadi Maderas</h6>
              <p>Seré tu asistente en lo que necesites</p>
            </div>
          </button>
        </div>
        <div className={`wpp-container-input ${chatIsOpen ? 'show' : null}`}>
          <div className='wpp-input-header'>
            <img alt='profile' src={logo512}></img>
            <div>
              <h6>Lumadi Maderas</h6>
              <p>Seré tu asistente en lo que necesites</p>
            </div>
          </div>
          <div className='messages-wpp-container'>
            <div>
                <p className='user-name'>Lumadi Maderas</p>
                <p className='wpp-saludo'>Hola 👋</p>
                <p className='wpp-pregunta'>¿Cómo puedo ayudarte?</p>
            </div>
          </div>
          <div className='message-input'>
            <textarea onChange={setNewText} type='text' placeholder='Escriba su mensaje'></textarea>
            <a rel='noreferrer' target='_blank' href={`https://wa.me/+5493535089899?text=${text}`}>Enviar</a>
          </div>
        </div>
        <button onClick={openModal} className='wpp-bot-button' type='button'><BsWhatsapp/></button>
    </>
  )
}

export default WppBurble;