import React, { createContext,  useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({children}) =>{
    const [materiales, setMateriales] = useState([]);
    const [deleNov, setDeleNov] = useState ('');
    const [changeDo, setChangeDo] = useState ('');


    const deleteMaterial = (id) =>{
        setMateriales(materiales.filter(e => e.id !== id))
    }

    const cleanMateriales = ()=>{
        setMateriales([]);
    }

    const addMaterial = (newMaterial)=>{
        let id = `${newMaterial.ancho}` + Math.floor(Math.random() * 999999);
        let materialAdded = {...newMaterial, cantoDerecho: newMaterial.cantoDerecho[0] || '', cantoSuperior: newMaterial.cantoSuperior[0] || '', cantoInferior: newMaterial.cantoInferior[0] || '', cantoIzquierdo: newMaterial.cantoIzquierdo[0] || ''}
        setMateriales([...materiales, {...materialAdded, id, status: 'false'}]);
    }

    

    return (
        <AppContext.Provider value={{changeDo, setChangeDo ,setDeleNov, deleNov, materiales, deleteMaterial, cleanMateriales, addMaterial}}>
            {children}
        </AppContext.Provider>
    )
};