import React, {useState, useEffect, useContext} from 'react';
import { AppContext } from '../../Context/AppContext';
import Banner from '../../components/Banner/Index';
import './Docs.css';
import MenuAdministracion from '../../components/MenuAdministracion/Index';
import ItemTable from '../../components/ItemTable/Index';
import db from '../../firebase/firebase';
import { collection, getDocs } from 'firebase/firestore';


const Docs = () => {
  const [documentos, setDocumentos] = useState([]);

  const {changeDo} = useContext(AppContext);

  useEffect(()=>{
    const obtenerDatos = async()=>{
        const docs = [];
        const datos = await getDocs(collection(db, 'optimizador'));
        datos.forEach(doc =>{docs.push({...doc.data(), id: doc.id})});
        const docsChanged = docs.map(docu => ({...docu, date: docu.date.split('-').reverse().join('-')}));
        setDocumentos(docsChanged.sort((a, b)=>{
            if(a.date < b.date){return 1;}
            if(a.date > b.date){return -1;}
            else{return 0;}}));
    }
    obtenerDatos();
  }, [changeDo]);

  let docsPending = documentos.filter(doc => doc.status === 'false' || doc.status === undefined);
  let docsDones = documentos.filter(doc => doc.status === 'true');


  return (
    <>
        <Banner title='Documentos Optimizador'/>
        <MenuAdministracion/>

        <div className='docs-container'> 
            <div className='contenedor-max'>
            <p style={{marginBottom: '10px', paddingLeft: '10px', fontSize: '14px', color: 'rgba(0, 0, 0, 0.8'}} className='error show'>* Al cambiar el estado, esperar un segundo que se actualice en la base de datos.</p>
                <h4>Pendientes</h4>
                <div className='tableContainer'>
                    <table className='tableProducts'>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Usuario</th>
                                <th>Mail</th>
                                <th>Teléfono</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {docsPending.map(doc => <ItemTable key={doc.id} documento={doc} check={false} />)}
                        </tbody>
                    </table>
                </div>
                <h4 id='prod-title'>En producción</h4>
                <div className='tableContainer'>
                    <table className='tableProducts'>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Usuario</th>
                                <th>Mail</th>
                                <th>Teléfono</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {docsDones.map(doc => <ItemTable  key={doc.id} documento={doc} check={true} />)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>
  )
}

export default Docs;