import React, { useState } from 'react';
import Banner from '../../../components/Banner/Index';
import './Steel.css';
import knauf from './../../../Images/marcas/6.svg';
import {BsCheckLg} from 'react-icons/bs';

const Steel = () => {
    const [comp, setComp] = useState('');

    const switchComponent = (selec)=>{
        switch(selec){
            case '': return <Default/>;
            case 'placas de yeso': return <Placasyeso/>;
            case 'placas de cemento': return <Placascemento/>;
            case 'placas especiales': return <Placasespeciales/>;
            case 'perfiles': return <Perfiles/>;
            case 'perfiles de Steel Framing': return <PerfilesSteel/>;
            case 'masillas': return <Masillas/>;
            case 'selladores': return <Selladores/>;
            case 'cintas y bandas': return <Cintas/>;
            case 'accesorios cielorraso suspendido': return <Accesorios/>;
            case 'tapa de inspeccion': return <Tapas/>;
            case 'herramientas': return <Herramientas/>;
            case 'placa de fibrocemento': return <PlacasFibrocemento/>;
            case 'siding': return <Siding/>;
            case 'cielorraso': return <Cielorraso/>;
            default: return <Default/>;
        }
    }

  return (
    <>
        <Banner title='Steel Framing'/>
        <div className='products-container'>
            <div className='submenu-products'>
                <div className='div-submenu'>
                    <h5 onClick={()=> setComp('')}>Steel Framing</h5>
                    <div className='buttons-submenu'>
                        <button onClick={()=> {setComp('placas de yeso'); console.log('Yeso')}} type='button'>Placas de yeso - Knauf</button>
                        <button onClick={()=> {setComp('placas de cemento'); console.log('Cemento')}} type='button'>Placas de cemento - Knauf</button>
                        <button onClick={()=> setComp('placas especiales')} type='button'>Placas especiales - Knauf</button>
                        <button onClick={()=> setComp('perfiles')} type='button'>Perfiles - Knauf</button>
                        <button onClick={()=> setComp('perfiles de Steel Framing')} type='button'>Perfiles de Steel Framing</button>
                        <button onClick={()=> setComp('masillas')} type='button'>Masillas - Knauf</button>
                        <button onClick={()=> setComp('selladores')} type='button'>Selladores - Knauf</button>
                        <button onClick={()=> setComp('cintas y bandas')} type='button'>Cintas y bandas - Knauf</button>
                        <button onClick={()=> setComp('accesorios cielorraso suspendido')} type='button'>Accesorios cielorraso suspendido - Knauf</button>
                        <button onClick={()=> setComp('tapa de inspeccion')} type='button'>Tapa de inspección - Knauf</button>
                        <button onClick={()=> setComp('herramientas')} type='button'>Herramientas - Knauf</button>
                        <button onClick={()=> setComp('placa de fibrocemento')} type='button'>Placa de fibrocemento superboard ST - Eternit</button>
                        <button onClick={()=> setComp('siding')} type='button'>Siding - Eternit</button>
                        <button onClick={()=> setComp('cielorraso')} type='button'>Cielorraso</button>
                    </div>
                </div>
            </div>
            <div className='content-products-container'>
                {switchComponent(comp)}
            </div>
        </div>
    </>
  )
}

export default Steel;

const Default = ()=>{
    return (
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://knauf.com.ar'><img src={knauf} alt='knauf' loading='lazy'/></a></h3>
            <hr className='divisor-hr'/>
            <img className='img-default' alt='const-en-seco' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2Fprincipal.jpg?alt=media&token=b64fb364-583d-41ec-88ac-f5fe7e382749' loading='lazy'/>
            <p>
                Este sistema está marcando un cambio profundo en los tiempos y costos de obra. Abre ilimitadas posibilidades que potencian los proyectos arquitectónicos. La CONSTRUCCION EN SECO permite alcanzar la mejor aislación acústica y térmica de las obras. Contamos con la más completa variedad de productos, asesoramiento y capacitación necesaria para su correcta implementación.
            </p>
            <p>
                Con la construcción en seco, la principal ventaja es que se reducen los costos, por la rapidez del sistema. Además, los productos empleados en el armado de los muros, a distintos espesores que van desde 30 a 200 milímetros permiten lograr una excelente aislación térmica y acústica, de mejor rendimiento que la mampostería tradicional.
            </p>
            <p>
                Este sistema resulta ideal para iniciar cualquier tipo de remodelación en la vivienda: no genera obra húmeda, es rápida, limpia y admite múltiples diseños. Más aún, es una alternativa que hace posible la casa propia
            </p>
        </>
    )
}

const Placascemento = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://knauf.com.ar'><img src={knauf} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>PLACAS DE CEMENTO - KNAUF</h4>
            <div className='cards-placas-container space'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F2v1.jpg?alt=media&token=dc9f3dd7-ea12-47fd-9aed-00b8b4d8b0be' loading='lazy'/>
                        </div>
                    </div>
                    <h5>AQUAPANEL UNIVERSAL</h5>
                    <p className='p-cards'><h6>Ancho:</h6> 1.200mm</p>
                    <p className='p-cards'><h6>Largo:</h6> 2.400mm</p>
                    <p className='p-cards'><h6>Espesor:</h6> 8mm</p>
                    <p className='p-cards-comp'><span>Tipos de bordes:</span> Los bordes son redondeados (Easy Edge), para permitir un tratamiento de juntas más fácil.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F2v1.jpg?alt=media&token=dc9f3dd7-ea12-47fd-9aed-00b8b4d8b0be' loading='lazy'/>
                        </div>
                    </div>
                    <h5>AQUAPANEL OUTDOOR</h5>
                    <p className='p-cards'><h6>Ancho:</h6> 1.200mm</p>
                    <p className='p-cards'><h6>Largo:</h6> 2.400mm</p>
                    <p className='p-cards'><h6>Espesor:</h6> 12,5mm</p>
                    <p className='p-cards-comp'><span>Tipos de bordes:</span> Los bordes son redondeados (Easy Edge), para permitir un tratamiento de juntas más fácil.</p>
                </div>
            </div>
        </>
    )
}

const Placasyeso = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://knauf.com.ar'><img src={knauf} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>PLACAS DE YESO - KNAUF</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' loading='lazy' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F1v1.jpg?alt=media&token=0b5dc2ff-9de9-409b-8bd1-31d48459fbfd'/>
                        </div>
                    </div>
                    <h5>PYE</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Espesor</th>
                                <th>Largo</th>
                                <th>Ancho</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>9,5mm</td>
                                <td><p>2.400mm</p><p>3.000mm</p></td>
                                <td>1.200mm</td>
                            </tr>
                            <tr>
                                <td>12,5mm</td>
                                <td><p>2.000mm</p><p>2.400mm</p><p>2.600mm</p><p>3.000mm</p></td>
                                <td>1.200mm</td>
                            </tr>
                            <tr>
                                <td>15mm</td>
                                <td><p>2.400mm</p><p>2.600mm</p><p>3.000mm</p></td>
                                <td>1.200mm</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F1v2.jpg?alt=media&token=33402681-84b6-4e04-b463-0a8c49c203b6' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PYRH</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Espesor</th>
                                <th>Largo</th>
                                <th>Ancho</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>12,5mm</td>
                                <td><p>2.400mm</p><p>2.600mm</p><p>3.000mm</p></td>
                                <td>1.200mm</td>
                            </tr>
                            <tr>
                                <td>15mm</td>
                                <td><p>2.400mm</p><p>2.600mm</p><p>3.000mm</p></td>
                                <td>1.200mm</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F1v3.jpg?alt=media&token=0767288d-00eb-4c87-bd0e-227abd46082e' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PYRF</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Espesor</th>
                                <th>Largo</th>
                                <th>Ancho</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>12,5mm</td>
                                <td><p>2.400mm</p><p>2.600mm</p><p>3.000mm</p></td>
                                <td>1.200mm</td>
                            </tr>
                            <tr>
                                <td>15mm</td>
                                <td><p>2.400mm</p><p>2.600mm</p><p>3.000mm</p></td>
                                <td>1.200mm</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

const Placasespeciales = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://knauf.com.ar'><img src={knauf} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>PLACAS ESPECIALES - KNAUF</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F3v1.jpg?alt=media&token=9782e42e-f501-497a-ad78-404f39fb8b7a' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PLACA DE YESO DIAMANT</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Espesor</th>
                                <th>Largo</th>
                                <th>Ancho</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>12,5mm</td>
                                <td><p>2.400mm</p></td>
                                <td>1.200mm</td>
                            </tr>
                            <tr>
                                <td>15mm</td>
                                <td><p>2.400mm</p></td>
                                <td>1.200mm</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className='p-cards'>2 Transversales Cuadrados (CUA)</p>
                    <p className='p-cards'>2 Longitudinales Rebajados (REB)</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F3v3.jpg?alt=media&token=863d71b5-96d0-4195-9743-c4560bd33889' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PLACA DE YESO IMPACT</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Espesor</th>
                                <th>Largo</th>
                                <th>Ancho</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>12,5mm</td>
                                <td><p>2.400mm</p></td>
                                <td>1.200mm</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className='p-cards'>2 Transversales Cuadrados (CUA)</p>
                    <p className='p-cards'>2 Longitudinales Rebajados (REB)</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F3v4.jpg?alt=media&token=833eb70f-9ad6-4878-a4ec-cf9c171617eb' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CLEANEO AKUSTIK SOUND 8/18R</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Espesor</th>
                                <th>Largo</th>
                                <th>Ancho</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>12,5mm</td>
                                <td><p>2.000mm</p></td>
                                <td>1.188mm</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className='p-cards-comp'><span>Tipos de bordes:</span> 2FF / 2SK</p>
                </div>
                {/*******************************
                 * ******************************
                 *******************************/}
                 <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F3v5.jpg?alt=media&token=b58d2d19-c76a-4072-9ffd-32d5fa0a7513' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CLEANEO AKUSTIK QUADRAT 8/18Q</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Espesor</th>
                                <th>Largo</th>
                                <th>Ancho</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>12,5mm</td>
                                <td><p>2.000mm</p></td>
                                <td>1.188mm</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className='p-cards'><span>Tipos de bordes:</span>2FF / 2SK</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F3v6.jpg?alt=media&token=53cf4f45-7aa1-4abe-b4e1-6fbc2b58318e' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CLEANEO AKUSTIK ALEATORIA PLUS 8/15/20R</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Espesor</th>
                                <th>Largo</th>
                                <th>Ancho</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>12,5mm</td>
                                <td><p>1.875mm</p></td>
                                <td>1.200mm</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className='p-cards'><span>Tipos de bordes:</span>2FF / 2SK</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F3v7.jpg?alt=media&token=82b46db1-01f5-467f-a621-da0f615c971b' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CLEANEO AKUSTIK SLOTLINE B4</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Espesor</th>
                                <th>Largo</th>
                                <th>Ancho</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>12,5mm</td>
                                <td><p>2.400mm</p></td>
                                <td>1.200mm</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className='p-cards-comp'><span>Tipos de bordes:</span>  2 Longitudinales Cuarta caña (CCA). 2 Transversales Cuadrado (CUA).</p>
                </div>
                {/*******************
                 * ******************
                 *******************/}
                 <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F3v8.jpg?alt=media&token=e4f24a70-d27b-491a-81a3-cc36fe6cc6ac' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PLACA DE FIBRA DE MADERA HERADESIGN FINE</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Formato</th>
                                <th>Espesor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>600mm/1.200mm x 600mm</td>
                                <td><p>15mm</p></td>
                            </tr>
                            <tr>
                                <td>600mm/1.200mm x 600mm</td>
                                <td><p>25mm</p></td>
                            </tr>
                            <tr>
                                <td>600mm/1.200mm x 600mm</td>
                                <td><p>35mm</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F3v9.jpg?alt=media&token=76a1898f-9d20-4816-83e9-3edec314648c' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PLACA DE FIBRA DE MADERA HERADESIGN SUPERFINE</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Formato</th>
                                <th>Espesor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>600mm/1.200mm x 600mm</td>
                                <td><p>15mm</p></td>
                            </tr>
                            <tr>
                                <td>600mm/1.200mm x 600mm</td>
                                <td><p>25mm</p></td>
                            </tr>
                            <tr>
                                <td>600mm/1.200mm x 600mm</td>
                                <td><p>35mm</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F4v1.jpg?alt=media&token=550a23da-37f9-46fe-b606-2e84a7f96d04' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PLACA DE YESO SAFEBOARD</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Espesor</th>
                                <th>Largo</th>
                                <th>Ancho</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>12,5mm</td>
                                <td><p>2.500mm</p></td>
                                <td>625mm</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className='p-cards-comp'><span>Tipos de bordes:</span> Longitudinal: Cuarto Caña (CCA). Transversal: Recto.</p>
                </div>
            </div>
        </>
    )
}


const Perfiles = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://knauf.com.ar'><img src={knauf} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>PERFILES - KNAUF</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F4v1.jpg?alt=media&token=550a23da-37f9-46fe-b606-2e84a7f96d04' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PERFIL U25/20</h5>
                    
                    <p className='p-cards-comp'><span>Largo:</span> 2.600mm / 3.000mm</p>
                    <p className='p-cards-comp'><span>A:</span> 25mm</p>
                    <p className='p-cards-comp'><span>B:</span> 20mm</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F4v2.jpg?alt=media&token=2a0a9d40-02bc-4c2d-b4e0-6b3010875565' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PERFIL OMEGA</h5>
                    
                    <p className='p-cards-comp'><span>Largo:</span> 2.600mm</p>
                    <p className='p-cards-comp'><span>A:</span> 35mm</p>
                    <p className='p-cards-comp'><span>B:</span> 13mm</p>
                    <p className='p-cards-comp'><span>C:</span> 10mm</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F4v3.jpg?alt=media&token=ce30dd05-dbab-4cf4-80d5-f01725a4446d' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SOLERA 35</h5>
                    
                    <p className='p-cards-comp'><span>Largo:</span> 2.600mm / 3.000mm</p>
                    <p className='p-cards-comp'><span>A:</span> 35mm</p>
                    <p className='p-cards-comp'><span>B:</span> 28mm</p>
                </div>
                {/*******************************
                 * ******************************
                 *******************************/}
                 <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F4v4.jpg?alt=media&token=f2ace160-4058-47ee-a9b8-c43aa540441c' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SOLERA 70</h5>
                    
                    <p className='p-cards-comp'><span>Largo:</span> 2.600mm / 3.000mm</p>
                    <p className='p-cards-comp'><span>A:</span> 70mm</p>
                    <p className='p-cards-comp'><span>B:</span> 28mm</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F4v5.jpg?alt=media&token=865d3cf8-ef79-493e-a0df-7022801a8480' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MAESTRA F-47</h5>
                    
                    <p className='p-cards-comp'><span>Largo:</span> 2.600mm / 4.000mm</p>
                    <p className='p-cards-comp'><span>A:</span> 47mm</p>
                    <p className='p-cards-comp'><span>B:</span> 17mm</p>
                    <p className='p-cards-comp'><span>C:</span> 5mm</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F4v6.jpg?alt=media&token=68a04e58-5354-40b2-a265-f2d7cdc927f7' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MONTANTE 35</h5>

                    <p className='p-cards-comp'><span>Largo:</span> 2.600mm / 3.000mm</p>
                    <p className='p-cards-comp'><span>A:</span> 34mm</p>
                    <p className='p-cards-comp'><span>B:</span> 35mm/30mm</p>
                    <p className='p-cards-comp'><span>C:</span> 5mm</p>
                </div>
                {/*******************
                 * ******************
                 *******************/}
                 <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F4v7.jpg?alt=media&token=c09979bc-17b5-4ddd-b89b-8f600edc7f65' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MONTANTE 70</h5>

                    <p className='p-cards-comp'><span>Largo:</span> 2.600mm / 3.000mm</p>
                    <p className='p-cards-comp'><span>A:</span> 69mm</p>
                    <p className='p-cards-comp'><span>B:</span> 35mm/30mm</p>
                    <p className='p-cards-comp'><span>C:</span> 5mm</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F4v8.jpg?alt=media&token=eb20a1aa-c4c7-4e09-b704-978a02fd48cf' loading='lazy'/>
                        </div>
                    </div>
                    <h5>BUÑA Z PERIMETRAL</h5>

                    <p className='p-cards-comp'><span>Largo:</span> 2.600mm / 3.000mm</p>
                    <p className='p-cards-comp'><span>A:</span> 34mm</p>
                    <p className='p-cards-comp'><span>B:</span> 35mm/30mm</p>
                    <p className='p-cards-comp'><span>C:</span> 5mm</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F4v9.jpg?alt=media&token=ab163998-68f5-48c5-b4ef-312cc9b11c47' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ÁNGULO DE AJUSTE</h5>
                    
                    <p className='p-cards-comp'><span>Largo:</span> 2.600mm</p>
                    <p className='p-cards-comp'><span>A:</span> 24mm</p>
                    <p className='p-cards-comp'><span>B:</span> 10mm</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F4v10.jpg?alt=media&token=418af200-6490-490d-b481-69ce0e1f45a3' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CANTONERA 32/32</h5>
                    
                    <p className='p-cards-comp'><span>Largo:</span> 2.600mm</p>
                    <p className='p-cards-comp'><span>A:</span> 31mm</p>
                    <p className='p-cards-comp'><span>B:</span> 31mm</p>
                </div>
            </div>
        </>
    )
}


const PerfilesSteel = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://knauf.com.ar'><img src={knauf} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>PERFILES STEEL FRAMING - KNAUF</h4>
            <p>Steel Framing es un sistema que se utiliza para la construcción de estructuras metálicas livianas y tiene diferentes aplicaciones, por ejemplo: viviendas, entrepisos, etc. Este proceso se caracteriza por ser un montaje en seco.</p>
            <br/>
            <p className='p-cards-comp'><span>Proyecto:</span> No existen restricciones</p>
            <p className='p-cards-comp'><span>Estructura:</span> conjunto de perfiles separados entre sí cada 40 o 60 cm.</p>
            <p className='p-cards-comp'><span>Eficiencia Energética:</span> elevado nivel de aislación térmica.</p>
            <p className='p-cards-comp'><span>Terminaciones Exteriores:</span> admite placas cementicias, revoques elastoplásticos, entablonados, terminaciones tradicionales (ladrillo, piedra).</p>
            <p className='p-cards-comp'><span>Montaje:</span> sobre fundaciones tradicionales.</p>
            <p className='p-cards-comp'><span>Instalaciones:</span> se distribuyen por el interior de la estructura, a través de las perforaciones previstas en la perfilería.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F5v1.png?alt=media&token=b52ccfad-d156-4bf2-a793-17aa5e784e01' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PERFIL C</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>A cm</th>
                                <th>B cm</th>
                                <th>C cm</th>
                                <th>0,9mm</th>
                                <th>1,25mm</th>
                                <th>1,6mm</th>
                                <th>2,0mm</th>
                                <th>2,5mm</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>PGC 70</td>
                                <td>70</td>
                                <td>35</td>
                                <td>15</td>
                                <td>{<BsCheckLg/>}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>PGC 90</td>
                                <td>90</td>
                                <td>35</td>
                                <td>15</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>PGC 100</td>
                                <td>100</td>
                                <td>35</td>
                                <td>15</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>PGC 140</td>
                                <td>140</td>
                                <td>35</td>
                                <td>15</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>PGC 150</td>
                                <td>150</td>
                                <td>35</td>
                                <td>15</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>PGC 200</td>
                                <td>200</td>
                                <td>35</td>
                                <td>15</td>
                                <td></td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>PGC 250</td>
                                <td>250</td>
                                <td>35</td>
                                <td>15</td>
                                <td></td>
                                <td></td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F5v2.png?alt=media&token=ff76c5fd-f3d2-400f-a638-d0ff73207b54' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PERFIL U</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>A cm</th>
                                <th>B cm</th>
                                <th>0,9mm</th>
                                <th>1,25mm</th>
                                <th>1,6mm</th>
                                <th>2,0mm</th>
                                <th>2,5mm</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>PGU 70</td>
                                <td>70</td>
                                <td>35</td>
                                <td>{<BsCheckLg/>}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>PGU 90</td>
                                <td>90</td>
                                <td>35</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>PGU 100</td>
                                <td>100</td>
                                <td>35</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>PGU 140</td>
                                <td>140</td>
                                <td>35</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>PGU 150</td>
                                <td>150</td>
                                <td>35</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>PGU 200</td>
                                <td>200</td>
                                <td>35</td>
                                <td></td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>PGU 250</td>
                                <td>250</td>
                                <td>35</td>
                                <td></td>
                                <td></td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                                <td>{<BsCheckLg/>}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}



const Masillas = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://knauf.com.ar'><img src={knauf} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>MASILLAS - KNAUF</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F6v1.jpg?alt=media&token=4e66112f-0aae-46c8-b954-e18719ce7fb3' loading='lazy'/>
                        </div>
                    </div>
                    <h5>READYMAS - JUNTAS Y TERMINACIÓN</h5>
                    
                    <p className='p-cards-comp'>Masilla a base de polímeros y cargas minerales de secado aéreo para tomado de juntas y terminaciones de placas de yeso.</p>
                    <p className='p-cards-comp'><span>Presentación:</span> Baldes de 1,7 kg - 7 kg - 17 kg - 32 kg.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F6v2.jpg?alt=media&token=0c159835-e591-48d7-bc6a-6426e7651d78' loading='lazy'/>
                        </div>
                    </div>
                    <h5>READYMAS - TOMADO DE JUNTAS</h5>
                    
                    <p className='p-cards-comp'>Masilla a base de polímeros y cargas minerales de secado aéreo para tomado de juntas de placas de yeso.</p>
                    <p className='p-cards-comp'><span>Presentación</span> Baldes de 27 kg - 32 kg.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F6v3.jpg?alt=media&token=8c9c4a36-89c8-4f2d-958e-7a2db7da2abe' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MULTIFINISH</h5>

                    <p className='p-cards-comp'>El enduído interior Multifinish es un material a base de polímeros y cargas minerales de color blanco.</p>
                    <p className='p-cards-comp'><span>Presentación:</span> Balde de 16 kg.</p>
                </div>
                {/*******************************
                 * ******************************
                 *******************************/}
                 <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F6v4.jpg?alt=media&token=c19f7f13-31be-490c-93d7-66992e93503a'/>
                        </div>
                    </div>
                    <h5>READYMAS - JUNTAS Y TERMINACIÓN EN POLVO</h5>

                    <p className='p-cards-comp'>Masilla en polvo de secado aéreo a base de polímeros y cargas minerales, para el tomado de juntas de placas de yeso y terminaciones.</p>
                    <p className='p-cards-comp'><span>Presentación:</span> Bolsa de 20 kg (32kg de pasta con su dosificación de agua correspondiente).</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F6v5.jpg?alt=media&token=33de8af0-2746-4117-9992-da9a291e0ebe' loading='lazy'/>
                        </div>
                    </div>
                    <h5>MASILLA SAFEBOARD</h5>
                    
                    <p className='p-cards-comp'>Esta masilla resulta indicada para el tomado de juntas de placas antirradiación. Es un compuesto especial en base a yeso, sintético-templado y en polvo, adaptado con material mineral de relleno y aditivos para su fácil aplicación. La masilla Safeboard brinda además protección contra la radiación y ha sido coloreada amarilla para su identificación. Resulta fácil de mezclar, no produce grumos, tiene consistencia flexible y presenta buen agarre.</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F6v6.jpg?alt=media&token=592688f9-3cac-4e11-adfb-70b1c9b771b8' loading='lazy'/>
                        </div>
                    </div>
                    <h5>FUGENFÜLLER</h5>

                    <p className='p-cards-comp'>Es una masilla en polvo de fraguado rápido, pensada para el tratamiento de juntas de papel.</p>
                    <p className='p-cards-comp'><span>Presentación:</span> Bolsa de 10 kg (17,5 kg de pasta con su dosificación de agua correspondiente).</p>
                </div>
                {/*******************
                 * ******************
                 *******************/}
                 <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F6v7.jpg?alt=media&token=d2c28de7-2f29-4f3c-a56b-f1a2c1f1261e' loading='lazy'/>
                        </div>
                    </div>
                    <h5>UNIFLOTT</h5>

                    <p className='p-cards-comp'>Es una masilla en polvo de fraguado rápido, pensada para el tratamiento de juntas con o sin papel y juntas de placa Cleaneo Akustik. Permite realizar reparaciones.</p>
                    <p className='p-cards-comp'><span>Presentación:</span> Bolsa de 5 kg (7,5 kg de pasta con su dosificación de agua correspondiente)</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F6v8.jpg?alt=media&token=4f96ec6d-a1fd-44f8-8aa5-ca69ed959b94' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PERLFIX</h5>

                    <p className='p-cards-comp'>Knauf Perlfix es un adhesivo en polvo, de fraguado rápido, creado para pegar placas de yeso sobre muros limpios y sin humedad.</p>
                    <p className='p-cards-comp'><span>Presentación:</span> Bolsa 30 kg</p>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F6v9.jpg?alt=media&token=e91dfad3-6e35-43d1-a15f-2d5ee0f7b66a' loading='lazy'/>
                        </div>
                    </div>
                    <h5>JOINTFILLER</h5>
                    
                    <p className='p-cards-comp'>Masilla en polvo a base yeso, de secado rápido, para tratamiento de juntas y reparaciones.</p>
                    <p className='p-cards-comp'><span>Presentación:</span> Bolsa de 10kg (16kg aprox. de pasta con su dosificación de agua correspondiente). Bolsa de 20kg (32kg aprox. de pasta con su dosificación de agua correspondiente)</p>
                </div>
            </div>
        </>
    )
}




const Selladores = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://knauf.com.ar'><img src={knauf} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>SELLADORES - KNAUF</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F7v1.jpg?alt=media&token=0f795d95-f41c-4328-b299-3dc46610da35'/>
                        </div>
                    </div>
                    <h5>FUGENDICHT</h5>
                    
                    <p className='p-cards-comp'>El Sellador Ignífugo-Acústico Fugendicht Knauf es un material acrílico de base acuosa y alta concentración de sólidos no inflamables.</p>
                    <p className='p-cards-comp'><span>Presentación:</span> Balde de 5 kg.</p>
                </div>
            </div>
        </>
    )    
}


const Cintas = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://knauf.com.ar'><img src={knauf} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>CINTAS Y BANDAS - KNAUF</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F8v1.jpg?alt=media&token=34f25c76-ef72-4518-9ecb-93c74e12b8a1' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CINTA DE PAPEL</h5>
                    
                    <p className='p-cards-comp'>Esta cinta de papel microperforada presenta el doblez central marcado. Es ideal para el tratamiento de juntas entre placas con masilla lista para usar o Fungenfüller.</p>
                    <p className='p-cards-comp'><span>Ancho:</span> 50mm.</p>
                    <p className='p-cards-comp'><span>Largo:</span> 75m/150m.</p>
                </div>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F8v2.jpg?alt=media&token=c21b296a-af57-41fb-8f3f-5c748b69ad44' loading='lazy'/>
                        </div>
                    </div>
                    <h5>BANDA ACÚSTICA</h5>
                    
                    <p className='p-cards-comp'>Es una cinta autoadhesiva de espuma de polietileno, elástica y estanca que se puede utilizar para los perfiles que están en contacto con otras superficies.</p>
                    <p className='p-cards-comp'><span>Ancho:</span> 50mm/70mm.</p>
                    <p className='p-cards-comp'><span>Largo:</span> 30m.</p>
                </div>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F8v3.jpg?alt=media&token=d08a86f5-8569-4486-ab54-5159de0e819e' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CINTA PLÁSTICA</h5>
                    
                    <p className='p-cards-comp'>Autoadhesiva, con bajo pegamento, es especificada para encuentros entre cielorrasos y tabiques, cielorrasos y muros de otros materiales, cielorrasos y revestimientos, y tabiques y muros de otros materiales.</p>
                    <p className='p-cards-comp'><span>Ancho:</span> 48mm.</p>
                    <p className='p-cards-comp'><span>Largo:</span> 10m.</p>
                </div>
            </div>
        </>
    )    
}


const Accesorios = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://knauf.com.ar'><img src={knauf} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>ACCESORIOS CIELORRASO SUSPENDIDO - KNAUF</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F9v1.jpg?alt=media&token=92743e37-f48e-4900-b9df-721b6171ff9b' loading='lazy'/>
                        </div>
                    </div>
                    <h5>EMPALME F-47</h5>
                    
                    <p className='p-cards-comp'>Pieza que resuelve la unión horizontal entre dos perfiles F-47. Se utiliza en los sistemas Cielorrasos D112 Bidireccional y D116 en los casos en que el largo del perfil F-47 sea menor a uno de los lados de la habitación. Se encastra en el extremo de los F-47 resolviendo una unión sin generar saltos en la estructura manteniendo la perfecta nivelación del sistema. Conforman una junta de dilatación entre los perfiles para mejor comportamiento de todo el sistema.</p>
                </div>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F9v2.jpg?alt=media&token=33eaf25a-3690-4e70-b5c0-f9da030256ec' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CABALLETE F-47</h5>
                    
                    <p className='p-cards-comp'>Pieza que vincula verticalmente los perfiles F-47 Primarios con los F-47 Secundarios en los sistemas de Cielorrasos D112 Bidireccional y D116. Permite una unión móvil entre los perfiles y la libertad de movimientos para el sistema y concepto de “cielorraso flotante”.</p>
                </div>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F9v3.jpg?alt=media&token=33852471-19a0-4ea9-b1b4-a31cdeea3635' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CLIP UNIÓN VARILLA</h5>
                    
                    <p className='p-cards-comp'>Accesorio especial que permite el empalme y prolongación entre dos Varillas de Cuelgue del Cuelgue Regulable cuando la altura de la cámara de aire del sistema de cielorrasos supere la dimensión del Metro.</p>
                </div>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F9v4.jpg?alt=media&token=e3da95be-f844-4c34-ad36-767d87e2de2e' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ANCLAJE PIVOT</h5>
                    
                    <p className='p-cards-comp'>El anclaje Pivot es la parte que vincula la estructura F-47 con el resto del cuelgue mediante cuatro muescas que calzan en la boca del perfil, especialmente diseñada acorde a la forma.</p>
                </div>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F9v5.jpg?alt=media&token=e9eaca1a-eb3e-490c-8b46-ce42b2131cea' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PIVOT</h5>
                    
                    <p className='p-cards-comp'>La pieza Anclaje Universal se rosca a la parte superior de la Varilla y resuelve la vinculación directa al plano superior resistente, sea losa, techo o estructura, de la cubierta o entrepiso del cual se resuelve el cielorraso a construir con las placas de Yeso Knauf. La vinculación se realiza con dos fijaciones acordes a la materialidad de la base.</p>
                </div>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F9v6.jpg?alt=media&token=d5e67764-96a6-481e-91d4-584240f4abb1' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ANCLAJE DIRECTO</h5>
                    
                    <p className='p-cards-comp'>Pieza que permite una pequeña regulación entre el techo y cielorraso de entre 5 y 10 cm de altura. Se fija en dos puntos en el techo con fijaciones de 8mm de diámetro. Las distancias entre perfiles serán iguales al caso de Cuelgue Pivot.</p>
                </div>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F9v7.jpg?alt=media&token=f01d0ccd-14d8-4456-860e-c179aa2f5f7f' loading='lazy'/>
                        </div>
                    </div>
                    <h5>VARILLA ROSCADA GALVANIZADA</h5>
                    
                    <p className='p-cards-comp'>La Varilla roscada se toma al Pivot mediante rosca y permite la vinculación vertical y regulación de la altura de descuelgue. Se presenta en tres medidas comerciales que adaptan la pieza a las necesidades del proyecto.</p>
                </div>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F9v8.jpg?alt=media&token=c2e608b2-5fd1-4d99-93a5-553d7c931a45' loading='lazy'/>
                        </div>
                    </div>
                    <h5>VARILLA DE CUELGUE</h5>
                    
                    <p className='p-cards-comp'>La Varilla de cuelgue se enhebra en la suspensión regulable y en su extremo superior va fijada al techo o estructura con una fijación acorde a la materialidad de la misma estructura resistente.</p>
                </div>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F9v9.jpg?alt=media&token=43c7290f-2624-4c8c-b297-7a95e5f54fe5' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SUSPENSIÓN REGULABLE</h5>
                    
                    <p className='p-cards-comp'>Cuelgue y nivelación para armado de cielorrasos suspendidos. Se encastra a presión con el perfil F-47.</p>
                </div>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F9v10.jpg?alt=media&token=d771b820-1338-4bda-a699-0d1228163613' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SISTEMA DE CUELGUE PIVOT</h5>
                    
                    <p className='p-cards-comp'>El cuelgue se compone de Pivot, Varilla Roscada de 6 mm de diámetro y Anclaje Pivot que va fijado al techo o a estructura de vigas.</p>
                    <p className='p-cards-comp'>Soporta 40 Kg/m2. Las varillas permiten realizar una nivelación milimétrica de la estructura.</p>
                    <p className='p-cards-comp'>(1) Anclaje Pivot</p>
                    <p className='p-cards-comp'>(2) Varilla Roscada Galvanizada</p>
                    <p className='p-cards-comp'>(3) Pivot</p>
                </div>
            </div>
        </>
    )    
}



const Tapas = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://knauf.com.ar'><img src={knauf} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>TAPAS DE INSPECCIÓN - KNAUF</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F10v1.jpg?alt=media&token=97e15814-5d16-4223-9e2b-14953991105a' loading='lazy'/>
                        </div>
                    </div>
                    <h5>TAPA DE INSPECCIÓN</h5>
                    
                    <p className='p-cards-comp'>Práctica puerta de acceso a instalaciones en cielorrasos y tabiques. Se presenta de medidas variables según la necesidad del proyecto.</p>
                </div>
            </div>
        </>
    )    
}




const Herramientas = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://knauf.com.ar'><img src={knauf} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>HERRAMIENTAS - KNAUF</h4>
            <div className='cards-placas-container  space'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F11v1.jpg?alt=media&token=c39fbd83-f9d7-4b46-a09e-036a060db521' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PUNZONADORA PARA PERFILES METÁLICOS</h5>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F11v2.jpg?alt=media&token=d67729cf-294f-4198-b2bb-67de521afbff' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ESPÁTULA KNAUF 150 MM + PUNTA PHILIPS</h5>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F11v3.jpg?alt=media&token=93b681f6-c34b-4108-913f-61c6eb173607' loading='lazy'/>
                        </div>
                    </div>
                    <h5>LLANA 300 MM</h5>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F11v4.jpg?alt=media&token=bb5ba12e-530e-458d-984e-fecd7c94db28' loading='lazy'/>
                        </div>
                    </div>
                    <h5>ESCOFINA PARA PLACAS DE YESO</h5>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F11v5.jpg?alt=media&token=b34638f8-48d8-43de-90cf-33337446e377' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CUTTER</h5>
                </div>
            </div>
        </>
    )    
}


const PlacasFibrocemento = ()=>{
    return(
        <>
            <h4 className='title-placas'>PLACA DE FIBROCEMENTO SUPERBOARD ST - ETERNIT</h4>
            <div className='cards-placas-container'>
                <div className='card-placas-30 still'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F12v1.jpg?alt=media&token=f78b91b5-7d30-4801-b454-c0af8828b903' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PLACA DE FIBROCEMENTO SUPERBOARD ST - ETERNIT</h5>
                    
                    <p className='p-cards-comp'>Superboard ST son placas de cemento curadas en autoclave, lo que brinda una excelente estabilidad dimensional y resistencia. De simple y rápida instalación se presenta en varios espesores, dando respuesta a los diversos y exigentes requerimientos funcionales del mercado de la construcción.</p>
                    <br/>
                    <p className='p-cards-comp'><span>Medidas:</span> 6mm/8mm/10mm/15mm de espesor.</p>
                    <p className='p-cards-comp'><span>Ancho:</span> 1.200mm</p>
                    <p className='p-cards-comp'><span>Largo:</span> 2.400mm</p>
                    <p className='p-cards-comp'><span>Borde:</span> recto</p>
                </div>
            </div>
        </>
    )    
}


const Siding = ()=>{
    return(
        <>
            <h4 className='title-placas'>SIDING - ETERNIT</h4>
            <p>Siding Eternit es un revestimiento compuesto por tablas de cemento con textura de madera. Especialmente diseñado para brindar una respuesta decorativa y estética para proyectos donde la durabilidad y el diseño son los valores más relevantes.</p>
            <ul>
                <li><p className='p-cards-comp'>Tablas de cemento autoclavadas.</p></li>
                <li><p className='p-cards-comp'>Texturadas con vetas de madera.</p></li>
                <li><p className='p-cards-comp'>Diseño y estética natural.</p></li>
                <li><p className='p-cards-comp'>Gran durabilidad y muy bajo mantenimiento.</p></li>
                <li><p className='p-cards-comp'>Puede recibir pinturas, lacas o barnices.</p></li>
            </ul>
            <p>Siding Eternit es producido en base a una mezcla homogenea de cemento, sílice y fibras de celulosa seleccionadas. Su fraguado en autoclave (sometida a alta presión, humedad y alta temperatura) garantizan una excelente estabilidad dimensional, resistencia mecánica, durabilidad y desempeño.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F13v1.jpg?alt=media&token=08991507-c961-4010-b8af-97c303455708' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SIDING CEDAR (TEXTURA MADERA) IMAGEN PINTADA COLOR CEDRO</h5>
                    <p className='p-cards-comp'><span>Espesor:</span> 6mm y 8mm.</p>
                    <p className='p-cards-comp'><span>Largo:</span> 3.600mm</p>
                    <p className='p-cards-comp'><span>Ancho:</span> 200mm</p>
                    <p className='p-cards-comp'><span>Consumo:</span> 1,64c/m2</p>
                    
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F13v2.jpg?alt=media&token=4e2c2c4a-ead9-44e4-8e47-aee9a0f2f2b5' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SIDING ETERNiT LISO</h5>
                    <p className='p-cards-comp'><span>Espesor:</span> 6mm y 8mm.</p>
                    <p className='p-cards-comp'><span>Largo:</span> 3.600mm</p>
                    <p className='p-cards-comp'><span>Ancho:</span> 200mm</p>
                    <p className='p-cards-comp'><span>Consumo:</span> 1,64c/m2</p>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F13v3.png?alt=media&token=5ab0eacb-1956-4275-969d-d367849994c0' loading='lazy'/>
                        </div>
                    </div>
                    <h5>SIDING CEDAR (TEXTURA MADERA) IMAGEN COLOR NATURAL SIN IMPREGNAR</h5>
                    <p className='p-cards-comp'><span>Espesor:</span> 6mm y 8mm.</p>
                    <p className='p-cards-comp'><span>Largo:</span> 3.600mm</p>
                    <p className='p-cards-comp'><span>Ancho:</span> 200mm</p>
                    <p className='p-cards-comp'><span>Consumo:</span> 1,64c/m2</p>
                </div>
            </div>
        </>
    )
}


const Cielorraso = ()=>{
    return(
        <>
            <h4 className='title-placas'>CIELORRASO - ETERNIT</h4>
            <p>El cielorraso crea un espacio entre su estructura y el techo que se utiliza para el paso de las instalaciones.</p>

            <p>Tanto en la nueva obra como en restauraciones, los Sistemas de Cielorrasos ofrecen opciones precisas para atender exigencias estéticas, acústicas y climáticas. Eficientes y seguros en la resistencia al fuego, los sistemas de cielorrasos proporcionan múltiples soluciones, que van desde cielorrasos simples y económicos hasta proyectos excepcionales. No hay diseño que no pueda concretarse con los sistemas de cielorrasos, Color, efectos de iluminación, ventilación, aislación e intalaciones, todo encuentra una óptima solución técnica.</p>

            <hr/>

            <h4 style={{color: '#636363'}}>CIELORRASO JUNTA TOMADA</h4>
            <p>Las características técnicas de nuestros sistemas permiten crear una inmensa variedad de diseños y decorados, adaptándose perfectamente a todos los requerimientos acústicos o climáticos de cada proyecto. Por sus características, resultan sumamente eficaces para la protección contra incendios y el ahorro energético</p>

            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F14v1.png?alt=media&token=34fec664-52cf-4f28-ab09-7eaac26496bf' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CIELORRASOS D151.AR</h5>

                    <p className='p-cards-comp'>Este cielorraso está formado por una estructura metálica de perfiles Omega a cada 50 cm que va sujeta al directamente al techo con fijaciones. Es continuo y sin juntas a la vista. Puede colocarse una capa de placas Knauf de 9.5, 12.5 ó 15 mm. Es un sistema rígido pero tiene la ventaja de ocupar el mínimo espacio del ambiente. El techo base debe estar perfectamente nivelado, dado que el sistema copiara la forma del plano superior.</p>  
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F14v2.png?alt=media&token=1761796b-14c5-4008-a717-cb6836e35460' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CIELORRASO UNIDIRECCIONAL D112.AR</h5>

                    <p className='p-cards-comp'>El Cielorraso Unidireccional D112 es continuo y sin juntas a la vista. Está compuesto por una estructura de Perfiles F-47 suspendidos del techo por medio de Anclajes Directos, Cuelgues Pivot o Suspensiones Regulables ; relacionados a las paredes a través de perfiles U 25x20 y Banda Acústica Knauf . Sobre esta estructura se atornilla una capa de placas de yeso Knauf de 9.5 ó 12.5 mm.</p>
                    <p className='p-cards-comp'>Tratamiento de juntas: con cinta de papel microperforado y masilla Knauf.</p>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F14v3.png?alt=media&token=541d8bbb-6b73-43f5-be33-a06aae83a413' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CIELORRASO BIDIRECCIONAL D112.AR</h5>
                    <p className='p-cards-comp'>El Cielorraso Bidireccional D112 es continuo y sin juntas a la vista. Está compuesto por una estructura de Perfiles F-47 suspendidos colocados en dos sentidos y fijados al techo por medio de Anclajes Directos , Cuelgues Pivot o Suspensiones Regulables ; relacionados a las paredes a través de perfiles U 25x20 y Banda Acústica. Sobre esta estructura se atornillan una o dos capas de placas de yeso Knauf de 9.5, 12.5 ó 15 mm.</p>
                    <p className='p-cards-comp'>Tratamiento de juntas: con cinta de papel microperforado y masilla Knauf.</p>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F14v4.png?alt=media&token=ab1fb26e-8f2b-4441-831e-b52e1336a49d' loading='lazy'/>
                        </div>
                    </div>
                    <h5>CIELORRASO BIDIRECCIONAL D126.AR</h5>
                    <p className='p-cards-comp'>El Cielorraso Bidireccional D126.ar es absorbente acústico y purificador, su superficie es continua perforada y sin juntas a la vista. Está compuesto por una estructura de Perfiles F-47 suspendidos colocados en dos sentidos y fijados al techo por medio de Anclajes Directos, Cuelgues Pivot o Suspensiones Regulables; relacionados a las paredes a través de perfiles U 25x20 y Banda Acústica. Sobre esta estructura se atornillan las placas de yeso Knauf Cleaneo Akustik. Ideal para ambientes ruidosos, y cerrados, mejorando la calidad del aire interior y el confort acústico.</p>
                    <p className='p-cards-comp'>Tratamiento de juntas invisible: con masilla Knauf Uniflott.</p>
                </div>
            </div>

            <hr/>

            <h4 style={{color: '#636363'}}>CIELORRASO DESMONTABLE</h4>
            <p>Compuestos por una estructura metálica suspendida que queda a la vista, colocada en forma de cuadricula en la cual se apoyan las placas de yeso. Estas cuentan con varios diseños, permitiendo la diversidad de terminaciones.</p>

            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F14v5.png?alt=media&token=335aa498-48b9-46e5-b900-e7c5bd6f2f96' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PLACA YESO KNAUF PEGASUS</h5>

                    <p className='p-cards-comp'><span>Medidas: </span>605mm x 1215mm. Espesor: 9,5mm.</p>  
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F14v6.png?alt=media&token=eac8bd1b-d71d-42a1-ba54-470b5cc1a752' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PLACA YESO KNAUF NÉBULA</h5>

                    <p className='p-cards-comp'><span>Medidas: </span>605mm x 1215mm. Espesor: 8,5mm.</p>  
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F14v7.png?alt=media&token=853b6f90-87f3-4c30-a11f-3e8ca26a562e' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PLACA AMF ECOMIN FILIGRAN</h5>
                    <p className='p-cards-comp'><span>Medidas: </span> 610mm x 610mm. Espesor: 13mm.</p> 
                    <p className='p-cards-comp'>Placas de techo compuestas por lana mineral biosoluble de nueva generación, perlita, arcilla y almidón, que aportan excelentes propiedades técnicas en lo referente a la protección al fuego, acústica, higiene y resistencia a la humedad.</p> 
                    <p className='p-cards-comp'>La gama de techos AMF ECOMIN es ideal donde una buena calidad y un bajo costo son necesarios.</p> 
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F14v8.png?alt=media&token=2aa376d6-b2fc-4b7a-af7c-5b767a0f472b' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PLACA ANDINA</h5>
                    <p className='p-cards-comp'><span>Medidas: </span> 605mm x 1215mm. Espesor: 20mm.</p> 
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F14v9.png?alt=media&token=bd55c8d8-7446-4f6f-a5b3-58b0f0e30fba' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PLACA ANDINA ISOCUSTIC VELO NEGRO</h5>
                    <p className='p-cards-comp'><span>Medidas: </span> 605mm x 1215mm. Espesor: 20mm.</p> 
                    <p className='p-cards-comp'>Sirve para ser instalada con perfilería desmontable vista.</p> 
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F14v10.png?alt=media&token=5b56a611-3b29-47fb-977d-7f5632dfc126' loading='lazy'/>
                        </div>
                    </div>
                    <h5>PLACA ANDINA ISOCUSTIC VELO BLANCO</h5>
                    <p className='p-cards-comp'><span>Medidas: </span> 605mm x 1215mm. Espesor: 20mm.</p> 
                    <p className='p-cards-comp'>Se utiliza para ser instalado con perfilería desmontable vista, en salones, auditorios, restaurantes, bares, cines, etc.</p> 
                </div>
            </div>

                <div style={{alignItems: 'center', margin: '30px, 0'}} className='cards-placas-container'>
                    <div className='card-placas-60'>
                        <p style={{margin: '0'}}>
                        Cielorrasos de lana de vidrio Isover G3 diseñados para el mejoramiento acústico, mejorar los tiempos de reverberación y acondicionamiento térmico de los locales, únicos con barrera de vapor incorporada. Los cielorrasos andina PVC al estar compuesto por lana de vidrio y Pvc en la cara vista es un material que posee una excelente absorción acústica, ofreciendo además un excelente comportamiento en caso de incendio, haciéndolo ideal para lugares con gran afluencia de público.
                        </p>
                    </div>
                    <div className='card-placas-30'>
                        <div className='marco-img'>
                            <div className='img-card'>
                                <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F14v11.png?alt=media&token=87f7ad39-0f76-4e2d-bee3-2f42cda2eb91' loading='lazy'/>
                            </div>
                        </div>
                    </div>
                </div>

            <hr/>

            <h5>PERFILES DESMONTABLES PARA LA SUSPENSIÓN DE CIELORRASOS DESMONTABLES</h5>
            <p>El sistema de suspensión desarrollado con Perfiles de Acero Galvanizado se caracteriza por su resistencia, durabilidad y alta estabilidad. La estructura es visible solamente mediante una cara expuesta que otorga a cada proyecto una apariencia limpia y refinada, siendo la solución ideal para múltiples aplicaciones.</p>
            <p>El perfil T principal (larguero) proporciona una conexión fuerte y segura con una precisa alineación. El perfil T secundario permite asegurar la conexión con facilidad para desmontarse, volver a utilizar o reubicarse. Los perfiles T (larguero y travesaño) mantienen los componentes verticales y estables donde se instalan las luminarias. Cubierta galvanizada que inhibe la formación de óxido rojo.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F14v12.png?alt=media&token=3a3e4531-1630-4a24-b24b-442caa0e7f87' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>

            <hr/>

            <h4 style={{color: '#636363'}}>CIELORRASO DE PVC</h4>
            <p>Es ideal para uso residencial, hospitales, escuelas, supermercados, estaciones de servicio, laboratorios, bancos, farmacias, frigoríficos, salones de fiestas, vestuarios, plantas elaboradoras de alimentos, industrias, oficinas, aeropuertos, etc. esencialmente indicado para corporaciones donde se demanden condiciones substanciales de higiene.</p>
            <ul>
                <li><p className='p-cards-comp'>Resistente a la acción de hongos, bacterias, insectos y roedores.</p></li>
                <li><p className='p-cards-comp'>Resistencia, rigidez y dureza mecánica elevadas.</p></li>
                <li><p className='p-cards-comp'>Rango de temperatrura de trabajo de -15ºC a 60ºC.</p></li>
                <li><p className='p-cards-comp'>Autoextinguible. No propaga la llama.</p></li>
                <li><p className='p-cards-comp'>Vida útil ilimitada.</p></li>
                <li><p className='p-cards-comp'>Fácil instalación.</p></li>
                <li><p className='p-cards-comp'>Impermeable.</p></li>
                <li><p className='p-cards-comp'>No requiere mantenimiento</p></li>
            </ul>
            <p>Para realizar la instalación de un cielorraso de PVC, primero se debe realizar una estructura de perfilesde acero galvanizado livianos o madera bien rígida. Esta estructura deberá estar perfectamente nivelada con un espacio entre los listones de estructura de aproximadamente 0.70 m. El sentido de la colocación deberá ser contrario al sentido de colocación del cielorraso.</p>
            <p>No se requiere selladores ya que las placas encajan simplemente a presión tipo machimbre y los tornillos de fijación quedan ocultos. <br/>La instalación es limpia y rápida, no requiere de herramientas especiales y puede ser realizada por personal que no posea habilidades especiales</p>
            <p style={{marginTop: '20px'}} className='p-cards-comp'><span>Medidas:</span></p>
            <p className='p-cards-comp'>Cielorraso PVC7,5 mm 25 cm x 3,95 m 109,88M² Blanco.</p>
            <p className='p-cards-comp'>Cielorraso PVC7,5 mm 25 cm x 5,95 m 1014,88M² Blanco.</p>
            <div className='cards-placas-container'>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F14v13.png?alt=media&token=56a9f9df-14bc-4e71-a949-68ddb8893fb6' loading='lazy'/>
                        </div>
                    </div>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F14v14.png?alt=media&token=f2854994-153b-4bfb-93f8-0577a8376f9f' loading='lazy'/>
                        </div>
                    </div>
                </div>
                <div className='card-placas-30'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FSteel%2F14v15.png?alt=media&token=780d75a3-8baf-46ad-941b-57b7fb1751fb' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}