import React, { useState } from 'react';
import Banner from '../../../components/Banner/Index';
import './Yeso.css';
import knauf from './../../../Images/marcas/6.svg';

const Yeso = () => {
    const [comp, setComp] = useState('');

    const switchComponent = (selec)=>{
        switch(selec){
            case '': return <Default/>;
            case 'MP 75': return <Mp75/>;
            case 'Rotband': return <Rotband/>;
            case 'Baugips': return <Baugips/>;
            default: return <Default/>
        }
    }


  return (
    <>
        <Banner title='Yeso'/>
        <div className='products-container'>
            <div className='submenu-products'>
                <div className='div-submenu'>
                    <h5 onClick={()=> setComp('')}>Yeso</h5>
                    <div className='buttons-submenu'>
                        <button onClick={()=> setComp('MP 75')} type='button'>MP 75</button>
                        <button onClick={()=> setComp('Rotband')} type='button'>Rotband</button>
                        <button onClick={()=> setComp('Baugips')} type='button'>Baugips</button>
                    </div>
                </div>
            </div>
            <div className='content-products-container'>
                {switchComponent(comp)}
            </div>
        </div>
    </>
  )
}

export default Yeso;


const Default = ()=>{
    return (
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://knauf.com.ar'><img src={knauf} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <img className='img-default' alt='const-en-seco' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FYeso%2Fprincipal.jpg?alt=media&token=dbb7265d-88a1-48c1-95c3-2a3a926d4214'/>
            <p>
                Revoque de alto rendimiento KNAUF es un gran cambio en la manera de revocar, logran paredes y cielorrasos con superficies espejadas y suaves, listas para la pintura o cualquier otro tratamiento. Logran un excelente agarre en todo tipo de pared y pueden alcanzar diferentes espesores, con estabilidad controlada en todo momento. Y todo esto, en una sola capa: reemplaza al viejo revoque grueso y fino realizado con cemento. También acelera la construcción, ya que el tiempo de aplicación es menor que el de los sistemas tradicionales multicapa de base cemento, con obras mucho más limpias y prolijas...
            </p>
            <p>
                Knauf suma a los reconocidos sistemas de tabiques, cielorrasos y revestimientos su nueva línea de revoques de alto rendimiento y excelente calidad, que completan todo tipo de construcción con terminaciones modernas e impecables. Combinando la pureza del yeso de alta montaña con una fórmula desarrollada con tecnología de punta, los nuevos revoques Knauf superan todo lo conocido en el mercado, ya sea para aplicación manual o a máquina, de acabado o monocapa. Tan duros como un revoque de cemento y con un nivel de pureza de hasta más del 90%, aportan gran maleabilidad y dan a sus obras un excelente acabado liso, blanco y suave, manteniendo intactas sus propiedades de origen. Con menos trabajo, los nuevos yesos Knauf logran resultados muy superiores a todo lo conocido en revoques de cemento: su fórmula superior conserva la dureza natural de las rocas de la cantera, con gran resistencia a los impactos, a la vez que las superficies finales resultan mucho más suaves al tacto.
            </p>
            <p>
                Los revoques Knauf ofrecen mayores prestaciones de seguridad contra el fuego y la humedad, los espacios revocados con yeso se sienten más cálidos y confortables, debido a que controlan naturalmente la humedad interior; mantienen las temperaturas de superficie y de ambiente cercanas y equilibradas entre los 18°C y 20°C, por lo que también se logran importantes ahorros de energía en climatización del aire.
            </p>
        </>
    )
}



const Mp75 = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://knauf.com.ar'><img src={knauf} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>MP 75</h4>
            <p>El yeso Knauf MP75 es un revoque monocapa premezcaldo con aditivos a base de yeso para ser aplicado en interiores, con máquinas de proyectar; con tiempo extra de fragüe para optimizar las terminaciones en revoques y lograr mejor acabado de superficie. Adecuado para el revoque de grandes superficies, con resultados impecables en todo tipo de trabajo interior, con extrema dureza, resistencia y durabilidad. Es la solución más económica, ya que se mezcla y aplica homogéneamente a máquina. El procedimiento es mucho más fácil, ahorra energía y sobre todo tiempo.</p>
            <h4>FORMA DE PRESENTACIÓN</h4>
            <p>Bolsa de 30 kgs.</p>
            <h4>RENDIMIENTO</h4>
            <p>8.3 - 9 kg por m2 para 1 cm de espesor de revoque.</p>
            <h4>TIEMPO ABIERTO DE TRABAJO</h4>
            <p>La aplicación a máquina se distingue por el resultado parejo y el secado homogéneo. El tiempo abierto de trabajo se calcula en aproximadamente 2 horas dependiendo de la superficie donde será aplicado. Esto permite una aplicación racional en superficies grandes. Dado que el yeso aplicado de esta manera mantiene su plasticidad se permiten interrupciones en el trabajo de proyección de hasta 15 minutos. Una vez terminado el trabajo hay que limpiar la máquina, las mangueras y el rotor cuidadosamente.</p>
            <h4>TEMPERATURA DE APLICACIÓN</h4>
            <p>No utilizar el producto con temperaturas menores a 5°C . Una vez aplicado deberá ser protegido de congelamiento hasta su secado completo.</p>
            <h4>ALMACENAJE</h4>
            <p>Guardar las bolsas secas sobre pallet de madera. Las bolsas dañadas o abiertas deben ser cubiertas y utilizarse. No exponerlas a la intemperie.</p>
            <h4>PRECAUCIONES</h4>
            <p>Se recomienda la utilización de elementos de protección personal. No ingerir. Mantener fuera del alcance de los niños.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-360'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FYeso%2F1v1.png?alt=media&token=0213758d-f5a0-4a4a-a1d7-5d893b863938' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const Rotband = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://knauf.com.ar'><img src={knauf} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>ROTBAND</h4>
            <p>El yeso Knauf Rotband es un revoque monocapa premezcaldo con aditivos a base de yeso para ser aplicado en interiores, de forma manual. Knauf Rotband se destaca particularmente para el uso en mejoras y reparaciones de pequeñas superficies. Se ajustan a tiempos cortos de trabajo, contrariamente a los yesos de máquina ideales para el trabajo sobre grandes superficies.</p>
            <h4>FORMA DE PRESENTACIÓN</h4>
            <p>Bolsa de 30 kgs.</p>
            <h4>RENDIMIENTO</h4>
            <p>8 kg por m2 para 1 cm de espesor de revoque.</p>
            <h4>TIEMPO ABIERTO DE TRABAJO</h4>
            <p>El tiempo abierto de trabajo se calcula en aproximadamente 2 horas dependiendo de la superficie donde será aplicado. Esto permite una aplicación racional en superficies grandes.</p>
            <h4>TEMPERATURA DE APLICACIÓN</h4>
            <p>No utilizar el producto con temperaturas menores a 5°C . Una vez aplicado deberá ser protegido de congelamiento hasta su secado completo.</p>
            <h4>ALMACENAJE</h4>
            <p>Guardar las bolsas secas sobre pallet de madera. Las bolsas dañadas o abiertas deben ser cubiertas y utilizarse. No exponerlas a la intemperie.</p>
            <h4>PRECAUCIONES</h4>
            <p>Se recomienda la utilización de elementos de protección personal. No ingerir. Mantener fuera del alcance de los niños.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-360'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FYeso%2F2v1.png?alt=media&token=d3bb4fb1-194d-45db-8ac9-c8b4f275ab46' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const Baugips = ()=>{
    return(
        <>
            <h3 className='default-title'>SOMOS DISTRIBUIDORES EXCLUSIVOS DE <a href='https://knauf.com.ar'><img src={knauf} alt='knauf'/></a></h3>
            <hr className='divisor-hr'/>
            <h4 className='title-placas'>BAUGIPS</h4>
            <p>EL yeso de obra tradicional, con la marca de calidad Knauf. Yeso puro, sin aditivos y de secado rápido, para ser utilizado en interiores como revoques (engrosados o enlucidos) y aplicarse en forma manual en todo tipo de trabajos.</p>
            <h4>FORMA DE PRESENTACIÓN</h4>
            <p>Bolsa de 40 kgs.</p>
            <h4>RENDIMIENTO</h4>
            <p>4 kg por m2 para enlucido. 9 -11 kg por m2 para engrosado y enlucido.</p>
            <h4>TIEMPO ABIERTO DE TRABAJO</h4>
            <p>Inicio de fragüe: 4 / 5 minutos aprox.</p>
            <h4>TIEMPO DE TRABAJO</h4>
            <p>Hasta 14 minutos aprox.</p>
            <h4>SECADO</h4>
            <p>Aprox. 30 minutos dependiendo de la superficie donde será aplicado.</p>
            <h4>TEMPERATURA DE APLICACIÓN</h4>
            <p>No utilizar el producto con temperaturas menores a 5°C . Una vez aplicado deberá ser protegido de congelamiento hasta su secado completo.</p>
            <h4>ALMACENAJE</h4>
            <p>Guardar las bolsas secas sobre pallet de madera. Las bolsas dañadas o abiertas deben ser cubiertas y utilizarse. No exponerlas a la intemperie.</p>
            <h4>PRECAUCIONES</h4>
            <p>Se recomienda la utilización de elementos de protección personal. No ingerir. Mantener fuera del alcance de los niños.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-360'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FYeso%2F3v1.png?alt=media&token=8c133934-bba0-4a78-b692-4dfbb7413281' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}