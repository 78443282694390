import "./App.css";
/*******Components all pages******/
import Footer from "./components/Footer";
import Header from "./components/Header/Index";
import WppBurble from "./components/WppBurble/Index";
/********Config******/
import { Route, Routes, Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext, AuthProvider } from "./Auth/useAuth";
import { AppProvider } from "./Context/AppContext";
/*************Views********/
import Home from "./views/Home/Home";
import Empresa from "./views/Empresa/Empresa";
/*************Productos********/
import Precios from "./views/Productos/Precios/Precios";
import Steel from "./views/Productos/Steel/Steel";
import Placas from "./views/Productos/Placas/Placas";
import Techos from "./views/Productos/Techos/Techos";
import Herrajes from "./views/Productos/Herrajes/Herrajes";
import Maderas from "./views/Productos/Maderas/Maderas";
import Molduras from "./views/Productos/Molduras/Molduras";
import Chapas from "./views/Productos/Chapas/Chapas";
import Pisos from "./views/Productos/Pisos/Pisos";
import Herramientas from "./views/Productos/Herramientas/Herramientas";
import Aislacion from "./views/Productos/Aislacion/Aislacion";
import Ferreteria from "./views/Productos/Ferreteria/Ferreteria";
import Yeso from "./views/Productos/Yeso/Yeso";
import Pinturas from "./views/Productos/Pinturas/Pinturas";
import Rurales from "./views/Productos/Rurales/Rurales";
/*************Productos********/
import Novedades from "./views/Novedades/Novedades";
import Novedad from "./views/Novedad/Novedad";
import Optimizador from "./views/Optimizador/Optimizador";
/*****Private******/
import Administracion from "./views/Administracion/Administracion";
import NovedadCreate from "./views/NovedadCreate/NovedadCreate";
import Docs from "./views/Docs/Docs";
import Calefaccion from "./views/Productos/Calefaccion/Calefaccion";
import Login from "./views/Login/Login";
import Politicas from "./views/Politicas/Politicas";
import Order from "./views/Order/Order";
import E404 from "./views/E404/E404";
import Ofertas from "./components/Ofertas/Ofertas";

const RouteWithoutAuth = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated) {
    return <Navigate to="/administracion" />;
  }

  return children;
};

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);

  if (!isAuthenticated) {
    return <Navigate to={`/login`} />;
  }

  return children;
};

function App() {
  return (
    <>
      <AuthProvider>
        <AppProvider>
          <div className="body">
            <Header />

            <div className="max-1920">
              <Routes>
                <Route path="/" element={<Home />} />

                <Route path="/empresa" element={<Empresa />} />

                <Route path="/optimizador" element={<Optimizador />} />

                <Route path="/ordenes/:id" element={<Order />} />

                <Route path="/novedades" element={<Novedades />} />

                <Route path="/novedades/:id" element={<Novedad />} />

                <Route path="/productos/precios" element={<Precios />} />

                <Route path="/productos/steel-framing" element={<Steel />} />

                <Route path="/productos/placas" element={<Placas />} />

                <Route path="/productos/techos" element={<Techos />} />

                <Route path="/productos/herrajes" element={<Herrajes />} />

                <Route path="/productos/maderas" element={<Maderas />} />

                <Route path="/productos/molduras" element={<Molduras />} />

                <Route
                  path="/productos/chapas-y-perfiles"
                  element={<Chapas />}
                />

                <Route path="/productos/pisos-y-decks" element={<Pisos />} />

                <Route
                  path="/productos/herramientas"
                  element={<Herramientas />}
                />

                <Route path="/productos/aislacion" element={<Aislacion />} />

                <Route path="/productos/ferreteria" element={<Ferreteria />} />

                <Route path="/productos/yeso" element={<Yeso />} />

                <Route
                  path="/productos/pinturas-y-accesorios"
                  element={<Pinturas />}
                />

                <Route
                  path="/productos/articulos-rurales"
                  element={<Rurales />}
                />

                <Route
                  path="/productos/calefaccion"
                  element={<Calefaccion />}
                />

                <Route
                  path="/login"
                  element={
                    <RouteWithoutAuth>
                      <Login />
                    </RouteWithoutAuth>
                  }
                />

                <Route
                  path="/administracion"
                  element={
                    <ProtectedRoute>
                      <Administracion />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/novedades/edit/:id"
                  element={
                    <ProtectedRoute>
                      <NovedadCreate />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/docs-optimizador"
                  element={
                    <ProtectedRoute>
                      <Docs />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/politicas-de-privacidad"
                  element={<Politicas />}
                />

                <Route path="*" element={<E404 />} />
              </Routes>

              <Ofertas />

              <WppBurble />

              <Footer />
            </div>
          </div>
        </AppProvider>
      </AuthProvider>
    </>
  );
}

export default App;
