import React from 'react';
import imgBanner from '../../Images/banner.jpg';
import './Banner.css';

const Banner = ({title}) => {
  return (
    <div className='banner-general-container' style={{backgroundImage: `url(${imgBanner})`}}>
        <div className='banner-general-content'>
            <h3>{title}</h3>
            <span></span>
        </div>
    </div>
  )
}

export default Banner;