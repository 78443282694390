import React from 'react'
import './Ofertas.css'

const Ofertas = () => {
  return (
    <a rel='noreferrer' target='_blank' href='https://drive.google.com/drive/folders/1My_SpaoCH0C1lcZ73fGrHTkHoNaiB4-g?usp=share_link' className='ofertasBubble'>
      ¡VER OFERTAS!
      <span />
      <span />
    </a>
  )
}

export default Ofertas
