import React, { useEffect, useState, useContext } from 'react';
import {AuthContext} from '../../Auth/useAuth';
import {AppContext} from '../../Context/AppContext';
import './Administracion.css';
import Banner from '../../components/Banner/Index';
import MenuAdministracion from '../../components/MenuAdministracion/Index';
import CardNew from '../../components/CardNew/Index'
import { NavLink } from 'react-router-dom';
import db from '../../firebase/firebase';
import { collection, getDocs } from 'firebase/firestore';

const Administracion = () => {
  const {login, logout} = useContext(AuthContext);
  let dateLogin = localStorage.getItem('date');
  if(dateLogin !== null && (Date.now() - parseInt(dateLogin) < 172800000)){
    login();
    localStorage.setItem('date', Date.now());
  }
  else{
    logout();
    localStorage.removeItem('date')
  }


  const [novedades, setNovedades] = useState([]);
  const {deleNov} = useContext(AppContext);

  useEffect(()=>{
    const obtenerDatos = async()=>{
        const docs = [];
        const datos = await getDocs(collection(db, 'novedades'));
        datos.forEach(doc =>{docs.push({...doc.data(), id: doc.id})});
        setNovedades(docs);
    }
    obtenerDatos();
  }, [deleNov])

  return (
    <div className='container-total'>
        <Banner title='Administración'/>
        <MenuAdministracion/>
        <div className='createNew'>
            <NavLink to='/novedades/edit/false'>Crear nueva novedad</NavLink>
        </div>
        <div className='cards-news-administracion'>
          {novedades.map(novedad => (
              <CardNew key={novedad.id} novedad={novedad} edit={true}/>
          ))}
        </div>
    </div>
  )
}

export default Administracion;