import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import Banner from '../../components/Banner/Index';
import './Novedad.css';
import db from '../../firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';

const Novedad = () => {
  const [novedad, setNovedad] = useState([]);
  const {id} = useParams();

  useEffect(()=>{
    const obtenerDatos = async(idNew)=>{
      const docRef = doc(db, 'novedades', idNew);
      const docSnap = await getDoc(docRef);
      setNovedad(docSnap.data());
    }
    obtenerDatos(id);
  }, [id])


  return (
    <>
      <Banner title='Novedades'/>
      <div className='novedades-container'>
        <h2>{novedad.title}</h2>
        <hr></hr>
        <p>{novedad.shortDescription}</p>
        <div className='novedad-img-container'>
          <img src={novedad.img} alt='img novedad'/>
        </div>
        <p>
          {novedad.text}
        </p>
        {novedad.secondImg ?
          (<div className='novedad-img-container'>
            <img src={novedad.secondImg} alt='segunda img novedad'/>
          </div>) : null
        }
        {novedad.secondText ? 
          (<p>{novedad.secondText}</p>) : null
        }
      </div>
    </>
  )
}

export default Novedad;