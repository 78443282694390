import React from 'react';
import Banner from '../../../components/Banner/Index';
import './Ferreteria.css';

const Ferreteria = () => {
  return (
    <>
        <Banner title='Ferretería'/>
        <div className='products-container'>
            <div className='submenu-products'>
                <div className='div-submenu'> 

                </div>
            </div>
            <div className='content-products-container'>
              <img className='img-default' alt='const-en-seco' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FFerreteria%2Fprincipal.jpg?alt=media&token=f40f4454-f131-4665-824b-1d2c9055b59b' loading='lazy'/>
              <p>
                Contamos con una amplia línea de tornillos, bulones, tirafondos, tarugos, fijaciones mecánicas, clavos. Todos de marcas líderes.
              </p>
              <p>
                Haciendo foco en toda la tornillería y fijaciones específicas para la construcción en seco y el stell-frame.
              </p>
              <div className='images-ferreteria'>
                <img alt='img1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FFerreteria%2F1v1.png?alt=media&token=b715c13f-b2c6-4dc1-a5e5-971cf45c3f75' loading='lazy'/>
                <img alt='img2' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FFerreteria%2F1v2.png?alt=media&token=d2f4430e-4910-47b5-8533-02cf409e4ee0' loading='lazy'/>
                <img alt='img3' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FFerreteria%2F1v3.png?alt=media&token=cce31640-903e-448f-9243-30d2536855d4' loading='lazy'/>
                <img alt='img4' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FFerreteria%2F1v4.png?alt=media&token=4d87269f-bd61-4128-882d-e7f055c487e9' loading='lazy'/>
              </div>
            </div>
        </div>
    </>
  )
}

export default Ferreteria;