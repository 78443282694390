import React, { useState } from 'react';
import Banner from '../../../components/Banner/Index';
import './Molduras.css';

const Molduras = () => {
    const [comp, setComp] = useState('');


    const switchComponent = (selec)=>{
        switch(selec){
            case '': return <Default/>;
            case '-Zocalo': return <Zocalo/>;
            case '-Zocalin': return <Zocalin/>;
            case '-Terminacion': return <Terminacion/>;
            case '-Tapajunta': return <Tapajunta/>;
            case '-Roseton': return <Roseton/>;
            case '-Rinconero': return <Rinconero/>;
            case '-Pasamano': return <Pasamano/>;
            case '-Liston': return <Liston/>;
            case '-Inglete': return <Inglete/>;
            case '-Guardasilla': return <Guardasilla/>;
            case '-Esquinero': return <Esquinero/>;
            case '-Marco': return <Marco/>;
            case '-Contravidrio': return <Contravidrio/>;
            case '-Contramarco': return <Contramarco/>;
            case '-Barral liso': return <Barral/>;
            case '-Balaustre': return <Balaustre/>;
            case 'Molduras de madera de saligna': return <Saligna/>;
            case 'Molduras de MDF prepintadas': return <Prepintadas/>;
            case 'Molduras de zocalos foliados': return <Foliados/>;
            case 'Molduras de MDF crudos': return <Crudo/>;
            case 'Molduras de telgopor': return <Telgopor/>;
            default: return <Default/>
        }
    }


  return (
    <>
        <Banner title='Molduras'/>
        <div className='products-container'>
            <div className='submenu-products'>
                <div className='div-submenu'>
                    <h5 onClick={()=> setComp('')}>Molduras</h5>
                    <div className='buttons-submenu'>
                        <button id='different-button'>Molduras de madera de pino</button>
                        <button onClick={()=> setComp('-Zocalo')} type='button'>-Zócalo</button>
                        <button onClick={()=> setComp('-Zocalin')} type='button'>-Zocalín</button>
                        <button onClick={()=> setComp('-Terminacion')} type='button'>-Terminación</button>
                        <button onClick={()=> setComp('-Tapajunta')} type='button'>-Tapajunta</button>
                        <button onClick={()=> setComp('-Roseton')} type='button'>-Rosetón</button>
                        <button onClick={()=> setComp('-Rinconero')} type='button'>-Rinconero</button>
                        <button onClick={()=> setComp('-Pasamano')} type='button'>-Pasamano</button>
                        <button onClick={()=> setComp('-Liston')} type='button'>-Listón</button>
                        <button onClick={()=> setComp('-Inglete')} type='button'>-Inglete</button>
                        <button onClick={()=> setComp('-Guardasilla')} type='button'>-Guardasilla</button>
                        <button onClick={()=> setComp('-Esquinero')} type='button'>-Esquinero</button>
                        <button onClick={()=> setComp('-Marco')} type='button'>-Marco</button>
                        <button onClick={()=> setComp('-Contravidrio')} type='button'>-Contravidrio</button>
                        <button onClick={()=> setComp('-Contramarco')} type='button'>-Contramarco</button>
                        <button onClick={()=> setComp('-Barral liso')} type='button'>-Barral liso</button>
                        <button onClick={()=> setComp('-Balaustre')} type='button'>-Balaustre</button>
                        <button onClick={()=> setComp('Molduras de madera de saligna')} type='button'>Molduras de madera de saligna</button>
                        <button onClick={()=> setComp('Molduras de MDF prepintadas')} type='button'>Molduras de MDF prepintadas</button>
                        <button onClick={()=> setComp('Molduras de zocalos foliados')} type='button'>Molduras de zócalos foliados</button>
                        <button onClick={()=> setComp('Molduras de MDF crudos')} type='button'>Molduras de MDF crudos</button>
                        <button onClick={()=> setComp('Molduras de telgopor')} type='button'>Molduras de telgopor</button>
                    </div>
                </div>
            </div>
            <div className='content-products-container'>
                {switchComponent(comp)}
            </div>
        </div>
    </>
  )
}

export default Molduras;

const Default = ()=>{
    return (
        <>
            <img className='img-default' alt='const-en-seco' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2Fprincipal.jpg?alt=media&token=5ac762a8-3436-4cae-a073-887e28c3314f' loading='lazy'/>
            <p>
                Tanto para construcciones nuevas como para trabajos de renovación, las molduras tienen dos propósitos: función y belleza. Estas piezas simples decorativas cubren uniones y protegen bordes y esquinas. 
            </p>
            <p>
                En LUMADI MADERAS contamos con amplia variedad de molduras en madera y telgopor.
            </p>
        </>
    )
}



const Zocalo = ()=>{
    return(
        <>
            <h4 className='title-placas'>ZÓCALO</h4>
            <p>Los zócalos tienen su máxima utilización en la unión de pared y piso.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F1v1.png?alt=media&token=04060109-2bb2-4325-bd81-677b505f3fc1' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Zocalin = ()=>{
    return(
        <>
            <h4 className='title-placas'>ZOCALÍN</h4>
            <p>Los zocalnes tienen su máxima utilización en la unión de contramarco y zócalo en aberturas de puertas y portones.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F2v1.png?alt=media&token=10c616b6-e67c-4f61-b43f-be488746fa09' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Terminacion = ()=>{
    return(
        <>
            <h4 className='title-placas'>TERMINACIÓN</h4>
            <p>Las molduras para terminaciones tienen diversa utilización en el ámbito del mueble, aberturas y otras aplicaciones en el rubro de la construcción.</p>
            <p>La mayoría de estas molduras es utilizada para rematar terminaciones machimbradas u otras uniones, aplicadas en puertas ventanas o paredes a modo de cuadros en sobre relieve, o actuando combinada entre otras molduras, generando nuevos estilos de mayor ornamento.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F3v1.png?alt=media&token=308ae065-da49-4ebd-becc-4bf6148689c6' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Tapajunta = ()=>{
    return(
        <>
            <h4 className='title-placas'>TAPAJUNTA</h4>
            <p>Las tapajuntas tienen su máxima utilización en la terminación del ensamblado de unión a filo entre placas para cielorrazos u otro tipo de cerramientos, generando estilo y terminación en la conformación de dichas uniones.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F4v1.png?alt=media&token=b44b8942-7c19-4604-9ac0-ec71a53830de' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Roseton = ()=>{
    return(
        <>
            <h4 className='title-placas'>ROSETÓN</h4>
            <p>Los rosetones, tienen su máxima utilización en la unión de contramarcos en las esquinas superiores de las aberturas.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F5v1.png?alt=media&token=9cac093d-e11b-4391-8a48-329ed1366063' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Rinconero = ()=>{
    return(
        <>
            <h4 className='title-placas'>RINCONERO</h4>
            <p>Los rinconeros tienen su máxima utilización en la unión de superficies planas que se encuentran a 90º, tanto sean unión de pared a pared, techo pared o actuado combinada a otras molduras generando un pasaje formal de un plano ortogonal a otro.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F6v1.png?alt=media&token=d7b6782b-af5e-41db-93b1-f748a2f5c747' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Pasamano = ()=>{
    return(
        <>
            <h4 className='title-placas'>PASAMANO</h4>
            <p>Los pasamanos tienen su máximo uso a modo de barandas en la unión en balcones y escaleras. También pueden vincularse paralelos a escaleras de lado de la pared.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F7v1.png?alt=media&token=80b79c1c-fa74-40ad-94bf-d2b096940faf' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Liston = ()=>{
    return(
        <>
            <h4 className='title-placas'>LISTÓN</h4>
            <p>Los listones son utilizados para diversas aplicaciones en carpintería de obra, mobiliario, etc.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F8v1.png?alt=media&token=29458c68-61c3-47c8-8f12-1988d469ea33' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Inglete = ()=>{
    return(
        <>
            <h4 className='title-placas'>INGLETE</h4>
            <p>Herramienta para ajustar empalmes entre molduras.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F9v1.png?alt=media&token=de0588bb-b193-46d2-92d9-755ef873b571' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Guardasilla = ()=>{
    return(
        <>
            <h4 className='title-placas'>GUARDASILLA</h4>
            <p>Los guardasillas tienen su máxima utilización como protectores de choque o fricción de sillas u otros muebles en paredes o planos verticales salientes a la altura de la posible fricción de estos objetos. También para unir superficies planas que se encuentran en el mismo plano o en uniones de azulejados, cerámicos, empapelados, machimbrados u otros revestimientos, generando un pasaje formal de un plano paralelo o coincidente a otro.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F10v1.png?alt=media&token=5f492413-c28a-4f67-b73e-6ac8f82e09f5' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Esquinero = ()=>{
    return(
        <>
            <h4 className='title-placas'>ESQUINERO</h4>
            <p>Los esquineros tienen su máxima utilización en la unión de superficies planas que se encuentran formando esquina a 90º. También pueden ser utilizadas para rematar terminaciones machimbradas u otras uniones similares, o actuando combinada en la unión de dos molduras 90º generando nuevos estilos de esquineros de mayor ornamento.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F11v1.png?alt=media&token=d7e8ae77-0816-487d-9e06-618b9e2a0841' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}




const Marco = ()=>{
    return(
        <>
            <h4 className='title-placas'>MARCO</h4>
            <p>Los marcos son utilizados para enmarcar obras de arte, retratos, fotografias, etc; brindándole a la imagen enmarcada una terminación, protección con vidrio u otro material transparente, enfatizando al objeto enmarcado.</p>
            <br/>
            <p>Para seleccionar que tipo de moldura para cuadro es la adecuada para la imagen a enmarcar, se deben tener en cuenta el tamaño y el estilo del objeto, seleccionando secciones mayores para cuadros de mayor tamaño y menores secciones para cuadros mas pequeños. El estilo quedara a criterio del consumidor, pudiendo elegir molduras más ornamentales para algunos casos u otras más simples para otros.</p>
            <div className='cards-placas-container'>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F12v1.png?alt=media&token=a750f04a-c39b-4f78-b958-60c4dde5b9cf' loading='lazy'/>
                        </div>
                    </div>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F12v2.png?alt=media&token=14dcd445-2762-40c8-861f-92f4e7fce7d9' loading='lazy'/>
                        </div>
                    </div>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F12v3.png?alt=media&token=093c6191-b91c-4a56-a20c-158219dd28bd' loading='lazy'/>
                        </div>
                    </div>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F12v4.png?alt=media&token=642602c0-276c-4f75-a184-9f7a818694db' loading='lazy'/>
                        </div>
                    </div>
                </div>
                <div className='card-placas-45'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F12v5.png?alt=media&token=7850ac30-669e-4e39-a6f5-70cc484e2776' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Contravidrio = ()=>{
    return(
        <>
            <h4 className='title-placas'>CONTRAVIDRIO</h4>
            <p>Los contravidrios tienen su máxima utilización en el ensamblado de vidrios para aberturas generando un remate visual y terminación de puertas y ventanas, haciendo más amigable la tarea a la hora de instalarlos y o reponerlos.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F13v1.png?alt=media&token=11f3b5cb-c0ea-4c24-8d66-c9005e028ef0' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const Contramarco = ()=>{
    return(
        <>
            <h4 className='title-placas'>CONTRAMARCO</h4>
            <p>Los contramarcos tienen su máxima utilización en la unión de marcos de aberturas y pared.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F14v1.png?alt=media&token=d02b1d8d-2865-4eff-8d71-22ec70490eb7' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}




const Barral = ()=>{
    return(
        <>
            <h4 className='title-placas'>BARRAL LISO</h4>
            <p>Los barrales tienen su máxima utilización en la sujeción de cortinados.</p>

            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F15v1.png?alt=media&token=0c546089-303c-428d-83f3-3c3cb01bf28f' loading='lazy'/>
                        </div>
                    </div>
                </div>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F15v2.png?alt=media&token=ae515752-43c0-4d81-add3-1156881f8652' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Balaustre = ()=>{
    return(
        <>
            <h4 className='title-placas'>BALAUSTRE</h4>
            <p>Los balustres tienen su máximo uso a modo de seguridad generando contención en zonas de altura y a modo de soportes de barandas en balcones y escaleras. La baranda que generalmente se encuentra vinculándolos en su parte superior es una moldura pasamanos.</p>
            <br/>
            <p>Las diversas alternativas de combinación entre balustres y pasamanos recrean distintos estilos de balcones y escaleras.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F16v1.png?alt=media&token=f4696c25-9bd5-491a-97e8-75ff4abf1d8b' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}




const Saligna = ()=>{
    return(
        <>
            <h4 className='title-placas'>MOLDURAS DE MADERA DE SALIGNA</h4>
            <p>Estas molduras son fabricadas con madera de eucaliptus, especie Grandís. Dicha materia prima es de calidad Premium, debido a que es libre de nudos y a su vez, fue sometida a un exhaustivo proceso de secado con tecnología de avanzada. Estas características, hacen de este producto, una excelente opcion, ya que la calidad y terminación de las mismas son excelentes, y por sus tonos, brindan una gran calidez en los ambientes donde fueron aplicadas.</p>
            <br/>
            <p>Consultar modelos disponibles.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F17v1.png?alt=media&token=978bc377-770e-455e-9c89-ce55b5bc83c2' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Prepintadas = ()=>{
    return(
        <>
            <h4 className='title-placas'>MOLDURAS DE MDF PREPINTADAS</h4>
            <p>Tipo de moldura para resolver cada remate o terminacion. Con un prepintado blanco que simplifica la tarea final de pintura. Realizadas en fibra de madera que otorgan una excelente resistencia y simplifican las tareas de corte y acabado.. livianos y muy resistentes. De facil y rapida instalacion, se adhieren con pegamento o se pueden clavar o atornillar.</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F18v1.png?alt=media&token=5f0d0c59-8353-45ad-a022-1d3893598389' loading='lazy'/>
            <br/>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F18v2.png?alt=media&token=6f23fee2-4c15-47f7-b48f-eba4008d6eb8' loading='lazy'/>
            <br/>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F18v3.png?alt=media&token=051f11b6-79ec-4d89-b9f9-2cc5b7bdb494' loading='lazy'/>
        </>
    )
}



const Foliados = ()=>{
    return(
        <>
            <h4 className='title-placas'>MOLDURAS DE ZÓCALOS FOLIADOS</h4>
            <p>Tipo de moldura para resolver cada remate o terminacion. Con un prepintado blanco que simplifica la tarea final de pintura. Realizadas en fibra de madera que otorgan una excelente resistencia y simplifican las tareas de corte y acabado.. livianos y muy resistentes. De facil y rapida instalacion, se adhieren con pegamento o se pueden clavar o atornillar.</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F19v1.png?alt=media&token=f253ed0e-07af-45d7-8d4f-867f8e75bd32' loading='lazy'/>
            <br/>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F19v2.png?alt=media&token=ddd7d2ba-6726-4b8f-ba94-51c8d3ced138' loading='lazy'/>
            <br/>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F19v3.png?alt=media&token=8dba824c-2317-4590-b1dd-72ac8e612297' loading='lazy'/>
            <br/>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F19v4.png?alt=media&token=05c0bd42-4934-46c9-a8ad-535d13ba991a' loading='lazy'/>
            <br/>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F19v5.png?alt=media&token=054af0c9-8a92-4607-ac78-a2bb2d8d0f77' loading='lazy'/>
            <br/>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F19v6.png?alt=media&token=e5939e9b-b94c-4de0-a2ce-b5b6b6858e81' loading='lazy'/>
        </>
    )
}


const Crudo = ()=>{
    return(
        <>
            <h4 className='title-placas'>MOLDURAS DE MDF CRUDO</h4>
            <p>Livianos y resistentes y de muy simple retrabajo. Acepta cualquier tipo de pintura o revestimiento. De fácil y rápida instalación, se adhieren con pegamento especial Atenneas o bien se pueden clavar o atornillar.</p>
            <div className='cards-placas-container cent'>
                <div className='card-placas-60'>
                    <div className='marco-img'>
                        <div className='img-card'>
                            <img alt='placa1' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F20v1.png?alt=media&token=e35af651-6df9-4aef-88f2-a79c004c51dd' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const Telgopor = ()=>{
    return(
        <>
            <h4 className='title-placas'>MOLDURAS DE TELGOPOR</h4>
            <p>Cornisas en espumas extruidas. Molduras de estilo, modernas o atemporales para otorgarle a cada ambiente una impronta personal y destacada. De simple y rápida instalación resolviendo tanto aspectos decorativos como la necesidad de cubrir imperfecciones o encuentros no deseados. Livianos y resistentes. Se presentan en barras de 2 metros lineales. Aceptan todo tipo de pinturas sin diluir al solvente. De fácil y rápida instalación, se adhieren con pegamento especial Atenneas.</p>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F21v1.png?alt=media&token=ad4cd479-c495-4dbc-8462-e73219dfe457' loading='lazy'/>
            <img style={{width: '100%'}} alt='img-Wichi' src='https://firebasestorage.googleapis.com/v0/b/lumadi-maderas.appspot.com/o/Productos%2FMolduras%2F21v2.png?alt=media&token=f7879ee5-ddaa-427d-ac09-b10b769e4041' loading='lazy'/>
        </>
    )
}