import React from 'react';
import './MenuAdministracion.css';
import {NavLink} from 'react-router-dom';

const MenuAdministracion = () => {
  return (
    <div className='MenuAdministracion'>
        <div className='menuItems'>
            <NavLink to='/administracion'>Novedades</NavLink>
            <NavLink to='/docs-optimizador'>Documentos Optimizador</NavLink>
        </div>
    </div>
  )
}

export default MenuAdministracion;